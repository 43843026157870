import React, { useState, useEffect, createRef, useContext } from 'react';
import Place from '~/Scripts/Client/Place';
import Image from '~/Scripts/Client/Image';
import ImageCropper from '~/Components/Places/ImageCropper';
import ImageList from '~/Components/ImageGrabber/ImageList';
import ImageViewer from 'react-viewer';
import { DeleteOutlined } from '@ant-design/icons';
import { PlacesContext } from '~/Components/Context/PlacesContext';
import { useHistory } from 'react-router-dom';
import { message, Skeleton, Popconfirm, Modal,
    Button, Upload, Spin, Radio, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import User from '~/Scripts/Client/User';


const EditPlaceGallery = ({match}) => {
    const { places, setPlaces, csvPlaces, setCsvPlaces } = useContext(PlacesContext);
    const [images, setImages] = useState([]);
    const [place, setPlace] = useState();
    const [loadImages, setLoadImages] = useState(true);
    const [placeId] = useState(match.params.placeId);
    const [loading, setLoading] = useState(true);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [showCropper, setShowCropper] = useState(false);
    const [aspectRatio, setAspecRation] = useState(1.618 / 1);
    const [image, setImage] = useState("https://iguides.net/cityguideapi/v1/storage//public/questionmark.png");
    const [base64Image, setBase64Image] = useState();
    const [showSelectImage, setShowSelectImage] = useState(false);
    const [viewImage, setViewImage] = useState();
    const [showImageViewer, setShowImageViewer] = useState(false);
    const imageRef = createRef("image");
    const history = useHistory();

    const uploaderProps = {
        action: `${Place.baseUrl}/upload/image`,
        headers: Place.defaultHeaders().headers,
        multiple: false,
        name: "image",
        accept: ".jpg,.jpeg,.png",
        showUploadList: false,
        beforeUpload: () => setUploadLoading(true),
        onChange: (info) => uploadDone(info)
    }

    useEffect(() => {
        if(loadImages) {
            setLoadImages(false);
            Place.getPlace(placeId).then(
                (res) => {
                    setPlace(res.data);
                    setImages(res.data.gallery);
                    setLoading(false);
                }
            ).catch(errorHandler);
        }

    }, [placeId, loadImages, images]);

    const errorHandler = (error) => {
        if(error.response !== undefined)
            message.error(error.response.data.error_description);
        else
            message.error("Something went wrong, please try again later.");
        setLoading(false);
    }

    const deleteGalleryImage = (id, index) => {
        Place.removeGalleryImage(place.id, id).then(
            (res) => {
                images.splice(index, 1);
                setImages([...images]);
            }
        ).catch(errorHandler);
    }

    const uploadDone = (info) => {

        if (info.file.status === 'done') {
            setImage(info.file.response.url);
            setShowCropper(true);
        } else if (info.file.status === 'error') {
            setUploadLoading(false);
            message.error("error uploading image, please try again");
        }
    }

    const cancelCrop = () => {
        setShowCropper(false);
        setAspecRation(1.618 / 1);
        setImage("https://iguides.net/cityguideapi/v1/storage//public/questionmark.png");
    }

    const onCropImage = (image) => {
        // console.log("cropping...");
        setUploadLoading(false);
        setBase64Image(image);
    }

    const saveImage = () => {
        setUploadLoading(true);
        let order = images.length;
        Place.uploadGalleryBase64Image(place.id, base64Image, order).then(
            (res) => {
                setImage("https://iguides.net/cityguideapi/v1/storage//public/questionmark.png");
                setImages([...res.data.gallery]);
                setUploadLoading(false);
                setShowCropper(false);
                setAspecRation(1.618 / 1);

                places.forEach((p, i) => {
                    if(p.id === res.data.id) {
                        places[i] = res.data;
                    }
                });
                setPlaces([...places]);

                csvPlaces.forEach((p, i) => {
                    if(p.id === res.data.id) {
                        csvPlaces[i] = res.data;
                    }
                });
                setCsvPlaces([...csvPlaces]);
            }
        ).catch(errorHandler);
    }

    const onSelectImage = (url) => {
        setUploadLoading(true);
        setShowSelectImage(false);
        Image.uploadUrl(url).then(
            (res) => {
                setImage(res.data.url);
                // setUploadLoading(false);
                setShowCropper(true);
            }
        ).catch(errorHandler);
    }


    const loadGalleryImages = () => {
        return (
            <React.Fragment>
                {images.map((img, idx) => {
                    return (
                        <div className="image-item" key={idx}>
                            <img src={img.uri} 
                                style={{cursor: "zoom-in"}}
                                onClick={() => {setViewImage(img.uri); setShowImageViewer(true);}} />
                            <Popconfirm title="Are you sure you want to delete this image?"
                                onConfirm={() => deleteGalleryImage(img.id, idx)}
                                >
                                { (User.userCan('create_place') || User.userCan('update_place')) && 
                                    <Button danger type="primary" 
                                        icon={<DeleteOutlined />}
                                        size="small">Delete</Button> }
                            </Popconfirm>
                        </div>
                    )
                })}
            </React.Fragment>
        );
    }

    const renderImageButtons = () => {
        if(!User.userCan('create_place') && !User.userCan('update_place'))
            return false;

        if(!showCropper) {
            return (
                <React.Fragment>
                    <Upload {...uploaderProps}>
                        <Button type="primary">Upload</Button>
                    </Upload>
                    <Button type="primary" onClick={() => setShowSelectImage(true)}>Select</Button>
                    <Tooltip title="'Upload' button is used for uploading images in your local machine. 'Select' button to use images saved through Image Grabber.">
                        <Button type="link" icon={<InfoCircleOutlined />} size="medium" />
                    </Tooltip>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Radio.Group defaultValue="Landscape" buttonStyle="solid" style={{width: "100%", marginBottom: 15}}>
                        <Radio.Button value="Landscape" onClick={() => setAspecRation(1.618 / 1)}>Landscape</Radio.Button>
                        <Radio.Button value="Portrait" onClick={() => setAspecRation(1 / 1.618)}>Portrait</Radio.Button>
                        <Radio.Button value="Square" onClick={() => setAspecRation(1 / 1)}>Square</Radio.Button>
                        <Radio.Button value="Free Hand" onClick={() => setAspecRation(0)}>Free Hand</Radio.Button>
                    </Radio.Group>
                    <Button onClick={cancelCrop}>Cancel</Button>
                    <Button type="primary" onClick={saveImage}>Crop</Button>
                </React.Fragment>
            )
        }
    }

    const renderCropper = () => {
        if(!User.userCan('create_place') && !User.userCan('update_place'))
            return false;

        if(!showCropper) {
            return <img src={image} alt={place.name} 
                id="place-image" ref={imageRef} crossOrigin="Anonymous" />

        } else {
            return (
                <div className={!showCropper ? "hidden-cropper": "cropper"}>
                    <ImageCropper showCropper={showCropper} onCrop={onCropImage} 
                        aspectRatio={aspectRatio}
                        imageRef={imageRef}>
                        <img src={image} alt={place.name} 
                            id="place-image" ref={imageRef} crossOrigin="Anonymous" />
                    </ImageCropper>
                </div>
            );
        }
    }

    if(loading)
        return <Skeleton />

    return (
        <div className="place-gallery" style={{position: "relative"}}>
            <Button onClick={() => history.goBack()}
                    style={{marginRight: 15, position: "absolute", left: 5, top: 5}}
                    >Back</Button>
            <h2 style={{width: "100%"}}>Edit {place.name} Gallery</h2>
            <div className="gallery-container">
                
                <div className="image-list">
                    {loadGalleryImages()}
                </div>
                <div className="image-cropper">
                    <Spin spinning={uploadLoading}>
                        {renderCropper()}
                    </Spin>
                    {renderImageButtons()}
                </div>
            </div>

            <ImageViewer 
                visible={showImageViewer}
                images={[{src: viewImage}]}
                disableMouseZoom={false}
                zoomSpeed={0.2}
                drag={false}
                onClose={() => setShowImageViewer(false)}
            />
            
            <Modal
                visible={showSelectImage}
                title="Select Image"
                footer={[]}
                onCancel={() => setShowSelectImage(false)}
                width={"70%"}
                >
                    <div className="modal-image-list">
                        <ImageList onSelectImage={onSelectImage} />
                    </div>
            </Modal>
        </div>
    )
}

export default EditPlaceGallery;