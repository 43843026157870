import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import { Empty, Input } from 'antd';
// import PropTypes from 'prop-types';

const { Search } = Input;

class PendingPlaces extends Component {
    static contextType = AppContext;
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {  
        this.context.setCurrentTab("pending_places");
    }

    render() {

        return (
            <div>
                <h1>Pending Places</h1>
                <div>
                <div className="place-filter">
                    <Search placeholder="Search pending places..." enterButton 
                        style={{width: "50%", margin: "0 auto"}} />
                </div>
                    <Empty />
                </div>
            </div>
        );
    }
}

// Dashboard.propTypes = {

// };

export default PendingPlaces;