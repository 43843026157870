import React, { useState, useContext, useEffect } from 'react';
import Header from './Header';
import Sidebar from './SidebarMenu';
import { AppContext } from '~/Components/Context/AppContext';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from '~/Components/Pages/Dashboard';
import MapTiles from '~/Components/Pages/MapTiles';
import Places from '~/Components/Pages/Places';
import UploadCSV from '~/Components/Pages/UploadCSV';
import AddPlace from '~/Components/GoogleBot/AddNewPlace';
import EditPlace from '~/Components/Pages/EditPlace';
import EditPlaceGallery from '~/Components/Pages/EditPlaceGallery';
import GoogleBot from '~/Components/Pages/GoogleBot';
import SaveImages from '~/Components/Pages/SaveImages';
import PendingPlaces from '~/Components/Pages/PendingPlaces';
import PendingImages from '~/Components/Pages/PendingImages';
import Categories from '~/Components/Pages/Categories';
import Cities from '~/Components/Pages/Cities';
import AddCategory from '~/Components/Pages/AddCategory';
import EditCategory from '~/Components/Pages/EditCategory';
import Users from '~/Components/Pages/Users';
import HotelRoomTypes from '~/Components/Pages/HotelRoomTypes';
import HotelRoomTypeAddNew from '~/Components/Pages/HotelRoomTypeAddNew';
import HotelRoomTypeEdit from '~/Components/Pages/HotelRoomTypeEdit';
import Roles from '~/Components/Pages/Roles';
import DeletedPlaces from '~/Components/Pages/DeletedPlaces';
import ByCar from '~/Components/Pages/ByCar';
import CityBoundary from '~/Components/Pages/CityBoundary';
import WindowScreen from '~/Scripts/Utils/Window';

const Main = () => {
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [windowDimension, setWindowDimension] = useState(WindowScreen.getWindowDimensions());
    const { isLoggedIn } = useContext(AppContext);

    const handleCollapse = (e) => {
        e.preventDefault();
        setMenuCollapse(!menuCollapse);
    }

    useEffect(() => {
        WindowScreen.onWindowSizeChange(windowSizeChange);
        setMenuCollapse(WindowScreen.getWindowDimensions().width <= 1004);
    }, [WindowScreen.getWindowDimensions().width]);

    const windowSizeChange = () => {
        setWindowDimension(WindowScreen.getWindowDimensions());
    }
    
    if(!isLoggedIn)
        return <Redirect push to="/login" />

    return (
        <React.Fragment>
            <Header collpaseMenu={handleCollapse} />
            <div className="contentContainer" id="main-container" style={{
                    marginTop: 75,
                    height: windowDimension.height - 75,
                    width: windowDimension.width,
                    overflowX: 'hidden'
                }}>
                <Sidebar collapse={menuCollapse} />
                <div style={{flex: 1}}>
                    <Switch>
                        <Route exact path="/" component={Dashboard} />
                        <Route path="/places" component={Places} />
                        <Route path="/tiles" component={MapTiles} />
                        <Route path="/city/boundary" component={CityBoundary} />
                        <Route path="/add/place" component={AddPlace} />
                        <Route path="/place/upload/csv" component={UploadCSV} />
                        <Route path="/place/edit/:placeId" component={EditPlace} />
                        <Route exact path="/hotel/:hotelId/roomtypes" component={HotelRoomTypes} />
                        <Route path="/hotel/:hotelId/roomtypes/add" component={HotelRoomTypeAddNew} />
                        <Route path="/hotel/:hotelId/roomtypes/edit/:roomId" component={HotelRoomTypeEdit} />
                        <Route path="/place/gallery/:placeId" component={EditPlaceGallery} />
                        <Route path="/google/bot" component={GoogleBot} />
                        <Route path="/saved/images" component={SaveImages} />
                        <Route path="/pending/places" component={PendingPlaces} />
                        <Route path="/pending/images" component={PendingImages} />
                        <Route path="/categories" component={Categories} />
                        <Route path="/cities" component={Cities} />
                        <Route path="/category/add" component={AddCategory} />
                        <Route path="/category/edit/:catId" component={EditCategory} />
                        <Route path="/users" component={Users} />
                        <Route path="/user/roles" component={Roles} />
                        <Route path="/deleted/places" component={DeletedPlaces} />
                        <Route path="/bycar" component={ByCar} />
                    </Switch>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Main;