import React, { useState, useEffect, forwardRef } from 'react';
import Cropper from 'cropperjs';
import ReactDom from 'react-dom';

const ImageCropper = (props) => {

    const [cropper, setCropper] = useState();

    useEffect(() => {
        return () => {
            if(cropper !== null && cropper !== undefined) {
                console.log("Destroying cropper");
                cropper.destroy();
            }
        }
    }, []);

    useEffect(() => {
        // if(props.showCropper) {
        //     let crop = cropImage();
        //     setCropper(crop);
        // } else {
        //     if(cropper !== null && cropper !== undefined) {
        //         let crop = cropper.destroy();
        //         console.log(crop);
        //         setCropper(crop);
        //     }
        // }

        if(cropper === null || cropper === undefined) {
            let crop = cropImage();
            setCropper(crop);
        }

        if(cropper !== null && cropper !== undefined) {
            cropper.setAspectRatio(props.aspectRatio);
            setCropper(cropper);
        }
    }, [props.showCropper, props.aspectRatio]);

    const cropImage = () => {
        // let child = React.Children.only(props.children);
        // console.log(child);
        // let clone = React.cloneElement(child);
        // console.log(clone);
        const elem = ReactDom.findDOMNode(props.imageRef.current);
        // console.log(elem);
        let crop = new Cropper(elem, {
            checkCrossOrigin: false,
            responsive: false,
            scalable: false,
            aspectRatio: props.aspectRatio !== null ? props.aspectRatio : (1.618 / 1),
            viewMode: 3,
            crop: () => {
                props.onCrop(crop.getCroppedCanvas().toDataURL())
            }
        });

        return crop;
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )

}

export default ImageCropper;