import React, { useState, useEffect } from 'react';
import Image from '~/Scripts/Client/Image';
import LoadMoreButton from '~/Components/LoadMoreButton';
import ImageCard from './ImageCard';
import ImageFolder from './ImageFolder';
import ImageViewer from 'react-viewer';
import { Skeleton, message, Input, Button, Modal, Select, Upload } from 'antd';
import { ExclamationCircleOutlined, PlusSquareOutlined, PlusOutlined, SyncOutlined, LeftOutlined } from '@ant-design/icons';
import User from '~/Scripts/Client/User';

const { Search } = Input;
const { Option } = Select;

const ImageList = ({ onSelectImage, useImageViewer }) => {
  const [folders, setFolders] = useState([]);
  const [folderData, setFolderData] = useState({});
  const [images, setImages] = useState([]);
  const [loadImages, setLoadImages] = useState(true);
  const [loading, setLoading] = useState(false);
  const [nextPageLink, setNextPageLink] = useState();
  const [loadmoreLoading, setLoadMoreLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchImages, setSearchImages] = useState("");
  const [folderId, setFolderId] = useState();
  const [folderTree, setFolderTree] = useState([]);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [viewImage, setViewImage] = useState();
  const [imageNames, setImageNames] = useState([]);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("New Folder");
  const [input, setInput] = useState();
  const [editFolder, setEditFolder] = useState();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadList, setUploadList] = useState([]);

  useEffect(() => {
    if (loadImages) {
      setLoadImages(false);
      setLoading(true);
      Image.getFolders(folderId, search).then(
        (res) => {
          setFolderData(res.data);
          setFolders(res.data.data);
          setFolderTree(res.data.tree);
        }
      ).catch(errorHandler);

      Image.getSaveImageUrls(search, folderId).then(
        (res) => {
          setLoading(false);
          setImages(res.data.data);
          setNextPageLink(res.data.next_page_url);
        }
      ).catch(errorHandler);
    }
  }, [loadImages]);

  const loadMoreFolders = () => {
    setLoadMoreLoading(true);
    Image.getNextLink(folderData.next_page_url).then(
      (res) => {
        let folderarray = folders.concat(res.data.data);
        setFolders([...folderarray]);
        setFolderData(res.data);
      },
      errorHandler
    ).finally(() => setLoadMoreLoading(false));
  }

  const errorHandler = (error) => {
    if (error.response !== undefined)
      message.error(error.response.data.error_description);
    else
      message.error("Something went wrong, please try again later.");
    setLoading(false);
    setLoadMoreLoading(false);
  }

  const uploaderProps = {
    action: `${Image.baseUrl}/upload/image`,
    headers: Image.defaultHeaders().headers,
    multiple: true,
    name: "image",
    accept: ".jpg,.jpeg,.png",
    listType: "picture-card",
    fileList: uploadList,
    beforeUpload: () => setUploadLoading(true),
    onChange: (info) => uploadDone(info)
  }

  const uploadDone = (info) => {
    setUploadList(info.fileList);

    if (info.file.status === 'done') {
      // setImage(info.file.response.url);
      // setShowCropper(true);
      saveImage(info.file.response.url);
      let list = uploadList.filter((file) => {
        return file.uid !== info.file.uid;
      });
      setUploadList(list);
    } else if (info.file.status === 'error') {
      setUploadLoading(false);
      message.error("error uploading image, please try again");
    }
  }

  const saveImage = (url) => {
    let tag = url.substring(url.lastIndexOf('/') + 1);
    Image.saveImage([url], tag, folderId).then(
      (res) => {
        // console.log(res.data);
        if (res.data.urls && res.data.urls.length > 0) {
          images.push(res.data.urls[0]);
          setImages([...images]);
        }
      }
    )
  }

  const setLoadedImages = (response) => {
    setLoadImages(false);
    setLoading(false);
    setLoadMoreLoading(false);
    setImages(images.concat(response.data.data));
    setNextPageLink(response.data.next_page_url);
  }

  const onSelectImageChange = (url) => {

    if (onSelectImage !== null && onSelectImage !== undefined)
      onSelectImage(url);

    if (useImageViewer) {
      setViewImage(url);
      setShowImageViewer(true);
    }
  }

  const resetSearch = () => {
    setSearch("");
    setLoadImages(true);
  }

  // const getImageNames = (search) => {
  //     Image.searchImageTags(search).then(
  //         (res) => {
  //             setImageNames(res.data);
  //         }
  //     )
  // }

  // const searchKeyDown = (e) => {
  //     if(e.keyCode === 13) {
  //         e.preventDefault();
  //         setLoadImages(true);
  //     }
  // }

  // const searchKeyUp = (e) => {
  //     if(e.keyCode === 13) {
  //         e.preventDefault();
  //     }
  //     setSearchImages(e.target.value);
  // }

  const deleteImage = (id, index, setDeleteLoading) => {
    Modal.confirm({
      title: "Are you sure you want to delete this image?",
      icon: <ExclamationCircleOutlined />,
      content: "Deleting the image will remove it permanently from the list.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => {
        setDeleteLoading(true);
        Image.deleteImage(id).then(
          () => {
            setDeleteLoading(false);
            images.splice(index, 1);
            setImages([...images]);
          }
        ).catch(errorHandler);
      }
    });
  }

  const addFolder = (e) => {
    e.preventDefault();
    setCreatingFolder(true);
    // this.setState({editFolder: null});
    let f = folders.map((folder) => {
      folder.edit = 0;
      return folder;
    });
    setFolders([...f]);
    setNewFolderName("New Folder");
    setTimeout(() => {
      let input = document.getElementById("add-folder-input");
      // this.setState({input: input});
      setInput(input);
      if (input != null) {
        input.focus();
        input.select();
      }
    }, 500);
  }

  const onEditFolder = (folder, index) => {
    if (!User.userCan("manage_folders"))
      return;

    let fold = folders.map((f) => {
      f.edit = f.id === folder.id;
      return f;
    });
    setFolders(fold);
    setCreatingFolder(false);
    setNewFolderName(folder.name);
    setEditFolder(folder);
    let input = document.getElementById("add-folder-input");
    // this.setState({input: input});
    if (input != null) {
      setInput(input);
      input.focus();
      input.select();
    }
  }

  const saveFolder = (e, index) => {
    if (e.keyCode === 13) {
      if (input) {
        input.disabled = true;
      }
      if (editFolder !== null && editFolder !== undefined && index !== null && index !== undefined) {
        folders[index].edit = false;
        folders[index].name = newFolderName;
        // this.setState({folders: [...this.state.folders]});
        setFolders([...folders]);
        Image.updateFolder(editFolder.id, newFolderName, folderId).then(
          (res) => {
            setCreatingFolder(false);
            setNewFolderName("New Folder")
            setEditFolder(null);
          }
        ).catch(
          (error) => {
            console.log(error);
            message.error(error.response.data.error_description);
            if (input) {
              input.disabled = false;
              input.select();
            }
          }
        )
      } else {
        Image.createFolder(newFolderName, folderId).then(
          (res) => {
            setCreatingFolder(false);
            folders.push(res.data);
            setFolders([...folders]);
            setNewFolderName("New Folder");
            input.disabled = false;
          }
        ).catch(
          (error) => {
            console.log(error);
            message.error(error.response.data.error_description);
            if (input) {
              input.disabled = false;
              input.select();
            }
          }
        )
      }
    }

    if (e.keyCode === 27) {
      setCreatingFolder(false);
      // remove active edit
      let fold = folders.map((f) => {
        f.edit = false;
        return f;
      });
      setFolders(fold);
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const imageListCard = () => {
    if (loading)
      return (
        <div style={{ padding: 20 }}>
          <Skeleton active />
        </div>
      );

    return (
      <div>
        <div className="folder-list">
          {folders.map((folder, i) => {
            return <div>
              <ImageFolder
                folder={folder}
                key={folder.id}
                folderName={newFolderName}
                editFolder={onEditFolder}
                onDeleteFolder={onDeleteFolder}
                saveFolder={saveFolder}
                setFolderName={setNewFolderName}
                index={i}
                onClick={() => { setFolderId(folder.id); setSearch(''); setLoadImages(true); }}
              />
            </div>
          })}
          {creatingFolder && User.userCan("manage_folders") &&
            <div className="image-folder">
              <img src={require("~/assets/images/folder.png")} />
              <Input value={newFolderName}
                id="add-folder-input"
                onChange={(e) => setNewFolderName(e.target.value)}
                onKeyDown={saveFolder} />
            </div>}
          <div className="folder-item" onClick={addFolder}>
            <div style={{ height: "100px" }}>
              <a href="#">
                <PlusSquareOutlined style={{ color: "#eeeeee", fontSize: "75px", marginTop: "15px" }} />
              </a>
            </div>
            <a href="#">Add Folder</a>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #ccc", width: "100%", minHeight: 25, marginBottom: 10, padding: 10 }}>
          <br />
          {folderData.next_page_url && <Button type="primary" onClick={loadMoreFolders} loading={loadmoreLoading}>Load More</Button>}
        </div>
        <div className="image-list">
          {images.map((image, index) => {
            return <ImageCard image={image} key={image.id}
              onSelect={onSelectImageChange}
              showDelete={useImageViewer}
              index={index}
              images={images}
              setImages={setImages}
              onDeleteImage={deleteImage} />
          })}
          <Upload {...uploaderProps}>
            {uploadList.length >= 8 ? null : uploadButton}
          </Upload>
          <div style={{ width: "100%", textAlign: "left" }}>
            {folderTree.length === 1 &&
              <Button style={{ width: 200 }}
                onClick={() => { setFolderId(''); setSearch(''); setLoadImages(true); }}
                icon={<LeftOutlined />}>Back</Button>}
            {folderTree.length > 1 &&
              <Button tstyle={{ width: 200 }}
                onClick={() => { setFolderId(folderTree[folderTree.length - 2].id); setSearch(''); setLoadImages(true); }}
                icon={<LeftOutlined />}>Back</Button>}
            <LoadMoreButton url={nextPageLink} style={{ width: 200 }}
              onError={errorHandler}
              onFinish={setLoadedImages}
              loading={loadmoreLoading}
              setLoading={setLoadMoreLoading}
            />
          </div>
        </div>
      </div>
    );
  }
  const onDeleteFolder = (folder, index) => {
    folders.splice(index, 1);
    setFolders([...folders]);
  }

  const renderBreadCrumbs = () => {
    if (folderTree.length == 0) {
      return <p>Home</p>
    } else {
      let tree = folderTree.map((tree) => {
        if (tree.id === folderId)
          return <span style={{ marginLeft: 10 }}>{tree.name}</span>
        else
          return (
            <span>
              <Button onClick={() => { setFolderId(tree.id); setSearch(''); setLoadImages(true); }}
                type="link">{tree.name}</Button> &gt;
            </span>
          )
      });

      tree.unshift((
        <span>
          <Button onClick={() => { setFolderId(''); setSearch(''); setLoadImages(true); }}
            type="link">Home</Button> &gt;
        </span>
      ));

      return tree;
    }

  }

  const renderImageViewer = () => {
    if (useImageViewer) {
      return <ImageViewer
        visible={showImageViewer}
        images={[{ src: viewImage }]}
        disableMouseZoom={false}
        zoomSpeed={0.2}
        drag={false}
        onClose={() => setShowImageViewer(false)}
      />
    }
  }

  return (
    <div className="image-list-container">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Search value={search}
          placeholder="Search..."
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => setLoadImages(true)}
          enterButton
          style={{ width: "90%" }}
        />
        <Button type="primary"
          onClick={resetSearch}
          style={{ marginLeft: 10, marginRight: 10 }}
          icon={<SyncOutlined />}
        >Reset</Button>
      </div>
      <div className="breadcrumbs">
        {renderBreadCrumbs()}
      </div>
      {imageListCard()}
      {renderImageViewer()}
    </div>
  )
}

export default ImageList;