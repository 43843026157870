import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import ByCarPlaces from '~/Components/ByCar/ByCarPlaces';
import { MapProvider } from '~/Components/Context/MapContext';

class DeletedPlaces extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.context.setCurrentTab("by_car_places");
    }

    render() {

        return (
            <MapProvider>
                <ByCarPlaces google={this.props.google} />
            </MapProvider>
        );
    }
}

// Places.propTypes = {

// };

export default DeletedPlaces;