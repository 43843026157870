import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import WindowDimension from '~/Scripts/Utils/Window';
import LoginForm from '~/Components/Login/LoginForm';
import { Menu } from 'antd';

class Login extends Component {
    // constructor(props) {
    //     super(props);
    // }

    state = {
        windowDimension: WindowDimension.getWindowDimensions(),
        currentTab: 'login'
    }

    componentDidMount() {
        WindowDimension.onWindowSizeChange(this.windowSizeChange)
    }

    windowSizeChange = () => {
        this.setState({windowDimension: WindowDimension.getWindowDimensions()});
    }

    changeTabHandler = (e) => {
        this.setState({currentTab: e.key});
    }

    displayTabContent = (key) => {
        switch(key) {
            case "login":
                return <LoginForm />
            default: 
                return <LoginForm />
        }
    }

    render() {
        return (
            <div className="loginContainer" style={{
                backgroundImage: `url(${require('../../assets/images/login-bg.png')})`, 
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: this.state.windowDimension.height}}>
                
                <div className="loginFormContainer">
                    <Menu 
                        selectedKeys={[this.state.currentTab]} 
                        mode="horizontal" 
                        onClick={this.changeTabHandler}>
                        <Menu.Item key="login">
                            Login
                        </Menu.Item>
                        <Menu.Item key="forgot_password">
                            Forgot Password
                        </Menu.Item>
                    </Menu>
                    {this.displayTabContent()}
                </div>
            </div>
        );
    }
}

// Login.propTypes = {

// };

export default Login;