import React, { useState, useEffect } from 'react';
import { Card, Button, Spin, Input, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Image from '~/Scripts/Client/Image';

const { Meta } = Card;

const ImageCard = ({ image, onSelect, showDelete, onDeleteImage, index, images, setImages }) => {
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [imageName, setImageName] = useState(image.tag);
    const [input, setInput] = useState();


    useEffect(() => {
        let inpt = document.getElementById("add-folder-input");
        setInput(inpt);
    }, [images]);

    const renderDelete = () => {
        if(!showDelete)
            return null;

        return (
            <Button danger shape="circle" className="delete-image" 
                icon={<CloseOutlined />} onClick={() => onDeleteImage(image.id, index, setDeleteLoading)} />
        );
    }

    const saveImage = (e) => {
        let input = document.getElementById("add-folder-input");
        if(e.keyCode === 13) {
            if(input) {
                input.disabled = true;
            }

            Image.updateImage(image.id, {tag: imageName}).then(
                (res) => {
                    images[index].tag = imageName;
                    images[index].edit = false;
                    setImages([...images]);
                }, 
                (error) => {
                    console.log(error);
                    message.error(error.response.data.error_description);
                    if(input) {
                        input.disabled = false;
                        input.select();
                    }
                }
            )
        }
        
        if(e.keyCode === 27) {
            // remove active edit
            let i = images.map((f) => {
                f.edit = false;
                return f;
            });
            setImages([...i]);
        }
    }

    const editImage = () => {
        let i = images.map((img) => {
            img.edit = 0;
            return img;
        });

        i[index].edit = 1;
        setImages([...i]);
        if(input) {
            input.focus();
            input.select();
        }
    }

    const renderImageName = () => {
        if(image.edit) {
            return (
                <div className="folder-item">
                    <Input value={imageName} 
                        id="add-folder-input"
                        onChange={(e) => setImageName(e.target.value)}
                        onKeyDown={(e) => saveImage(e, index)} />
                </div>
            );
        } else {
            return (<p onDoubleClick={() => editImage()}>{image.tag}</p>);
        }
    }

    return (
        <Card 
            style={{ width: "30%" }}
            hoverable>
            <Spin spinning={deleteLoading}>
                <div className="image-card">
                    <div className="image">
                        <img alt={image.tag} src={image.url} onClick={() => onSelect(image.url)} />
                    </div>
                    <div className="image-detail">
                        {renderImageName()}
                    </div>
                    {renderDelete()}
                </div>
            </Spin>
        </Card>
    )
}

export default ImageCard;