import React, { useState, useContext, useEffect } from 'react';
import Constants from '~/Scripts/Utils/Constants';
import StarRating from 'react-star-ratings';
import ImageViewer from 'react-viewer';
import GooglePlaceDetails from './GooglePlaceDetail';
import GoogleUtils from '~/Scripts/Client/Google';
import { MapContext } from '~/Components/Context/MapContext';
import { Skeleton, Button, Popconfirm, message, Empty, Switch } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const GooglePlaceList = ({ loading, showList, currentType, showMarkerInMap }) => {

    const { markers, setMarkers, showHidden, setShowHidden, 
        showDetail, setShowDetail, placeToShow, setPlaceToShow } = useContext(MapContext);
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [viewImage, setViewImage] = useState();

    useEffect(() => {
        if(loading) {
            setShowDetail(false);
        }
    }, [loading])
    
    const zoomImage = (image) => {
        setViewImage(image);
        setShowImageViewer(true);
    }

    const getPlaceImage = (place) => {
        if(place.photos !== null && place.photos !== undefined && place.photos.length > 0)
            return 'https://maps.googleapis.com/maps/api/place/photo?maxwidth=750&photoreference=' + place.photos[0].photo_reference + '&key=' + process.env.REACT_APP_GOOGLE_MAP_KEY;
        else
            return "https://iguides.net/cityguideapi/v1/storage//public/questionmark.png";
    }

    const showGooglePlaceDetail = (place) => {
        setPlaceToShow(place);
        setShowDetail(true);
    }

    const hidePlace = (placeId, index) => {
        markers[index].loading = true;
        setMarkers([...markers]);
        GoogleUtils.hideGooglePlace(placeId).then(
            (res) => {
                message.success("Place successfull hidden");
                markers[index].hidden = 1;
                markers[index].loading = false;
                setMarkers([...markers]);
            }
        ).catch(
            (error) => {
                console.log(error);
                markers[index].loading = false;
                setMarkers([...markers]);
            }
        )
    }

    const unhidePlace = (placeId, index) => {
        markers[index].loading = true;
        setMarkers([...markers]);
        GoogleUtils.unhideGooglePlace(placeId).then(
            (res) => {
                message.success("Place successfull hidden");
                markers[index].hidden = 0;
                markers[index].loading = false;
                setMarkers([...markers]);
            }
        ).catch(
            (error) => {
                console.log(error);
                markers[index].loading = false;
                setMarkers([...markers]);
            }
        )
    }

    // const renderHideButton = (place, index) => {
    //     if(place.hidden) {
    //         return <Button size="small" 
    //             loading={place.loading}
    //             onClick={() => unhidePlace(place.place_id, index)}>Unhide</Button>;
    //     } else {
    //         return (
    //             <Popconfirm
    //                 title="Are you sure you want to hide this place?"
    //                 okText="Yes"
    //                 cancelText="No"
    //                 onConfirm={() => hidePlace(place.place_id, index)}>
    //                 <Button size="small" loading={place.loading}>Hide</Button>
    //             </Popconfirm>
    //         )
    //     }
    // }

    const placeList = () => {
        let temp = markers;
        if(!showHidden)
            temp = markers.filter((p) => {
                return !p.hidden;
            });

        if(temp.length === 0)
            return <Empty description="No Places Found" />

        return (
            <React.Fragment>
                <div className="include-hidden" style={{marginBottom: 25}}>
                    <Switch checked={showHidden} style={{marginRight: 15}}
                        onChange={() => setShowHidden(!showHidden)} />
                    <span>Show Hidden</span>
                </div>
                {temp.map((place, index) => {
                    return (
                        <div className={"place-list-item" + (place.hidden ? " hidden": "") 
                            + (place.is_saved ? " saved" : "")} key={index}>
                            <div className="place-image">
                                <img src={getPlaceImage(place)} 
                                    onClick={() => zoomImage(getPlaceImage(place))}
                                    alt={place.name} />
                            </div>
                            <div className="place-details">
                                <h3><strong>{index + 1}) {place.name}</strong></h3>
                                {(place.rating !== null && place.rating !== undefined) ? parseFloat(place.rating) : 0} 
                                <StarRating 
                                    rating={(place.rating !== null && place.rating !== undefined) ? parseFloat(place.rating) : 0}
                                    numberOfStars={5}
                                    name={place.name}
                                    starDimension="15px"
                                    starSpacing="1px"
                                    starRatedColor={Constants.primaryColor}
                                />
                                <div>
                                    <Button type="primary" size="small" 
                                        onClick={() => showGooglePlaceDetail(place, index)}
                                        style={{marginRight: 10}}>View</Button>
                                    {/*renderHideButton(place, index)*/}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </React.Fragment>
        );
    }

    if(showDetail)
        return <GooglePlaceDetails place={placeToShow} setPlace={setPlaceToShow} showMarkerInMap={showMarkerInMap}
            showDetail={setShowDetail} currentType={currentType} />

    return (
        <Skeleton loading={loading} active={true}>
            <div>
                <div className="google-place-list">
                    {placeList()}
                    <Button className="close"
                        shape="circle"
                        onClick={() => showList(false)}
                        icon={<CloseOutlined />}
                    />
                </div>
                <ImageViewer 
                    visible={showImageViewer}
                    images={[{src: viewImage}]}
                    disableMouseZoom={false}
                    zoomSpeed={0.2}
                    drag={false}
                    onClose={() => setShowImageViewer(false)}
                />
            </div>
        </Skeleton>
    )
}

export default GooglePlaceList;