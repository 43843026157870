import axios from 'axios';

class BaseClient {
    env = require('dotenv').config();
    baseUrl = process.env.REACT_APP_API_BASE_URL;

    defaultHeaders = () => {
        return {
            headers: {
                Authorization: 'Bearer ' + this.getToken(),
                Accept: 'application/json'
            }
        }
    }

    get = (uri, headers) => {
        return axios.get(`${this.baseUrl}${uri}`, headers);
    }

    post = (uri, data, headers) => {
        return axios.post(`${this.baseUrl}${uri}`, data, headers);
    }

    put = (uri, data, headers) => {
        return axios.put(`${this.baseUrl}${uri}`, data, headers);
    }

    delete = (uri, headers) => {
        return axios.delete(`${this.baseUrl}${uri}`, headers);
    }

    getToken = () => {
        return localStorage.getItem("CityGuide.AccessToken");
    }

    setToken = (token) => {
        localStorage.setItem("CityGuide.AccessToken", token);
    }

    setRememberMe = (username) => {
        localStorage.setItem("CityGuide.Username", username);
    }

    setCurrentUser = (user) => {
        localStorage.setItem("CityGuide.CurrentUser", JSON.stringify(user));
    }

    getRememberMe = () => {
        return localStorage.getItem("CityGuide.Username");
    }

    getCurrentUser = () => {
        let user = localStorage.getItem("CityGuide.CurrentUser");
        return JSON.parse(user);
    }

    removeToken = () => {
        localStorage.removeItem("CityGuide.AccessToken");
    }

    removeLoginUser = () => {
        localStorage.removeItem("CityGuide.CurrentUser");
    }

    isLogin = () => {
        let token = this.getToken();
        return token !== null;
    }

}

export default BaseClient;