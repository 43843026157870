import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import CityBoundaryView from '~/Components/CityBoundary/CityBoundaryView';
import WindowDimension from '~/Scripts/Utils/Window';
import { MapProvider } from '~/Components/Context/MapContext';


class CityBoundary extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);

    }

    state = {
        windowDimension: WindowDimension.getWindowDimensions()
    }

    componentDidMount() {
        this.context.setCurrentTab("city_boundary");
        WindowDimension.onWindowSizeChange(this.windowSizeChange);
    }

    windowSizeChange = () => {
        this.setState({windowDimension: WindowDimension.getWindowDimensions()});
    }

    render() {

        return (
            <MapProvider>
                <CityBoundaryView google={this.props.google} />
            </MapProvider>
        );
    }
}

// MapTiles.propTypes = {

// };

export default CityBoundary;