import BaseClient from './BaseClient';

class Place extends BaseClient {
  getAll = (search, limit, type, page, sort, order, filter, subType, googleType, country, hiddenOnly, shownOnly, price) => {
    if (limit == null)
      limit = 20;

    let url = '/place?limit=' + limit;

    if (search !== null && search !== undefined && search.length > 0)
      url += '&search=' + search;

    if (type !== null && type !== undefined && type.length > 0)
      url += '&type=' + type;

    if (page != null)
      url += '&page=' + page;

    if (sort != null)
      url += '&sort_by=' + sort;

    if (order != null)
      url += '&order=' + order;

    if (filter != null)
      url += '&filter=' + filter;

    if (subType != null)
      url += '&sub_type=' + subType;

    if (googleType != null)
      url += '&google_type=' + googleType;

    if (country != null)
      url += '&country=' + country;

    if (hiddenOnly != null)
      url += '&hidden_only=' + (hiddenOnly ? 1 : 0);

    if (shownOnly != null)
      url += '&shown_only=' + (shownOnly ? 1 : 0);

    if (price != null)
      url += '&price=' + price;

    return this.get(url, this.defaultHeaders());
  }

  getHotels = (limit, page, sort, order, hotelType) => {
    if (limit == null)
      limit = 9;

    let url = '/place?type=hotel&limit=' + limit;

    if (page != null)
      url += '&page=' + page;

    if (sort != null)
      url += '&sort_by=' + sort;

    if (order != null)
      url += '&order=' + order;

    if (hotelType != null)
      url += '&property_type=' + hotelType;

    return this.get(url);
  }

  getPlace = (id) => {
    return this.get(`/place/${id}`, this.defaultHeaders());
  }

  getNextPage = (link) => {
    let url = link.replace(this.baseUrl, '');
    return this.get(url, this.defaultHeaders());
  }

  getFeedbacks = (id) => {
    return this.get(`/place/${id}/rating`, this.defaultHeaders());
  }

  postFeedback = (id, data) => {
    return this.post(`/place/${id}/rating`, data, this.defaultHeaders());
  }

  addPlace = (data) => {
    return this.post('/place', data, this.defaultHeaders());
  }

  editPlace = (id, data) => {
    return this.put(`/place/${id}`, data, this.defaultHeaders());
  }

  deletePlace = (id, permanent) => {
    const perm = permanent ? permanent : 0;
    return this.delete(`/place/${id}?permanent=${perm}`, this.defaultHeaders());
  }

  getCountryList = () => {
    return this.get(`/country/list`, this.defaultHeaders());
  }

  getNearestPlaces = (lat, lng, distance, type, userInterest, nearest, page, limit) => {
    let url = `/place/nearest?latitude=${lat}&longitude=${lng}`;
    if (distance != null)
      url += `&distance=${distance}`;

    if (type != null)
      url += `&type=${type}`;

    if (userInterest != null)
      url += '&user_interest=1';

    if (nearest != null)
      url += `&nearest=${nearest}`;

    if (page != null)
      url += `&page=${page}`;

    if (limit != null)
      url += `&limit=${limit}`;

    return this.get(url, this.defaultHeaders());
  }

  getNearestPlaceByBounds = (bounds, type, subType, price) => {
    let url = `/place/nearestbybounds?limit=1500&bounds=${bounds}`;

    if (type != null)
      url += `&type=${type}`;

    if (subType != null)
      url += `&sub_type=${subType}`;

    if (price != null)
      url += `&price=${price}`;

    return this.get(url, this.defaultHeaders());
  }

  getFilterByAgeOptions = () => {
    return this.get('/place/age/options');
  }

  getGalleryImages = (id) => {
    return this.get(`/place/${id}/gallery`);
  }

  removeGalleryImage = (placeId, imageId) => {
    return this.delete(`/place/${placeId}/gallery/${imageId}`, this.defaultHeaders());
  }

  bookmarkPlace = (userId, placeId, order) => {
    let data = {
      place_id: placeId,
      order: order
    }
    return this.post(`/user/${userId}/bookmark`, data, this.defaultHeaders());
  }

  unbookmarkPlace = (userId, placeId) => {
    return this.delete(`/user/${userId}/bookmark/${placeId}`, this.defaultHeaders())
  }

  uploadBase64Image = (placeId, image) => {
    let data = {
      place_id: placeId,
      image: image
    }
    return this.post('/upload/base64/image', data, this.defaultHeaders())
  }

  updateImageByUrl = (placeId, image) => {
    let data = {
      image: image
    }
    return this.post(`/place/${placeId}/image/byurl`, data, this.defaultHeaders());
  }

  uploadGalleryBase64Image = (placeId, image, order) => {
    let data = {
      place_id: placeId,
      image: image,
      order: order
    }
    return this.post('/upload/base64/gallery/image', data, this.defaultHeaders());
  }

  updateGalleryOrder = (placeId, data) => {
    return this.put(`/place/${placeId}/update/gallery`, data, this.defaultHeaders());
  }

  searchPlaceName = (search, type, subType, googleType, filter) => {
    let url = `/search/places?search=${search}`;

    if (type !== undefined && type !== null)
      url += `&type=${type}`;

    if (subType !== undefined && subType !== null)
      url += `&sub_type=${subType}`;

    if (googleType !== undefined && googleType !== null)
      url += `&google_type=${googleType}`;

    if (filter !== undefined && filter !== null)
      url += `&filter=${filter}`;

    return this.get(url, this.defaultHeaders());
  }

  searchNearbyGooglePlaces = (location, radius, type, name, tileId, rating, includeSavePlaces) => {
    let url = `/google/nearby/places?location=${location}&radius=${radius}&type=${type}&name=${name}&tile_id=${tileId}&rating=${rating}&include_save=${includeSavePlaces}`;
    return this.get(url, this.defaultHeaders());
  }

  uploadCSV = (data) => {
    return this.put(`/place/upload/csv`, data, this.defaultHeaders());
  }

  getDeletedPlaces = ({ page, limit, userId }) => {
    return this.get(`/places/deleted?page=${page}&limit=${limit}&user_id=${userId}`, this.defaultHeaders());
  }

  restorePlace = (placeId) => {
    return this.put(`/places/${placeId}/restore`, {}, this.defaultHeaders());
  }

  getPlaceSearchUrl = (search, type, sort, order, filter, subType, googleType, country, hiddenOnly, shownOnly, price) => {

    let url = '/place/search/csv?limit=20';

    if (search !== null && search !== undefined && search.length > 0)
      url += '&search=' + search;

    if (type !== null && type !== undefined && type.length > 0)
      url += '&type=' + type;

    if (sort != null)
      url += '&sort_by=' + sort;

    if (order != null)
      url += '&order=' + order;

    if (filter != null)
      url += '&filter=' + filter;

    if (subType != null)
      url += '&sub_type=' + subType;

    if (googleType != null)
      url += '&google_type=' + googleType;

    if (country != null)
      url += '&country=' + country;

    if (hiddenOnly != null)
      url += '&hidden_only=' + (hiddenOnly ? 1 : 0);

    if (shownOnly != null)
      url += '&shown_only=' + (shownOnly ? 1 : 0);

    if (price != null)
      url += '&price=' + price;

    return this.baseUrl + url;
  }
}

export default new Place();