import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import CityList from '~/Components/City/CityList';
// import PropTypes from 'prop-types';

class Cities extends Component {
    static contextType = AppContext;
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {  
        this.context.setCurrentTab("cities");
    }

    render() {

        return (
            <div>
                <h1>List of Cities</h1>
                <div>
                    <CityList />
                </div>
            </div>
        );
    }
}

// Dashboard.propTypes = {

// };

export default Cities;