import React, { useEffect, useState } from 'react';
import StarRating from 'react-star-ratings';
import Constants from '~/Scripts/Utils/Constants';
import GoogleClient from '~/Scripts/Client/Google';
import RoomType from '~/Scripts/Client/RoomType';
import { Skeleton } from 'antd';
import { DollarOutlined } from "@ant-design/icons"

const PlaceDetails = ({ place }) => {
  const { fields, image, name, description, address, website, phone } = place;
  const [googlePlace, setGooglePlace] = useState();
  const [loading, setLoading] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  useEffect(() => {
    if (fields.google_place_id !== null && fields.google_place_id !== undefined) {
      setLoading(true);
      GoogleClient.getGooglePlaceDetail(fields.google_place_id).then(
        (res) => {
          setLoading(false);
          setGooglePlace(res.data.result);
        }
      ).catch(
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );

      if (isAccomodation()) {
        RoomType.getRoomTypes(place.id).then(
          (res) => {
            let min = 0;
            let max = 0;
            res.data.data.forEach((room, index) => {
              // if the first room, then set the max and min price
              if (index === 0) {
                min = room.price;
                max = room.price;
              } else {
                if (room.price < min)
                  min = room.price;

                if (room.price > max)
                  max = room.price;
              }
            });
            setMinPrice(min);
            setMaxPrice(max);
          }
        );
      }
    }
  }, [place]);

  const getPlaceCity = () => {
    let city = '';
    if (fields.route !== null && fields.route !== undefined)
      city += `${fields.route}, `;
    if (fields.city !== null && fields.city !== undefined)
      city += fields.city;
    else if (fields.town !== null && fields.town !== undefined)
      city += fields.town;
    else
      city = 'No City';

    return city;
  }

  const getTypes = () => {
    let concatType = "";
    place.types.forEach((type, index) => {
      if (index < (place.types.length - 1))
        concatType += `${type.name}, `;
      else
        concatType += type.name;
    });

    return concatType;
  }

  const getSubTypes = () => {
    let concatType = "";
    place.sub_types.forEach((type, index) => {
      if (index < (place.sub_types.length - 1))
        concatType += `${type.name}, `;
      else
        concatType += type.name;
    });

    return concatType;
  }

  const isAccomodation = () => {
    let accomodation = place.types.find((p) => { return p.type === "accommodation" });

    return accomodation !== null && accomodation !== undefined;
  }

  const renderPrice = (fields) => {
    let price = [];
    if (fields.price !== undefined) {
      if (fields.price === 0) {
        return <span style={{ color: "#0ba18e" }}>Free</span>
      }

      for (let x = 0; x < fields.price; x++) {
        if (x > 2)
          break;

        price.push(x);
      }
    }

    return (<span>{price.map((P) => {
      return <span style={{ color: "#0ba18e" }}>$</span>
    })}</span>);

  }

  const renderGoogleReviews = () => {
    if (googlePlace === null || googlePlace === undefined)
      return null;

    if (googlePlace.reviews === null || googlePlace.reviews === undefined)
      return null;

    return (
      <React.Fragment>
        <Skeleton loading={loading}>
          {googlePlace.reviews.map((review) => {
            return (
              <div className="user-review">
                <div className="user-photo">
                  <img src={review.profile_photo_url} />
                </div>
                <div className="user-rating">
                  <p><strong>{review.author_name}</strong></p>
                  <div>
                    ({review.rating})<StarRating
                      rating={review.rating ? parseFloat(review.rating) : 0}
                      numberOfStars={5}
                      name="user_review"
                      starDimension="20px"
                      starSpacing="3px"
                      starRatedColor={Constants.primaryColor}
                    /><i style={{ marginLeft: 10 }}>{review.relative_time_description}</i>
                    <p>{review.text}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </Skeleton>
      </React.Fragment>
    )
  }

  const renderOpeningHours = () => {
    if (googlePlace === null || googlePlace === undefined || loading)
      return null;

    if (googlePlace.opening_hours === null || googlePlace.opening_hours === undefined)
      return null;

    return (
      <div className="opening-hours">
        <h4><strong>Opening Hours</strong></h4>
        <p className={googlePlace.opening_hours.open_now ? "open" : "close"}>
          Status: <strong>{googlePlace.opening_hours.open_now ? "Open" : "Close"}</strong></p>
        {googlePlace.opening_hours.weekday_text.map((time) => {
          return <p>{time}</p>
        })}
      </div>
    );
  }

  const renderBusinessStatus = () => {
    if (googlePlace === null || googlePlace === undefined || loading)
      return null;

    if (googlePlace.business_status === null || googlePlace.business_status === undefined)
      return null;

    return (
      <div className="opening-hours">
        <p className={googlePlace.business_status.includes("CLOSE") ? "close" : "open"}>
          Business Status: <strong>{googlePlace.business_status}</strong></p>
      </div>
    );
  }

  return (
    <div className="modal-place-details">
      <div className="place-details">
        <div className="place-image">
          <img src={image} alt={name} />
        </div>
        <div className="details">
          <h3>{name}</h3>
          <p style={{ marginBottom: 1 }}><strong>{getPlaceCity()}</strong></p>
          <p style={{ marginBottom: 1 }}>Categories: <strong>{getTypes()}</strong></p>
          <p>Sub-Categories: <strong>{getSubTypes()}</strong></p>
          {fields.rating && <span>({fields.rating})</span>}<StarRating
            rating={fields.rating ? parseFloat(fields.rating) : 0}
            numberOfStars={5}
            name={name}
            starDimension="20px"
            starSpacing="3px"
            starRatedColor={Constants.primaryColor}
          /> {googlePlace && <span>({googlePlace.user_ratings_total} reviews)</span>}
          <p>{description}</p>
          <p>{address}</p>
          {/* {isAccomodation() && <p>Price: {`$${minPrice} - $${maxPrice}`}</p>}
          {!isAccomodation() && <p>Price: {fields.price !== undefined && fields.price !== null && fields.price.length > 0 ? `$${fields.price}` : ''}</p>} */}
          <p>Price: {renderPrice(fields)}</p>
          <p>Website:
            <a href={fields.website} target="_blank">
              {fields.website}
            </a>
          </p>
          <p>Phone: {fields.phone}</p>
          {renderBusinessStatus()}
          {renderOpeningHours()}
        </div>
      </div>
      <div className="google-reviews">
        {renderGoogleReviews()}
      </div>
    </div>
  )
}

export default PlaceDetails;