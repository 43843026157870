import React, { useState, useEffect } from 'react';
import RoomType from '~/Scripts/Client/RoomType';
import { message, Skeleton, Empty, Button, Popconfirm } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const RoomTypeList = ({ match }) => {

    const [roomTypes, setRoomTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadTypes, setLoadTypes] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if(loadTypes) {
            setLoading(true);
            RoomType.getRoomTypes(match.params.hotelId).then(
                (res) => {
                    setRoomTypes(res.data.data);
                    setLoading(false);
                    setLoadTypes(false);
                }
            ).catch(
                (error) => {
                    console.log(error);
                    if(error.response !== undefined)
                        message.error(error.response.data.error_description);
                    else
                        message.error("Something went wrong, please try again later.");
                        setLoading(false);
                }
            );
        }
    }, [loadTypes]);

    const deleteRoom = (id, index) => {
        RoomType.deleteRoom(match.params.hotelId, id).then(
            (res) => {
                roomTypes.splice(index, 1);
                setRoomTypes([...roomTypes]);
            }
        ).catch(
                (error) => {
                    console.log(error);
                    if(error.response !== undefined)
                        message.error(error.response.data.error_description);
                    else
                        message.error("Something went wrong, please try again later.");
                        setLoading(false);
                }
            );
    }

    const renderList = () => {
        if(!loading && roomTypes.length <= 0)
            return <Empty description="No Room Types" />

        return (
            <Skeleton active loading={loading}>
                <div className="room-type-list">
                    {roomTypes.map((room, index) => {
                        return (
                            <div className="room-types-info">
                                <div className="room-image">
                                    <img src={room.image !== null ? room.image.uri : "https://iguides.net/cityguideapi/v1/storage//public/questionmark.png"} />
                                </div>
                                <div className="room-info">
                                    <h3>{room.type}</h3>
                                    <p>Bed Type: <strong>{room.bed_type}</strong></p>
                                    <p>Number of Beds: <strong>{room.number_of_beds}</strong></p>
                                    <p>Capacity: <strong>{room.capacity}</strong></p>
                                    <p>Price: <strong>${room.price}</strong></p>
                                    <p>
                                        <Link to={`/hotel/${match.params.hotelId}/roomtypes/edit/${room.id}`}>
                                            <Button type="primary" 
                                                size="small"
                                                style={{marginRight: 20}}>Edit</Button>
                                        </Link>
                                        <Popconfirm
                                            title="Are you sure you want to delete this room?"
                                            onConfirm={() => deleteRoom(room.id, index)}
                                            okText="Yes"
                                            cancelText="No"
                                            >
                                            
                                                <Button type="primary" danger size="small">Delete</Button>
                                        </Popconfirm>
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Skeleton>
        );
    }

    return (
        <React.Fragment>
            <div style={{marginRight: 10, textAlign: "left", marginBottom: 15, textAlign: "left"}}>
                <div style={{marginBottom: 15}}>
                    <Link to={`/places`} 
                        style={{marginRight: 10, textAlign: "left", marginBottom: 15}}>
                        <Button style={{marginRight: 15}}
                                icon={<ArrowLeftOutlined />}
                                >Back</Button>
                    </Link>
                    <Link to={`/hotel/${match.params.hotelId}/roomtypes/add`} 
                        style={{marginRight: 10, textAlign: "left", marginBottom: 15}}>
                        <Button type="primary">Add New</Button>
                    </Link>
                </div>
                {renderList()}
            </div>
        </React.Fragment>
    );
}

export default RoomTypeList;