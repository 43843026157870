import React, { useState, useEffect } from 'react';
import Role from '~/Scripts/Client/Role';
import { Button, message, Modal, Input, Checkbox, Skeleton, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';

const RoleList = () => {
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [roleToEdit, setRoleToEdit] = useState();
    const [roleName, setRoleName] = useState();
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);

    const errorHandler = (error) => {
        console.log(error);
        if(error.response !== undefined)
            message.error(error.response.data.error_description);
        else
            message.error("Something went wrong, please try again later.");
        setLoading(false);
        setSaveLoading(false);
    }

    useEffect(() => {
        loadRoles();
        Role.getPermissions().then(
            (res) => {
                setPermissions(res.data);
            },
            (err) => errorHandler
        ).catch(errorHandler);
    }, []);

    const loadRoles = () => {
        setLoading(true);
        Role.getRoles().then(
            (res) => {
                setRoles(res.data);
                setLoading(false);
            },
            (err) => errorHandler
        ).catch(errorHandler);
    }

    const editClick = (role) => {
        setRoleToEdit(role);
        setRoleName(role.name);
        let rolePermissions = [];
        role.permissions.forEach((perm) => {
            rolePermissions.push(perm.id);
        });
        setSelectedPermissions(rolePermissions);
        setShowUpdateModal(true);
    }

    const checkBoxChanged = (e) => {
        let obj = e.target;
        let index = selectedPermissions.indexOf(obj.value);
        if(obj.checked) {
            selectedPermissions.push(obj.value);
        } else {
            selectedPermissions.splice(index, 1);
        }
        setSelectedPermissions([...selectedPermissions]);
    }

    const createRole = () => {
        if(!roleName || roleName.length <= 0) {
            message.error("Please enter role name");
            return false;
        }
        if(selectedPermissions.length <= 0) {
            message.error("Please select permissions");
            return false;
        }
        setSaveLoading(true);
        Role.createRole({name: roleName, permissions: selectedPermissions}).then(
            (res) => {
                setSaveLoading(false);
                loadRoles();
                setShowCreateModal(false);
            },
            (err) => errorHandler
        ).catch(errorHandler);
    }

    const updateRole = () => {
        setSaveLoading(true);
        Role.updateRole(roleToEdit.id, {name: roleName, permissions: selectedPermissions}).then(
            (res) => {
                setSaveLoading(false);
                loadRoles();
                setShowUpdateModal(false);
            },
            (err) => errorHandler
        ).catch(errorHandler);
    }

    const deleteRole = (roleId, index) => {
        Role.deleteRole(roleId).then(
            (res) => {
                roles.splice(index, 1);
                setRoles([...roles]);
            },
            (err) => errorHandler
        ).catch(errorHandler);
    }

    return (
        <div className="role-container" style={{textAlign: "left"}}>
            <Link to="/users">
                <Button style={{marginRight: 15}}>Back</Button>
            </Link>
            <Button type="primary" onClick={() => { setShowCreateModal(true); setRoleName(''); }}>Add Role</Button>
            <Skeleton active={true} loading={loading}>
                <div className="role-list">
                    {roles.map((role, idx) => {
                        return (
                            <div className="role-item">
                                <div className="role-name"
                                    style={{width: 200, textAlign: "left"}}
                                >{role.name}</div>
                                <div className="buttons">
                                    <Button type="primary" size="small"
                                        style={{marginRight: 15}}
                                        disabled={role.id === 1}
                                        onClick={() => editClick(role)}>Edit</Button>
                                    <Popconfirm title={`Are you sure to delete ${role.name}?`}
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={() => deleteRole(role.id, idx)}
                                        >
                                        <Button type="primary" disabled={role.id === 1} 
                                            danger size="small">Delete</Button>
                                    </Popconfirm>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Skeleton>
            <Modal
                title="Create New Role"
                visible={showCreateModal}
                okText="Save"
                cancelText="Cancel"
                // onOk={addCountry}
                onCancel={() => { setShowCreateModal(false); setSelectedPermissions([]); }}
                maskClosable={false}
                footer={[]}
                // confirmLoading={saveLoading}
            >
                <div className="role-edit">
                    <div>
                        <Input value={roleName} 
                        onChange={(e) => setRoleName(e.target.value)}
                        placeholder="Role Name" />
                    </div>
                    <div>
                        {permissions.map((permission) => {
                            return <Checkbox className="role-edit-checkbox"
                                onChange={checkBoxChanged}
                                checked={selectedPermissions.includes(permission.id)}
                                value={permission.id}>{permission.label}</Checkbox>
                        })}
                    </div>
                    <div style={{marginTop: 20, textAlign: "right"}}>
                        <Button onClick={() => setShowCreateModal(false)} 
                            style={{marginRight: 15}} disabled={saveLoading}>Cancel</Button>
                        <Button type="primary" loading={saveLoading}
                            onClick={createRole}>Save</Button>
                    </div>
                </div>
            </Modal>
            <Modal

                title="Edit Role"
                visible={showUpdateModal}
                okText="Save"
                cancelText="Cancel"
                // onOk={addCountry}
                onCancel={() => { setShowUpdateModal(false); setSelectedPermissions([]); }}
                maskClosable={false}
                footer={[]}
                // confirmLoading={saveLoading}
            >
                <div className="role-edit">
                    <div>
                        { roleToEdit && 
                        <Input value={roleName} 
                        onChange={(e) => setRoleName(e.target.value)}
                        placeholder="Role Name" /> }
                    </div>
                    <div>
                        {permissions.map((permission) => {
                            return <Checkbox className="role-edit-checkbox"
                                onChange={checkBoxChanged}
                                checked={selectedPermissions.includes(permission.id)}
                                value={permission.id}>{permission.label}</Checkbox>
                        })}
                    </div>
                    <div style={{marginTop: 20, textAlign: "right"}}>
                        <Button onClick={() => setShowUpdateModal(false)} 
                            style={{marginRight: 15}} disabled={saveLoading}>Cancel</Button>
                        <Button type="primary" loading={saveLoading}
                            onClick={updateRole}>Save</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default RoleList;