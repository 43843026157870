import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import WindowDimension from '~/Scripts/Utils/Window';
import ImageList from '~/Components/ImageGrabber/ImageList';


class SaveImages extends Component {
    static contextType = AppContext;
    // constructor(props) {
    //     super(props);

    // }

    state = {
        windowDimension: WindowDimension.getWindowDimensions()
    }

    componentDidMount() {
        this.context.setCurrentTab("save_images");
        WindowDimension.onWindowSizeChange(this.windowSizeChange);
    }

    windowSizeChange = () => {
        this.setState({windowDimension: WindowDimension.getWindowDimensions()});
    }

    render() {

        return (
            <div className="save-images-container">
                <ImageList useImageViewer={true} />
            </div>
        );
    }
}

export default SaveImages;