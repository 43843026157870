import React, { useState, useContext } from 'react';
import Place from '~/Scripts/Client/Place';
import User from '~/Scripts/Client/User';
import StarRating from 'react-star-ratings';
import Constants from '~/Scripts/Utils/Constants';
import { Link } from 'react-router-dom';
import { Card, Button, Tooltip, Popconfirm, message, Badge } from 'antd';
import { PlacesContext } from '~/Components/Context/PlacesContext';
import { DeleteOutlined, 
    CompassOutlined,
    GroupOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    InfoOutlined,
    PictureOutlined,
    SettingOutlined } from '@ant-design/icons';

const PlaceCard = ({ place, onShowDetail, onShowLocation }) => {
    const { id, name, image, fields, gallery, room_types } = place;
    const [isHidden, setIsHidden] = useState(place.is_hidden);
    const { places, setPlaces, csvPlaces, setCsvPlaces, 
        setViewerImage, setShowImageViewer } = useContext(PlacesContext);

    const getPlaceCity = () => {
        let city = '';
        if(fields.route !== null && fields.route !== undefined)
            city += `${fields.route}, `;
        if(fields.city !== null && fields.city !== undefined)
            city += fields.city;
        else if(fields.town !== null && fields.town !== undefined)
            city += fields.town;
        else
            city = 'No City';

        return city;
    }

    const deletePlace = (placeId) => {
        Place.deletePlace(placeId).then(
            (res) => {
                message.success("Place successfully deleted");
                setPlaces(places.filter((p) => {
                    return p.id !== place.id;
                }));

                setCsvPlaces(csvPlaces.filter((p) => {
                    return p.id !== place.id;
                }));
            }
        ).catch(
            (error) => {
                message.error("Something went wrong, please try again later.");
            }
        )
    }

    const hidePlace = () => {
        place.is_hidden = !isHidden;
        setIsHidden(!isHidden);

        Place.editPlace(id, place).then(
            (res) => {
                place = res.data;
            }
        ).catch(
            (error) => {
                console.log(error);
            }
        )
    }

    const hideButton = () => {
        if(!User.userCan('create_place') && !User.userCan('update_place'))
            return false;
            
        if(isHidden)
            return <Button shape="circle" icon={<EyeInvisibleOutlined />} onClick={hidePlace} />
        else
            return <Button shape="circle" icon={<EyeOutlined />} onClick={hidePlace} />
    }

    const zoomImage = () => {
        setViewerImage(image);
        setShowImageViewer(true);
    }

    const isAccomodation = () => {
        let accomodation = place.types.find((p) => { return p.type === "accommodation" });

        return accomodation !== null && accomodation !== undefined;
    }

    return (
        <Card hoverable className={"place-item-card" + (isHidden ? " hidden" : "")}>
            <div className="card-header">
                <img src={image} alt={name} 
                    onClick={zoomImage} 
                    style={{cursor: "zoom-in"}}
                    />
            </div>
            <div>
                <h3 style={{marginBottom: 0}}>{name}</h3>
                <p style={{marginBottom: 1}}><strong>{getPlaceCity()}</strong></p>
                <StarRating 
                    rating={(fields.rating !== null && fields.rating !== undefined) ? parseFloat(fields.rating) : 0}
                    numberOfStars={5}
                    name={name}
                    starDimension="20px"
                    starSpacing="3px"
                    starRatedColor={Constants.primaryColor}
                    />
            </div>
            <div className="place-card-footer">
                <div className="card-buttons">
                    <Tooltip title="Gallery Images">
                        <Link to={`/place/gallery/${id}`}>
                            <Badge count={gallery.length} offset={[-9, 2]} 
                                style={{backgroundColor: Constants.primaryColor}}>
                                <Button shape="circle" icon={<PictureOutlined />} />
                            </Badge>
                        </Link>
                    </Tooltip>
                </div>
                <div className="card-buttons">
                    <Tooltip title="Hide Place">
                        {hideButton()}
                    </Tooltip>
                </div>
                <div className="card-buttons">
                    <Tooltip title="Place Details">
                        <Button shape="circle" icon={<InfoOutlined />} 
                            onClick={() => onShowDetail(place)} />
                    </Tooltip>
                </div>
                {/* <div className="card-buttons">
                    <Tooltip title="Update Place Location">
                        <Button shape="circle" icon={<CompassOutlined />} 
                            onClick={() => onShowLocation(place)} />
                    </Tooltip>
                </div> */}
                 <div className="card-buttons" style={{display:  isAccomodation() ? "block": "none"}}>
                    <Tooltip title="Manage Rooms">
                        <Link to={`/hotel/${place.id}/roomtypes`}>
                            {room_types && <Badge count={room_types.length} offset={[-9, 2]} 
                                style={{backgroundColor: Constants.primaryColor}}>
                                <Button shape="circle" icon={<GroupOutlined />} />
                            </Badge> }
                        </Link>
                    </Tooltip>
                </div> 
                <div className="card-buttons">
                    <Tooltip title="Edit Place">
                        <Link to={`/place/edit/${id}`}>
                            { User.userCan("update_place") &&
                            <Button shape="circle" icon={<SettingOutlined />} /> }
                        </Link>
                    </Tooltip>
                </div>
                <div className="card-buttons">
                    <Popconfirm
                        title="Are you sure you want to delete this place?"
                        onConfirm={() => deletePlace(id)}
                        okText="Yes"
                        cancelText="No"
                        >
                        <Tooltip title="Delete Place">
                            { User.userCan("delete_place") &&
                            <Button shape="circle" icon={<DeleteOutlined />} /> }
                        </Tooltip>
                    </Popconfirm>
                </div>
            </div>
        </Card>
    )
}

export default PlaceCard;