import React, { useState, useContext, useEffect } from 'react';
import { Menu } from 'antd';
import { AppContext } from '~/Components/Context/AppContext';
import { Link } from 'react-router-dom';
import User from '~/Scripts/Client/User';

import { 
    PieChartOutlined, 
    AppstoreOutlined,
    GlobalOutlined,
    CloudOutlined,
    PictureOutlined,
    CloudSyncOutlined,
    FileImageOutlined,
    UnorderedListOutlined,
    TeamOutlined,
    ApartmentOutlined,
    DeleteOutlined,
    CarOutlined,
    GatewayOutlined
    } from '@ant-design/icons';

const SideBarMenu = ({ collapse }) => {

    const { currentTab, setCurrentTab } = useContext(AppContext);
    const [tab, setTab] = useState(currentTab != null ? currentTab : "dashboard");

    useEffect(() => {
        setTab(currentTab);
    }, [currentTab]);
    
    const changeTabHandler = (e) => {
        // console.log(e.key);
        setTab(e.key);
        setCurrentTab(e.key);
    }

    return (
        <div className="sidebarContainer" style={{width: collapse ? 80 : 180}}>
            <div style={{position: "fixed", height: "100%", zIndex: 100}}>
                <Menu mode="inline"
                    inlineCollapsed={collapse}
                    selectedKeys={[tab]}
                    onClick={changeTabHandler}
                    style={{height: '100%'}}
                    >
                    <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
                        <Link to="/">Dashboard</Link>
                    </Menu.Item>
                    {User.userCan("view_tile") && 
                    <Menu.Item key="map_tile" icon={<AppstoreOutlined />}>
                        <Link to="/tiles">Map Tiles</Link> 
                    </Menu.Item> }
                    {User.userCan("view_tile") && 
                    <Menu.Item key="city_boundary" icon={<GatewayOutlined />}>
                        <Link to="/city/boundary">City Boundary</Link> 
                    </Menu.Item> }
                    {User.userCan("view_place") && 
                    <Menu.Item key="places" icon={<GlobalOutlined />}>
                        <Link to="/places">Places</Link>
                    </Menu.Item> }
                    {User.userCan("view_place") && 
                    <Menu.Item key="by_car_places" icon={<CarOutlined />}>
                        <Link to="/bycar">By Car</Link>
                    </Menu.Item> }
                    {User.userCan("view_place") && User.userCan("create_place") && 
                    <Menu.Item key="google_bot" icon={<CloudOutlined />}>
                        <Link to="/google/bot">Search Tools</Link>
                    </Menu.Item> }
                    {User.userCan("view_place") && User.userCan("update_place") && 
                    <Menu.Item key="save_images" icon={<PictureOutlined />}>
                        <Link to="/saved/images">Saved Images</Link>
                    </Menu.Item> }
                    {User.userCan("view_place") && User.userCan("update_place") && 
                    <Menu.Item key="pending_places" icon={<CloudSyncOutlined />}>
                        <Link to="/pending/places">Pending Places</Link>
                    </Menu.Item> }
                    {User.userCan("view_place") && User.userCan("update_place") && 
                    <Menu.Item key="pending_images" icon={<FileImageOutlined />}>
                        <Link to="/pending/images">Pending Images</Link>
                    </Menu.Item> }
                    {User.userCan("view_place") && User.userCan("view_category") && 
                    <Menu.Item key="categories" icon={<UnorderedListOutlined />}>
                        <Link to="/categories">Categories</Link>
                    </Menu.Item> }
                    {User.userCan("view_place") && User.userCan("update_place") && 
                    <Menu.Item key="cities" icon={<ApartmentOutlined />}>
                        <Link to="/cities">Countries</Link>
                    </Menu.Item> }
                    {User.userCan("view_user") &&
                    <Menu.Item key="users" icon={<TeamOutlined />}>
                        <Link to="/users">Users</Link>
                    </Menu.Item> }
                    {User.userCan("update_place") &&
                    <Menu.Item key="deleted_places" icon={<DeleteOutlined />}>
                        <Link to="/deleted/places">Deleted Places</Link>
                    </Menu.Item> }
                </Menu>
            </div>
        </div>
    )
}

export default SideBarMenu;