import BaseClient from './BaseClient';

class Image extends BaseClient {
  getPendingImages = () => {
    return this.get('/pending/images', this.defaultHeaders())
  }

  approveImage(id) {
    return this.post('/image/' + id + '/approve', null, this.defaultHeaders())
  }

  getSaveImageUrls = (search, folderId) => {
    if (folderId == null)
      folderId = '';
    return this.get('/get/save/image/urls?tag=' + search + '&folder_id=' + folderId, this.defaultHeaders())
  }

  deleteImage = (id) => {
    return this.delete('/image/url/' + id, this.defaultHeaders())
  }

  getNextLink = (link) => {
    link = link.replace(this.baseUrl, "", link);
    return this.get(link, this.defaultHeaders())
  }

  uploadUrl = (url) => {
    var data = {
      url: url
    }

    return this.post('/temp/image', data, this.defaultHeaders())
  }

  saveImage(images, tag, folderId) {
    let data = {
      tag: tag,
      url: images,
      folder_id: folderId
    }

    return this.post("/save/image/urls", data, this.defaultHeaders());
  }

  updateImage = (id, data) => {
    return this.put('/image/url/' + id, data, this.defaultHeaders())
  }

  getSaveImageUrls = (search, folderId) => {
    if (folderId == null)
      folderId = '';
    return this.get('/get/save/image/urls?tag=' + search + '&folder_id=' + folderId, this.defaultHeaders())
  }
  searchImageTags = (tag) => {

    return this.get('/search/image/url/tags?tag=' + tag, this.defaultHeaders())
  }

  getFolders = (parentId, search) => {
    if (parentId == undefined)
      parentId = '';

    return this.get("/folder?parent_id=" + parentId + "&search=" + search);
  }

  createFolder(name, parentId) {
    let data = {
      name: name,
      parent_id: parentId
    }

    return this.post("/folder", data);
  }

  updateFolder(id, name, parentId) {
    let data = {
      name: name,
      parent_id: parentId
    }

    return this.put("/folder/" + id, data);
  }

  deleteFolder(folderId) {
    return this.delete("/folder/" + folderId);
  }
}

export default new Image();