import lineDecoder from 'decode-google-map-polyline'

class Direction {
    addPolyLine = (google, map, points, color, origin, destination) => {
        if(!origin)
            return;
            
        let path = lineDecoder(points);
        // Add the start and destination point on the path
        path.unshift({
            lat: origin.lat(),
            lng: origin.lng()
        })
        path.push(destination)
        var direction = new window.google.maps.Polyline({
            path: path,
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 4
        })

        direction.setMap(map)
        return direction;
    }
}

export default new Direction();