import React, { createContext, useState, useEffect } from 'react';

export const MapContext = createContext();

export const MapProvider = (props) => {

    const [map, setMap] = useState();
    const [markers, setMarkers] = useState([]);
    const [tiles, setTiles] = useState([]);
    const [google, setGoogle] = useState();
    const [tileDeleted, setTileDeleted] = useState(false);
    const [showHidden, setShowHidden] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [placeToShow, setPlaceToShow] = useState();
    
    const clearMarkers = () => {
        markers.forEach((m) => {
            m.setMap(null);
        })

        setMarkers([]);
    }

    const clearTiles = () => {
        tiles.forEach((t) => {
            t.setMap(null);
        })

        setTiles([]);
    }

    const state = {
        map: map,
        markers: markers,
        tiles: tiles,
        google: google,
        tileDeleted: tileDeleted,
        showHidden: showHidden,
        showDetail: showDetail,
        placeToShow: placeToShow,
        setMap: setMap,
        setMarkers: setMarkers,
        setTiles: setTiles,
        clearMarkers: clearMarkers,
        clearTiles: clearTiles,
        setGoogle: setGoogle,
        setTileDeleted: setTileDeleted,
        setShowHidden: setShowHidden,
        setShowDetail: setShowDetail,
        setPlaceToShow: setPlaceToShow,
    }

    return (
        <MapContext.Provider value={state}>
            {props.children}
        </MapContext.Provider>
    )

}
