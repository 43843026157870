import BaseClient from './BaseClient';

class RoomType extends BaseClient {

    getRoomTypes = (hotelId) => {
        return this.get(`/hotel/${hotelId}/roomtypes`, this.defaultHeaders());
    }

    create = (hotelId, data) => {
        return this.post(`/hotel/${hotelId}/roomtypes`, data, this.defaultHeaders());
    }

    update = (hotelId, roomTypeId, data) => {
        return this.put(`/hotel/${hotelId}/roomtypes/${roomTypeId}`, data, this.defaultHeaders());
    }

    deleteRoom = (hotelId, roomTypeId) => {
        return this.delete(`/hotel/${hotelId}/roomtypes/${roomTypeId}`, this.defaultHeaders());
    }

    getRoomTypeById = (hotelId, roomId) => {
        return this.get(`/hotel/${hotelId}/roomtypes/${roomId}`, this.defaultHeaders());
    }

    addImage = (roomTypeId, image) => {
        let data = {
            image: image
        };

        return this.post(`/roomtype/${roomTypeId}/image`, data, this.defaultHeaders());
    }

}

export default new RoomType();