import React, { useState, createRef } from 'react';
import { Form, Input, Button,message, Radio, Upload, Spin, Modal, Tooltip } from 'antd';
import RoomType from '~/Scripts/Client/RoomType';
import { Link } from 'react-router-dom';
import ImageCropper from '~/Components/Places/ImageCropper';
import ImageList from '~/Components/ImageGrabber/ImageList';
import Image from '~/Scripts/Client/Image';
import { useHistory } from "react-router-dom";
import { InfoCircleOutlined } from '@ant-design/icons';

const AddRoomType = ({ match }) => {
    const [form] = Form.useForm();
    const history = useHistory();

    const uploaderProps = {
        action: `${RoomType.baseUrl}/upload/image`,
        headers: RoomType.defaultHeaders().headers,
        multiple: false,
        name: "image",
        accept: ".jpg,.jpeg,.png",
        showUploadList: false,
        beforeUpload: () => setUploadLoading(true),
        onChange: (info) => uploadDone(info)
    }
    
    const [roomType, setRoomType] = useState();
    const [bedType, setBedType] = useState();
    const [numberOfBeds, setNumberOfBeds] = useState(1);
    const [bedCapacity, setBedCapacity] = useState(1);
    const [price, setPrice] = useState(0);
    const [saveLoading, setSaveLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [showCropper, setShowCropper] = useState(false);
    const [aspectRatio, setAspecRation] = useState(1.618 / 1);
    const [image, setImage] = useState("https://iguides.net/cityguideapi/v1/storage//public/questionmark.png");
    const [base64Image, setBase64Image] = useState();
    const [showSelectImage, setShowSelectImage] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const imageRef = createRef("image");
    const [roomData, setRoomData] = useState([""]);

    const saveRoomType = () => {
        let data = {
            type: roomType,
            bed_type: bedType,
            number_of_beds: parseInt(numberOfBeds),
            capacity: parseInt(bedCapacity),
            price: parseInt(price),
            fields: {
                data: roomData
            }
        };

        setSaveLoading(true);
        RoomType.create(match.params.hotelId, data).then(
            (res) => {
                if(base64Image !== null && base64Image !== undefined && base64Image.length > 0) {
                    RoomType.addImage(res.data.id, base64Image).then(
                        (res) => {
                            setSaveLoading(false);
                            message.success("Hotel Room Successfully Added");
                            history.push(`/hotel/${match.params.hotelId}/roomtypes`);
                        }
                    ).catch(
                        (error) => {
                            if(error.response !== undefined)
                                message.error(error.response.data.error_description);
                            else
                                message.error("Something went wrong, please try again later.");
                                setSaveLoading(false);
                        }
                    )
                } else {
                    setSaveLoading(false);
                    message.success("Hotel Room Successfully Added");
                    history.push(`/hotel/${match.params.hotelId}/roomtypes`);
                }
            }
        ).catch(
            (error) => {
                if(error.response !== undefined)
                    message.error(error.response.data.error_description);
                else
                    message.error("Something went wrong, please try again later.");
                    setSaveLoading(false);
            }
        )
    }

    const errorHandler = (error) => {
        if(error.response !== undefined)
            message.error(error.response.data.error_description);
        else
            message.error("Something went wrong, please try again later.");
        setLoading(false);
        setUploadLoading(false);
    }

    const onSelectImage = (url) => {
        setUploadLoading(true);
        setShowSelectImage(false);
        Image.uploadUrl(url).then(
            (res) => {
                setImage(res.data.url);
                // setUploadLoading(false);
                setShowCropper(true);
            }
        ).catch(errorHandler);
    }

    const uploadDone = (info) => {

        if (info.file.status === 'done') {
            setImage(info.file.response.url);
            setShowCropper(true);
        } else if (info.file.status === 'error') {
            setUploadLoading(false);
            message.error("error uploading image, please try again");
        }
    }

    const cancelCrop = () => {
        setShowCropper(false);
        setImage(image);
    }

    const saveImage = () => {
        setImage(base64Image);
        setShowCropper(false);
    }

    const onCropImage = (image) => {
        // console.log("cropping...");
        setUploadLoading(false);
        setBase64Image(image);
    }

    const updateRoomData = (index, value) => {
        roomData[index] = value;
        setRoomData([...roomData]);
    }

    const addDataField = () => {
        roomData.push("");
        setRoomData([...roomData]);
    }

    const renderImageButtons = () => {
        if(!showCropper) {
            return (
                <React.Fragment>
                    <Upload {...uploaderProps}>
                        <Button type="primary" disabled={loading}>Upload</Button>
                    </Upload>
                    <Button type="primary" onClick={() => setShowSelectImage(true)} 
                        style={{marginLeft: 20}}
                        disabled={loading}>Select</Button>
                    <Tooltip title="'Upload' button is used for uploading images in your local machine. 'Select' button to use images saved through Image Grabber.">
                        <Button type="link" icon={<InfoCircleOutlined />} size="medium" />
                    </Tooltip>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Radio.Group defaultValue="Landscape" buttonStyle="solid">
                        <Radio.Button value="Landscape" onClick={() => setAspecRation(1.618 / 1)}>Landscape</Radio.Button>
                        <Radio.Button value="Portrait" onClick={() => setAspecRation(1 / 1.618)}>Portrait</Radio.Button>
                        <Radio.Button value="Square" onClick={() => setAspecRation(1 / 1)}>Square</Radio.Button>
                        <Radio.Button value="Free Hand" onClick={() => setAspecRation(0)}>Free Hand</Radio.Button>
                    </Radio.Group>
                    <Button onClick={cancelCrop}>Cancel</Button>
                    <Button type="primary" onClick={saveImage}>Crop</Button>
                </React.Fragment>
            )
        }
    }

    const renderCropper = () => {
        if(!showCropper) {
            return <img src={image} alt={bedType} style={{marginBottom: 20}}
                id="place-image" ref={imageRef} />

        } else {
            return (
                <div className={!showCropper ? "hidden-cropper": "cropper"} style={{marginBottom: 20}}>
                    <ImageCropper showCropper={showCropper} onCrop={onCropImage} 
                        aspectRatio={aspectRatio}
                        imageRef={imageRef}>
                        <img src={image} alt={bedType} 
                            id="place-image" ref={imageRef} crossOrigin="Anonymous" />
                    </ImageCropper>
                </div>
            );
        }
    }

    const renderBackButton = () => {
        if(match !== undefined && match !== null)
            return (
                <Link to={`/hotel/${match.params.hotelId}/roomtypes`} style={{marginRight: 10}}>
                    <Button>Back</Button>
                </Link>
            );
    }

    return (
        <div className="add-room-type">
            <div className="room-type-form-container">
                <Form form={form} onFinish={saveRoomType}>
                    <div className="room-type-form">
                        <div className="form-item">
                            <label>
                                <p>Room Type</p>
                                <Form.Item name={['roomtype']} 
                                    rules={[{required: true, message: "Please enter room type"}]}
                                    initialValue={roomType}>
                                    <Input placeholder="Enter Room Type" 
                                        value={roomType}
                                        onChange={(e) => setRoomType(e.target.value)}
                                        />
                                </Form.Item>
                            </label>
                        </div>
                        <div className="form-item">
                            <label>
                                <p>Bed Type</p>
                                <Form.Item name={['bedtype']} 
                                    rules={[{required: true, message: "Please enter bed type"}]}
                                    initialValue={bedType}>
                                    <Input placeholder="Enter Bed Type" 
                                        value={bedType}
                                        onChange={(e) => setBedType(e.target.value)}
                                        />
                                </Form.Item>
                            </label>
                        </div>
                        <div className="form-item">
                            <label>
                                <p>Number of Beds</p>
                                <Form.Item name={['numberofbeds']} 
                                    rules={[{required: true, message: "Please enter number of beds"}]}
                                    initialValue={numberOfBeds}>
                                    <Input placeholder="Enter Number of Beds" 
                                        value={numberOfBeds}
                                        type="number"
                                        className="room-number"
                                        min={1}
                                        onChange={(e) => setNumberOfBeds(e.target.value)}
                                        />
                                </Form.Item>
                            </label>
                        </div>
                        <div className="form-item">
                            <label>
                                <p>Capacity</p>
                                <Form.Item name={['bedcapacity']} 
                                    rules={[{required: true, message: "Please enter capacity"}]}
                                    initialValue={bedCapacity}>
                                    <Input placeholder="Enter Bed Capacity" 
                                        value={bedCapacity}
                                        type="number"
                                        className="room-number"
                                        min={1}
                                        onChange={(e) => setBedCapacity(e.target.value)}
                                        />
                                </Form.Item>
                            </label>
                        </div>
                        <div className="form-item">
                            <label>
                                <p>Price ( $ )</p>
                                <Form.Item name={['price']} 
                                    rules={[{required: true, message: "Please enter price"}]}
                                    initialValue={price}>
                                    <Input placeholder="Enter price" 
                                        value={price}
                                        type="number"
                                        className="room-number"
                                        onChange={(e) => setPrice(e.target.value)}
                                        />
                                </Form.Item>
                            </label>
                        </div>
                        <div className="room-data-container">
                                <h3>
                                    Room Features 
                                    <Tooltip title="Add features of the Rooms, ex. Flat Screen TV, Sofa, etc ">
                                        <Button type="link" icon={<InfoCircleOutlined />} size="medium" />
                                    </Tooltip>
                                </h3>
                                {roomData.map((data, index) => {
                                    return (
                                        <div key={index} style={{display: "flex", flexDirection: "row", marginBottom: 10}}>
                                            <Input value={data} placeholder="Enter Feature"
                                                onChange={(e) => updateRoomData(index, e.target.value)} />
                                        </div>
                                    )
                                })}
                                <Button className="add-field" size="small" onClick={addDataField}
                                    type="primary">Add Field</Button>
                                <div style={{clear: "both"}}></div>
                            </div>
                        <div className="form-item">
                            <label>
                                {renderBackButton()}
                                <Button type="primary" htmlType="submit" 
                                    loading={saveLoading}
                                        >Save</Button>
                            </label>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="room-image-cropper">
                <Spin spinning={uploadLoading}>
                    {renderCropper()}
                </Spin>
                {renderImageButtons()}
            </div>
            <Modal
                visible={showSelectImage}
                title="Select Image"
                footer={[]}
                onCancel={() => setShowSelectImage(false)}
                width={"70%"}
                >
                    <div className="modal-image-list">
                        <ImageList onSelectImage={onSelectImage} />
                    </div>
            </Modal>
        </div>
    );
};

export default AddRoomType;