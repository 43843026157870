import BaseClient from './BaseClient';

class CityBoundary extends BaseClient {
    addBoundary = (data) => {
        return this.post('/citybounds', data, this.defaultHeaders());
    }

    editBoundary = (id, data) => {
        return this.put(`/citybounds/${id}`, data, this.defaultHeaders());
    }

    getBoundary = (id) => {
        return this.get(`/citybounds/${id}`, this.defaultHeaders());
    }

	deleteBoundary = (id) => {
		return this.delete(`/citybounds/${id}`, this.defaultHeaders());
	}

    getBoundaries = (bounds, search, city, state, country) => {
		let url = '/citybounds?page=1'

		if(bounds != null)
			url+= "&bounds=" + encodeURIComponent(bounds)

		if(search != null)
			url += '&search=' + search
		
		if(city != null)
			url += '&city=' + city

		if(state != null)
			url += '&state=' + state

		if(country != null)
			url += '&country=' + country

		return this.get(url)
	}

}

export default new CityBoundary();