import React, { useEffect, useState, useContext } from 'react';
import StarRating from 'react-star-ratings';
import Constants from '~/Scripts/Utils/Constants';
import ImageViewer from 'react-viewer';
import GoogleUtils from '~/Scripts/Client/Google';
import Place from '~/Scripts/Client/Place';
import AddNewPlace from './AddNewPlace';
import { Button, Skeleton, Checkbox, Modal, message, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { PlacesContext } from '~/Components/Context/PlacesContext';
import PlaceTypes from '~/assets/placetypes';

const GooglePlaceDetail = ({ place, setPlace, showDetail, currentType, showMarkerInMap }) => {

  const { selectedTypes, setSelectedTypes,
    selectedGoogleTypes, setSelectedGoogleTypes } = useContext(PlacesContext);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [googlePlace, setGooglePlace] = useState(place);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [placeToAdd, setPlaceToAdd] = useState();
  const [showAddPlace, setShowAddPlace] = useState(false);
  const [googlePlaceTypes, setGooglePlaceTypes] = useState([]);
  const [hideLoading, setHideLoading] = useState(false);

  useEffect(() => {
    if (place !== null && place !== undefined) {
      setLoading(true);
      GoogleUtils.getGooglePlaceDetail(place.place_id).then(
        (res) => {
          setGooglePlace(res.data.result);
          setGooglePlaceTypes(res.data.result.types);
          setLoading(false);
          setSelectedTypes(["food_drink", "restaurant"]);
          // trigger to show the marker info window in the map
          showMarkerInMap(res.data.result);
        }
      ).catch(
        (error) => {
          console.log(error);
          setLoading(false);
        }
      )
    }
  }, [place]);

  const getPlaceImage = () => {
    if (googlePlace.photos !== null && googlePlace.photos !== undefined && googlePlace.photos.length > 0)
      return 'https://maps.googleapis.com/maps/api/place/photo?maxwidth=750&photoreference=' + googlePlace.photos[0].photo_reference + '&key=' + process.env.REACT_APP_GOOGLE_MAP_KEY;
    else
      return "https://iguides.net/cityguideapi/v1/storage//public/questionmark.png";
  }

  const checkBoxChange = (checked, type) => {
    if (checked) {
      selectedTypes.push(type);
      setSelectedTypes([...selectedTypes]);
    } else {
      const index = selectedTypes.indexOf(type);
      if (index > -1) {
        selectedTypes.splice(index, 1);
        setSelectedTypes([...selectedTypes]);
      }
    }
  }

  const saveGooglePlace = () => {
    let types = [];
    if (googlePlace !== undefined && googlePlaceTypes.includes("food")) {
      types = selectedTypes;
      if (!types.includes(currentType) && (currentType !== "food_drink" || currentType !== "restaurant") && currentType.length > 0)
        types.push(currentType);
    } else {
      types = GoogleUtils.getPlaceCategory(googlePlaceTypes);
    }

    setSelectedTypes(types);
    setSelectedGoogleTypes(googlePlace.types.filter((t) => {
      let exists = PlaceTypes.find((pt) => {
        return pt.type === t;
      });

      return exists !== null && exists !== undefined;
    }));

    let data = {
      name: googlePlace.name,
      address: googlePlace.formatted_address,
      description: "",
      is_published: 1,
      latitude: googlePlace.geometry.location.lat,
      longitude: googlePlace.geometry.location.lng,
      rating: googlePlace.rating != null ? googlePlace.rating : 0,
      type: types,
      google_type: googlePlace.types,
      image: getPlaceImage(),
      fields: {
        phone: googlePlace.international_phone_number,
        website: googlePlace.website,
        rating: googlePlace.rating,
        google_place_id: googlePlace.place_id,
        google_reviews: googlePlace.reviews,
        google_map_url: googlePlace.url,
        opening_hours: (googlePlace.opening_hours != null) ? googlePlace.opening_hours : null
      }
    }

    if (googlePlace.address_components != undefined) {
      googlePlace.address_components.forEach((address) => {
        if (address.types.includes("route")) {
          data.fields.route = address.long_name;
        }

        if (address.types.includes("locality"))
          data.fields.city = address.long_name;

        if (address.types.includes("country"))
          data.fields.country = address.long_name;
      });
    }

    setPlaceToAdd(data);
    setShowAddPlace(true);

    // setSaveLoading(true);
    // Place.addPlace(data).then(
    //     (response) => {
    //         Place.updateImageByUrl(response.data.id, data.image).then(
    //             (res) => {
    //                 message.success("Place successfully saved.");
    //                 setSaveLoading(false);
    //             }
    //         )
    //     }
    // ).catch(
    //     (error) => {
    //         setSaveLoading(false);
    //         if(error.response !== undefined)
    //             message.error(error.response.data.error_description);
    //         else
    //             message.error("Something went wrong, please try again.");
    //     }
    // )
  }

  const hidePlace = (placeId, index) => {
    setHideLoading(true);
    GoogleUtils.hideGooglePlace(placeId).then(
      (res) => {
        message.success("Place successfull hidden");
        place.hidden = 1;
        setPlace({ ...place });
        setHideLoading(false);
      }
    ).catch(
      (error) => {
        console.log(error);
        setHideLoading(false);
      }
    )
  }

  const unhidePlace = (placeId) => {
    setHideLoading(true);
    GoogleUtils.unhideGooglePlace(placeId).then(
      (res) => {
        message.success("Place successfull hidden");
        place.hidden = 0;
        setPlace({ ...place });
        setHideLoading(false);
      }
    ).catch(
      (error) => {
        console.log(error);
        setHideLoading(false);
      }
    )
  }

  const renderHideButton = (place) => {
    if (place.hidden) {
      return <Button
        style={{ marginLeft: 10 }}
        loading={hideLoading}
        onClick={() => unhidePlace(place.place_id)}>Unhide</Button>;
    } else {
      return (
        <Button style={{ marginLeft: 10 }} onClick={() => hidePlace(place.place_id)} loading={hideLoading}>Hide</Button>
      )
    }
  }

  const renderGoogleLink = () => {
    if (googlePlace.url === null || googlePlace.url === undefined)
      return null;

    return (
      <a href={googlePlace.url} target="_blank" style={{ marginLeft: 10 }}>
        <Button>View Map</Button>
      </a>
    );
  }

  const renderGoogleReviews = () => {
    if (googlePlace.reviews === null || googlePlace.reviews === undefined)
      return null;

    return (
      <React.Fragment>
        {googlePlace.reviews.map((review, index) => {
          return (
            <div className="user-review" key={index}>
              <div className="user-photo">
                <img src={review.profile_photo_url} />
              </div>
              <div className="user-rating">
                <p><strong>{review.author_name}</strong></p>
                <div>
                  {(place.rating !== null && place.rating !== undefined) !== null ? parseFloat(review.rating) : 0} <StarRating
                    rating={(place.rating !== null && place.rating !== undefined) !== null ? parseFloat(review.rating) : 0}
                    numberOfStars={5}
                    name="user_review"
                    starDimension="20px"
                    starSpacing="3px"
                    starRatedColor={Constants.primaryColor}
                  /><i style={{ marginLeft: 10 }}>{review.relative_time_description}</i>
                  <p>{review.text}</p>
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  const renderCheckboxes = () => {
    if (googlePlace === null || googlePlace === undefined)
      return null;

    if (googlePlace !== undefined && googlePlace.types.includes("food"))
      return (
        <div className="checkboxes">
          <Checkbox
            onChange={(e) => checkBoxChange(e.target.checked, "food_drink")}
            defaultChecked={true}
          >Food & Drinks</Checkbox>
          <Checkbox
            onChange={(e) => checkBoxChange(e.target.checked, "restaurant")}
            defaultChecked={true}
          >Restaurant</Checkbox>
        </div>
      );

    return null;
  }

  const renderBusinessStatus = () => {
    if (googlePlace === null || googlePlace === undefined || loading)
      return null;

    if (googlePlace.business_status === null || googlePlace.business_status === undefined)
      return null;

    return (
      <div className="opening-hours">
        <p className={googlePlace.business_status.includes("CLOSE") ? "status-close" : "open"}>
          Business Status: <strong>{googlePlace.business_status}</strong></p>
      </div>
    );
  }

  if (place === null || place === undefined)
    return null;

  return (
    <Skeleton loading={loading} active={true}>
      <div className="google-place-details">
        <div className="place-image" style={{ opacity: googlePlace.hidden ? 0.5 : 1 }}>
          <img src={getPlaceImage()} alt={googlePlace.name}
            onClick={() => setShowImageViewer(true)} />
        </div>
        <div className="place-info">
          <h2 style={{ opacity: googlePlace.hidden ? 0.5 : 1 }}><strong>{googlePlace.name}</strong></h2>
          <div>
            {googlePlace.rating !== null && googlePlace.rating !== undefined ? parseFloat(googlePlace.rating) : 0} <StarRating
              rating={googlePlace.rating !== null && googlePlace.rating !== undefined ? parseFloat(googlePlace.rating) : 0}
              numberOfStars={5}
              name={googlePlace.name}
              starDimension="15px"
              starSpacing="1px"
              starRatedColor={Constants.primaryColor}
            />
            <span>({googlePlace.user_ratings_total})</span>
          </div>
          <p>{googlePlace.international_phone_number}</p>
          <a href={googlePlace.website} target="_blank">{googlePlace.website}</a>
          {renderBusinessStatus()}
          {renderCheckboxes()}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button type="primary"
              loading={saveLoading}
              disabled={googlePlace.is_saved}
              onClick={saveGooglePlace}>Save to Places</Button>
            {renderHideButton(googlePlace)}
            {renderGoogleLink()}
          </div>
        </div>
        <div className="google-reviews">
          {renderGoogleReviews()}
        </div>
        <Button className="close"
          shape="circle"
          onClick={() => showDetail(false)}
          icon={<CloseOutlined />}
        />
      </div>
      <ImageViewer
        visible={showImageViewer}
        images={[{ src: getPlaceImage() }]}
        disableMouseZoom={false}
        zoomSpeed={0.2}
        drag={false}
        onClose={() => setShowImageViewer(false)}
      />
      <Modal
        title="Add New Place"
        visible={showAddPlace}
        onCancel={() => setShowAddPlace(false)}
        width={800}
        footer={null}
      >
        <AddNewPlace googlePlace={placeToAdd} setModalVisible={setShowAddPlace} />
      </Modal>
    </Skeleton>
  )
}

export default GooglePlaceDetail;