import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import EditRoomType from '~/Components/HotelRoomTypes/EditRoomType';

class HotelRoomTypeAddNew extends Component {
    static contextType = AppContext;

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        this.context.setCurrentTab("places");
    }

    render() {

        return (
            <React.Fragment>
                <h1>Edit Room Type</h1>
                <EditRoomType match={this.props.match} />
            </React.Fragment>
        );
    }
}

// Places.propTypes = {

// };

export default HotelRoomTypeAddNew;