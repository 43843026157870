import React from 'react';
import { Button, Tooltip, Modal, message, Input } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Image from '~/Scripts/Client/Image';
import User from '~/Scripts/Client/User';

const ImageFolder = ({ folder, index, onClick, onDeleteFolder, folderName, setFolderName, saveFolder, editFolder }) => {

  const deleteFolder = (e) => {
    Modal.confirm({
      title: "Are you sure you want to delete this folder?",
      icon: <ExclamationCircleOutlined />,
      content: "Deleting this folder will also delete sub-folders and images inside this folder.",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        Image.deleteFolder(folder.id).then(
          (res) => {
            // this.state.folders.splice(index, 1);
            // this.setState({folders: this.state.folders});
            onDeleteFolder(folder, index);
            message.success("Folder deleted");
          }
        ).catch(
          (e) => {
            message.error("Something went wrong, please try again");
          }
        )
      }
    });
  }

  const getFolderName = (folderName) => {
    if(folderName.length > 20) {
      return folderName.substr(0, 17) + "...";
    }

    return folderName;
  }

  const renderFolderName = () => {
    if (folder.edit) {
      return (
        <div className="folder-item">
          <Input value={folderName}
            id="add-folder-input"
            onChange={(e) => setFolderName(e.target.value)}
            onKeyDown={(e) => saveFolder(e, index)} />
        </div>
      );
    } else {
      return (<p onDoubleClick={() => editFolder(folder, index)}>{getFolderName(folder.name)}</p>);
    }
  }

  return (
    <div className="image-folder">
      <Tooltip title={folder.name}>
        <img src={require("~/assets/images/folder.png")} onClick={onClick} />
      </Tooltip>
      {renderFolderName()}
      <Tooltip title="delete">
        {User.userCan("manage_folders") &&
          <Button danger className="folder-delete"
            onClick={(e) => deleteFolder(e)}
            shape="circle" icon={<CloseOutlined />}
          />}
      </Tooltip>
    </div>
  )
}

export default ImageFolder;