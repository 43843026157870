import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import CategoryList from '~/Components/Categories/CategoryList';
// import PropTypes from 'prop-types';

class Categories extends Component {
    static contextType = AppContext;
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {  
        this.context.setCurrentTab("categories");
    }

    render() {

        return (
            <div>
                <h1>Categories</h1>
                <div>
                    <CategoryList />
                </div>
            </div>
        );
    }
}

// Dashboard.propTypes = {

// };

export default Categories;