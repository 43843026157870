import React from 'react';
import './App.less';
import Login from './Components/Pages/Login';
import { Route, Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AppProvider } from './Components/Context/AppContext';
import Main from './Components/Layout/Main';
import { PlacesProvider } from '~/Components/Context/PlacesContext';
import { ByCarProvider } from '~/Components/Context/ByCarContext';

function App() {
  const history = createBrowserHistory();
  return (
    <div className="App">
      <AppProvider>
        <PlacesProvider>
          <ByCarProvider>
            <Router history={history}>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Main />
              </Switch>
            </Router>
          </ByCarProvider>
        </PlacesProvider>
      </AppProvider>
      
    </div>
  );
}

export default App;
