import React, { useState, createRef, useEffect } from 'react';
import { Form, Input, Select, Upload, message, Button, Radio, Spin } from 'antd';
import User from '~/Scripts/Client/User';
import ImageCropper from '~/Components/Places/ImageCropper';
import Role from '~/Scripts/Client/Role';

const { Option } = Select;

const CreateUser = ({userAdded, cancelSave}) => {

    const defaultImage = "https://iguides.net/cityguideapi/v1/storage//public/questionmark.png";
    const [form] = Form.useForm();

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [type, setType] = useState();
    const [password, setPassword] = useState();
    const [confirm, setConfirm] = useState();
    const [uploadLoading, setUploadLoading] = useState(false);
    const [showCropper, setShowCropper] = useState(false);
    const [aspectRatio, setAspecRatio] = useState(1 / 1);
    const [image, setImage] = useState(defaultImage);
    const [base64Image, setBase64Image] = useState();
    const imageRef = createRef("image");
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);

    const uploaderProps = {
        action: `${User.baseUrl}/upload/image`,
        headers: User.defaultHeaders().headers,
        multiple: false,
        name: "image",
        accept: ".jpg,.jpeg,.png",
        showUploadList: false,
        beforeUpload: () => setUploadLoading(true),
        onChange: (info) => uploadDone(info)
    }

    useEffect(() => {
        Role.getRoles().then(
            (res) => {
                setRoles(res.data);
            },
            (err) => errorHandler
        ).catch(errorHandler);
    }, []);

    const errorHandler = (error) => {
        console.log(error);
        if(error.response !== undefined)
            message.error(error.response.data.error_description);
        else
            message.error("Something went wrong, please try again later.");
    }

    const onCropImage = (image) => {
        // console.log("cropping...");
        setUploadLoading(false);
        setBase64Image(image);
    }

    const uploadDone = (info) => {

        if (info.file.status === 'done') {
            setImage(info.file.response.url);
            setShowCropper(true);
        } else if (info.file.status === 'error') {
            setUploadLoading(false);
            message.error("error uploading image, please try again");
        }
    }

    const cancelCrop = () => {
        setShowCropper(false);
        setImage(defaultImage);
    }

    const saveImage = () => {
        setImage(base64Image);
        setShowCropper(false);
    }

    const saveUser = () => {
        setLoading(true);
        let data = { name, email, role_id: type, password, password_confirmation: confirm }
        User.createUser(data).then(
            (res) => {
                if(base64Image) {
                    User.changeAvatar(res.data.id, {image: base64Image}).then(
                        (response) => {
                            setLoading(false);
                            message.info("User Successfully added");
                            resetFields();
                            userAdded();
                        }, (error) => {
                            if(error.response !== undefined)
                                message.error(error.response.data.error_description);
                            else
                                message.error("Something went wrong, please try again later.");
                            setLoading(false);
                        }
                    );
                } else {
                    message.info("User Successfully added");
                    resetFields();
                    setLoading(false);
                    userAdded();
                }
            }, (err) => {
                if(err.response !== undefined)
                    message.error(err.response.data.error_description);
                else
                    message.error("Something went wrong, please try again later.");
                setLoading(false);
            }
        );
    }

    const resetFields = () => {
        setName('');
        setEmail('');
        setType('');
        setPassword('');
        setConfirm('');
        setImage(defaultImage);

        form.setFieldsValue({
            'type': '',
            'name': '',
            'email': '',
            'new_pass': '',
            'confirm_pass': ''
        });
    }

    return (
        <div className="create-user-form">
            <div className="uploader">
            <Spin spinning={uploadLoading}>
                {!showCropper &&
                    <img src={image} alt={name} 
                        id="place-image" ref={imageRef} /> }
                {showCropper &&
                    <div className={!showCropper ? "hidden-cropper": "cropper"}>
                        <ImageCropper showCropper={showCropper} onCrop={onCropImage} 
                            aspectRatio={aspectRatio}
                            imageRef={imageRef}>
                            <img src={image} alt={name} 
                                id="place-image" ref={imageRef} crossOrigin="Anonymous" />
                        </ImageCropper>
                    </div> }
            </Spin>
            <div className="uploader-container">
                {!showCropper &&
                    <React.Fragment>
                        <Upload {...uploaderProps}>
                            <Button type="primary" disabled={loading}>Upload</Button>
                        </Upload>
                    </React.Fragment> }
                {showCropper &&
                    <React.Fragment>
                        <Button onClick={cancelCrop}>Cancel</Button>
                        <Button type="primary" onClick={saveImage}>Crop</Button>
                    </React.Fragment> }
            </div>
            </div>
            <div className="user-form">
                <Form onFinish={saveUser} form={form}>
                    <Form.Item
                        name="type"
                        rules={[{ required: true, message: "Please select User Type"}]}
                    >
                        <Select defaultValue="" className="select" 
                            onChange={(val) => setType(val)}>
                            <Option value="">- Select User Type -</Option>
                            {roles.map(role => {
                                return <Option value={role.id}>{role.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: "Please input user Display Name"}]}
                    >
                        <Input value={name} 
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Display Name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: "Please input user email"}]}
                    >
                        <Input type="email" value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="new_pass"
                        rules={[{ required: true, message: 'Please input your password!' }, { min: 6, message: 'Password must be atleast 6 characters.'}]}
                        hasFeedback
                    >
                        <Input.Password placeholder="Password" value={password} 
                            onChange={(e) => setPassword(e.target.value)} autocomplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="confirm_pass"
                        rules={[{ required: true, message: 'Please confirm your password!' },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                            if (!value || getFieldValue('new_pass') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),]}
                    >
                        <Input.Password placeholder="Confirm Password" value={confirm} 
                            onChange={(e) => setConfirm(e.target.value)} autocomplete="off" />
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => {resetFields(); cancelSave()}} style={{marginRight: 10}} disabled={loading}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default CreateUser;