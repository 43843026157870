import React, { useRef } from 'react';
import { BarChart } from '@opd/g2plot-react';

const VisitedPlaces = () => {
    const config = {
        height: 400,
        title: {
          visible: true,
          text: 'Visited Places',
        },
        description: {
          visible: true,
          text: 'Most Visited Places',
        },
        padding: 'auto',
        forceFit: true,
        xField: 'visits',
        yField: 'place',
        label: {
          visible: false,
          type: 'point',
        },
        point: {
          visible: false,
          size: 5,
        },
        xAxis: {
          tickCount: 10,
        },
        barStyle: {
          fill: "#03fcdf"
        },
        data: [
          { place: '108 Brasserie, London', visits: 345 },
          { place: '11 Howard, New York', visits: 320 },
          { place: '12th Knot, London', visits: 290 },
          { place: '11ème Domaine, Paris', visits: 265 },
          { place: '14 Hills, London', visits: 255 },
          { place: '14 Henrietta Street, Dublin', visits: 240 },
          { place: '190 Queens Gate, London', visits: 194 },
          { place: '228 Litres, Paris', visits: 161 },
          { place: '3 Nounours, Paris', visits: 150 },
        ],
    };

    const chartRef = useRef();

    return (
        <div className="dash-chart">
            <BarChart {...config}  chartRef={chartRef} />
        </div>
    )
}

export default VisitedPlaces;