import React, { useState, createContext, useEffect } from 'react';
import Auth from '~/Scripts/Client/Auth';
import User from '~/Scripts/Client/User';

export const AppContext = createContext();

export const AppProvider = props => {
    const [isLoggedIn, setIsLoggedIn] = useState(Auth.isLogin());
    const [loggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem("CityGuide.CurrentUser")));
    const [currentTab, setCurrentTab] = useState();

    useEffect(() => {
        User.getCurrentUser().then(
            (res) => {
                setLoggedUser(res.data);
                Auth.setCurrentUser(res.data);
            }
        )
    }, []);

    const state = {
        isLoggedIn: isLoggedIn,
        loggedUser: loggedUser,
        currentTab: currentTab,
        setIsLoggedIn: setIsLoggedIn,
        setLoggedUser: setLoggedUser,
        setCurrentTab: setCurrentTab
    };

    return (
        <AppContext.Provider value={state}>{props.children}</AppContext.Provider>
    );
};
