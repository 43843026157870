import React, { useState, useEffect, useContext } from 'react';
import PlaceType from '~/Scripts/Client/PlaceType';
import Place from '~/Scripts/Client/Place';
import PlaceCard from './PlaceCard';
import LoadMoreButton from '~/Components/LoadMoreButton';
import { PlacesContext } from '~/Components/Context/PlacesContext';
import {
  message, Skeleton, Select, Input, Modal, Empty, Button,
  Switch, Pagination, Form
} from 'antd';
import ImageViewer from 'react-viewer';
import PlaceDetails from './PlaceDetails';
import PlaceLocation from './PlaceLocation';
import { MapProvider } from '~/Components/Context/MapContext';
import WindowScreen from '~/Scripts/Utils/Window';
import { SearchOutlined, SyncOutlined, PlusOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PlaceTypes from '~/assets/placetypes';
import City from '~/Scripts/Client/City';
import User from '~/Scripts/Client/User';

const { Option } = Select;
const { Search } = Input;

const PlaceList = ({ google }) => {

  const [loadTypes, setLoadTypes] = useState(true);
  const [types, setTypes] = useState([]);
  const [loadmoreLoading, setLoadMoreLoading] = useState(false);
  const [savePlaceLoading, setSavePlaceLoading] = useState(false);
  const [placeToShow, setPlaceToShow] = useState();
  const [showPlaceDetail, setShowPlaceDetail] = useState(false);
  const [showPlaceLocation, setShowPlaceLocation] = useState(false);
  const [placeNames, setPlaceNames] = useState([]);
  const [countries, setCountries] = useState([]);
  const [showLocationFilter, setShowLocationFilter] = useState(false);

  const [googleTypes] = useState(PlaceTypes);
  const [sort, setSort] = useState("latest");
  const { places, placesData, setPlaces, setPlacesData, viewImage, showImageViewer, loadingPlaces, setLoadingPlaces,
    setShowImageViewer, loadPlaces, setLoadPlaces, position, setPosition,
    nextPageLink, setNextPageLink, totalCount, setTotalCount, hiddenOnly, setHiddenOnly,
    currentType, currentSubType, currentGoogleType, search, city, filter, subTypes,
    setCurrentType, setCurrentSubType, setCurrentGoogleType, setSearch, setCity, setFilter, setSubTypes,
    area, setArea, town, setTown, shownOnly, price, setShownOnly, setPrice,
    selectedCountry, setSelectedCountry, selectCountryId, setSelectedCountryId } = useContext(PlacesContext);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [towns, setTowns] = useState([]);

  useEffect(() => {
    PlaceType.getPlaceTypes().then(
      (response) => {
        setLoadTypes(false);
        setTypes(response.data);
      }
    ).catch(errorHandler);

    // City.getCities(selectCountryId).then(
    //     (res) => {
    //         setCities(res.data);
    //     }
    // ).catch(errorHandler);

    City.getCountries().then(
      (res) => {
        setCountries(res.data);
      },
      errorHandler
    );

    // if the previous position is scrolled, then scroll to that position
    if (position > 0)
      WindowScreen.scrollElement(position);
  }, []);

  useEffect(() => {
    if (loadPlaces) {
      setPlaces([]);
      getPlaces();
    }

    WindowScreen.onWindowScroll(scroll);

    return () => {
      WindowScreen.removeWindowScroll(scroll);
    }
  }, [loadPlaces]);

  // window scroll event handler
  const scroll = (e) => {
    if (e.srcElement.scrollTop > 0) {
      setPosition(e.srcElement.scrollTop);
    }
  };

  const getPlaces = () => {
    setLoadingPlaces(true);
    setLoadMoreLoading(true);
    setPlaces([]);
    let currentScrollPosition = position;
    let orderBy = null;
    let order = null;
    if (sort == "latest") {
      orderBy = "created_at";
      order = "DESC";
    } else if (sort == "updated") {
      orderBy = "updated_at";
      order = "DESC";
    } else if (sort == "high_rating") {
      orderBy = "rating";
      order = "DESC";
    } else if (sort == "low_rating") {
      orderBy = "rating";
      order = "ASC";
    }
    let perPage = placesData ? placesData.per_page : 20;
    let page = placesData ? placesData.current_page : 1;
    Place.getAll(search, perPage, currentType, page, orderBy, order, filter, currentSubType, currentGoogleType, selectedCountry, hiddenOnly, shownOnly, price).then(
      (response) => {
        setLoadPlaces(false);
        setLoadingPlaces(false);
        setLoadMoreLoading(false);
        setPlaces(response.data.data);
        setNextPageLink(response.data.next_page_url);
        setTotalCount(response.data.total);
        setPlacesData(response.data);
        // prevent the page from jerking
        // this is a hack to force the page stay on its current position
        // while loading new items
        WindowScreen.scrollElement(currentScrollPosition);
      }
    ).catch(errorHandler);
  }

  const exportCSV = () => {
    let orderBy = null;
    let order = null;
    if (sort == "latest") {
      orderBy = "created_at";
      order = "DESC";
    } else if (sort == "updated") {
      orderBy = "updated_at";
      order = "DESC";
    } else if (sort == "high_rating") {
      orderBy = "rating";
      order = "DESC";
    } else if (sort == "low_rating") {
      orderBy = "rating";
      order = "ASC";
    }
    let url = Place.getPlaceSearchUrl(search, currentType, orderBy, order, filter, currentSubType, currentGoogleType, selectedCountry, hiddenOnly, shownOnly, price);
    window.open(url);
  }

  // use this only for load more
  const setLoadedPlaces = (response) => {
    let currentScrollPosition = position;
    setLoadPlaces(false);
    setLoadingPlaces(false);
    setLoadMoreLoading(false);
    setPlaces(places.concat(response.data.data));
    setNextPageLink(response.data.next_page_url);
    WindowScreen.scrollElement(currentScrollPosition);
  }

  const errorHandler = (error) => {
    console.log(error);
    if (error.response !== undefined)
      message.error(error.response.data.error_description);
    else
      message.error("Something went wrong, please try again later.");
    setLoadingPlaces(false);
  }

  const onSortSelect = (sort) => {
    setSort(sort);
    setLoadPlaces(true);
  }

  // const onGoogleTypeSelect = (type) => {
  //     setCurrentGoogleType(type);
  //     setLoadPlaces(true);
  //     setSearch("");
  // }

  const onCountryChange = (val) => {
    setSelectedCountry(val);
    // setLoadPlaces(true);
    setCity('');
    setTown('');
    setFilter('');
    // if the value is not oOthers, then filter cities
    if (val && val !== "Others") {
      let country = countries.find((c) => c.name === val);
      setSelectedCountryId(country.id);
      City.getCities(country.id).then(
        (res) => {
          setCities(res.data);
        }
      ).catch(errorHandler);
    }
  }

  const onTypeSelect = (type) => {
    setSubTypes([]);
    setCurrentSubType("");
    setCurrentType(type);
    setLoadPlaces(true);
    setSearch("");
    PlaceType.getSubTypes(type).then(
      (res) => {
        setSubTypes(res.data);
      }
    ).catch(errorHandler);
  }

  const onSubTypeSelect = (type) => {
    setCurrentSubType(type);
    setSearch("");
    setLoadPlaces(true);
  }

  const onCitySelect = (c) => {
    setSearch("");
    if (c != null && c != undefined && c.length > 0)
      setFilter("city:" + encodeURI(c));
    else
      setFilter("");

    setTown('');
    setArea('');

    if (c) {
      City.getCityAreas(c).then(
        (res) => {
          setAreas(res.data);
        }
      );

      let cty = cities.find((city) => {
        return city.name === c
      });

      if (cty) {
        City.getCityTowns(cty.id).then(
          (res) => {
            setTowns(res.data);
          }
        )
      }
    }

    setCity(c);
    // setLoadPlaces(true);
  }

  const onAreaSelect = (c) => {
    setSearch("");
    if (c != null && c != undefined && c.length > 0)
      setFilter("area:" + encodeURI(c));
    else
      setFilter("");

    setArea(c);
  }

  const onTownSelect = (c) => {
    setSearch("");
    if (c != null && c != undefined && c.length > 0)
      setFilter("route:" + encodeURI(c));
    else
      setFilter("");

    setTown(c);
  }

  const hiddenOnlyToggle = () => {
    setHiddenOnly(!hiddenOnly);
    setLoadPlaces(true);
  }

  // const onSearchPlace = (search) => {
  //     setSearch(search);
  //     setLoadPlaces(true);
  // }

  const resetFilter = () => {
    setSearch("");
    setFilter("");
    setCurrentGoogleType("");
    setCurrentType("");
    setCurrentSubType("");
    setCity("");
    setTown("");
    setArea("");
    setSort("latest");
    setSelectedCountry("");
    setShownOnly(false);
    setPrice("");
    setSubTypes([]);
    setHiddenOnly(false);
    setCities([]);
    setLoadPlaces(true);
  }

  const onShowDetail = (place) => {
    setPlaceToShow(place);
    setShowPlaceDetail(true);
  }

  const onShowLocation = (place) => {
    setPlaceToShow(place);
    setShowPlaceLocation(true);
  }

  const onPaginationChange = (page, size) => {
    if (!placesData)
      return false;

    placesData.current_page = page;
    placesData.per_page = size;
    setLoadPlaces(true);
  }

  const onPageSizeChange = (current, size) => {
    if (!placesData)
      return false;

    placesData.current_page = 1;
    placesData.per_page = size;
    setLoadPlaces(true);
  }

  const updatePlaceLocation = () => {
    setSavePlaceLoading(true);
    Place.editPlace(placeToShow.id, placeToShow).then(
      () => {
        setSavePlaceLoading(false);
        setShowPlaceLocation(false);
        message.success("Place Location successfull updated");
        let updatedList = places.map((p) => {
          if (p === placeToShow.id) {
            placeToShow.newName = "update";
            return placeToShow;
          }
          return p;
        });
        setPlaces([...updatedList]);
      }
    ).catch(
      (error) => {
        console.log(error);
        setSavePlaceLoading(false);
      }
    )
  }

  // create a setTimeout placeholder to cancel when type fast
  var placeNameTimeOut = null;
  const getPlaceNames = (search) => {
    // clear the previous timeout if it is set
    if (placeNameTimeOut !== null) {
      clearTimeout(placeNameTimeOut);
      console.log("Clear timeout");
    }

    placeNameTimeOut = setTimeout(() => {
      Place.searchPlaceName(search, currentType, currentSubType, currentGoogleType, filter).then(
        response => {
          setPlaceNames(response.data);
        }
      ).catch(
        error => {
          console.log(error);
        }
      );
    }, 1000);
  }

  const PlaceListCards = () => {
    if (loadingPlaces)
      return (
        <div style={{ padding: 20 }}>
          <Skeleton active />
        </div>
      );

    if (places.length === 0)
      return <Empty description="No Places Found" />

    return (
      <React.Fragment>
        <p className="place-count">Places Count: {totalCount}</p>
        <div className="place-list">
          {places.map((place) => {
            return <PlaceCard place={place} key={place.id}
              onShowDetail={onShowDetail}
              onShowLocation={onShowLocation}
            />
          })}
          {/* <LoadMoreButton url={nextPageLink} 
                        onError={errorHandler} 
                        onFinish={setLoadedPlaces}  
                        loading={loadmoreLoading}
                        setLoading={setLoadMoreLoading}
                        /> */}
        </div>
        <div style={{ paddingBottom: 20 }}>
          {placesData && <Pagination
            current={placesData.current_page}
            pageSize={placesData.per_page}
            total={placesData.total}
            onChange={onPaginationChange}
            onShowSizeChange={onPageSizeChange}
            showTotal={total => `${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Total Places`}
          />}
        </div>
      </React.Fragment>
    );
  }

  const searchKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setLoadPlaces(true);
    }
  }

  const searchKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
    setSearch(e.target.value);
  }

  const searchSelect = (value, option, e) => {
    setLoadPlaces(true);
  }

  return (
    <MapProvider>
      <div className="place-filter">
        <div className="buttons" style={{ textAlign: "left", marginBottom: 15, marginTop: 15 }}>
          <Link to="/add/place">
            {User.userCan("create_place") &&
              <Button type="primary" icon={<PlusOutlined />}>Add Place</Button>}
          </Link>
          <Link to="/place/upload/csv">
            {User.userCan("create_place") &&
              <Button type="primary"
                icon={<FileExcelOutlined />}
                style={{ marginLeft: 15, marginRight: 15 }}>Upload CSV</Button>}
          </Link>
          <Button type="primary"
            onClick={exportCSV}
            icon={<FileExcelOutlined />}
            style={{ marginLeft: 15, marginRight: 15 }}>Export CSV</Button>
          <Button type="primary" icon={<SyncOutlined />}
            onClick={resetFilter}>Reset</Button>
          <div style={{ display: "inline-block", marginLeft: 15 }}>
            <Switch checked={hiddenOnly} onChange={hiddenOnlyToggle} />
            <span style={{ marginLeft: 10 }}>Hidden Only</span>
          </div>
          <div style={{ display: "inline-block", marginLeft: 15 }}>
            <Switch checked={shownOnly} onChange={() => { setShownOnly(!shownOnly); setLoadPlaces(true); }} disabled={loadingPlaces} />
            <span style={{ marginLeft: 10 }}>Not Hidden Only</span>
          </div>
        </div>
        <div className="filter-inputs">
          <Select defaultValue="" className="select" disabled={loadingPlaces}
            onSelect={onSortSelect} value={sort}>
            <Option value="">- Sort By -</Option>
            <Option value="latest">Latest Added</Option>
            <Option value="updated">Latest Updated</Option>
            <Option value="alphabetical">Alphabetical</Option>
            <Option value="high_rating">Highest Rating</Option>
            <Option value="low_rating">Lowest Rating</Option>
          </Select>
          <Select defaultValue="" className="select" disabled={loadingPlaces}
            onSelect={onTypeSelect} value={currentType}>
            <Option value="">- Select Category -</Option>
            <Option value="no_category">No Category</Option>
            {types.map((type) => {
              return <Option value={type.type}>{type.name}</Option>
            })}
          </Select>
          <Select defaultValue="" className="select" disabled={loadingPlaces}
            onSelect={onSubTypeSelect} value={currentSubType}>
            <Option value="">- Select Sub-Category -</Option>
            {subTypes.map((type) => {
              return <Option value={type.id}>{type.name}</Option>
            })}
          </Select>
        </div>
        <div className="filter-inputs">
          <Modal
            title="Filter by Location"
            visible={showLocationFilter}
            okText="Search"
            onCancel={() => setShowLocationFilter(false)}
            onOk={() => { setLoadPlaces(true); setShowLocationFilter(false); }}
          >
            <Form>
              <div className="form-item">
                <label className="location-filter-label">
                  <p class="lbl">Country</p>
                  <Form.Item>
                    <Select defaultValue="" className="select" disabled={loadingPlaces}
                      onSelect={onCountryChange} value={selectedCountry}>
                      <Option value="">- Select Country -</Option>
                      {countries.map((country) => {
                        return <Option value={country.name}>{country.name}</Option>
                      })}
                      <Option value="Others">Others</Option>
                    </Select>
                  </Form.Item>
                </label>
              </div>
              <div className="form-item">
                <label className="location-filter-label">
                  <p class="lbl">City</p>
                  <Form.Item>
                    <Select defaultValue={city} className="select" disabled={loadingPlaces}
                      value={city}
                      onSelect={onCitySelect}>
                      <Option value="">- Select City -</Option>
                      {cities.map((city) => {
                        return <Option value={city.name}>{city.name}</Option>
                      })}
                      <Option value="Others">Others</Option>
                    </Select>
                  </Form.Item>
                </label>
              </div>
              <div className="form-item">
                <label className="location-filter-label">
                  <p class="lbl">Town</p>
                  <Form.Item>
                    <Select defaultValue={town} className="select" disabled={loadingPlaces}
                      onSelect={onTownSelect}
                      value={town}>
                      <Option value="">- Select Town -</Option>
                      {towns.map((town) => {
                        return <Option value={town.name}>{town.name}</Option>
                      })}
                      <Option value="Others">Others</Option>
                    </Select>
                  </Form.Item>
                </label>
              </div>
              <div className="form-item">
                <label className="location-filter-label">
                  <p class="lbl">Area</p>
                  <Form.Item>
                    <Select defaultValue={area} className="select" disabled={loadingPlaces}
                      onSelect={onAreaSelect}
                      value={area}>
                      <Option value="">- Select Area -</Option>
                      {areas.map((area) => {
                        return <Option value={area}>{area}</Option>
                      })}
                      <Option value="Others">Others</Option>
                    </Select>
                  </Form.Item>
                </label>
              </div>
            </Form>
          </Modal>

          <Select value={price} disabled={loadingPlaces}
            onChange={(value) => {
              setPrice(value);
              setLoadPlaces(true);
            }}>
            <Option value="">- Select Price -</Option>
            <Option value={0}>Free</Option>
            <Option value={1}>$</Option>
            <Option value={2}>$$</Option>
            <Option value={3}>$$$</Option>
          </Select>

          <Select
            showSearch
            value={search}
            allowClear={true}
            placeholder="Search Place"
            showArrow={false}
            filterOption={false}
            showSearch={true}
            onSearch={getPlaceNames}
            onSelect={searchSelect}
            onChange={setSearch}
            notFoundContent={null}
            onBlur={(e) => e.preventDefault()}
            onInputKeyDown={searchKeyDown}
            onKeyUp={searchKeyUp}
            disabled={loadingPlaces}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            <Option value="">Search Place...</Option>
            {placeNames.map((name) => {
              return <Option value={name}>{name}</Option>
            })}
          </Select>
          <Button type="primary" icon={<SearchOutlined />}
            onClick={() => setLoadPlaces(true)} />

          <Button type="primary" onClick={() => setShowLocationFilter(true)}
            style={{ marginLeft: 15 }}>Location</Button>
        </div>
      </div>
      {PlaceListCards()}
      <ImageViewer
        visible={showImageViewer}
        images={[{ src: viewImage }]}
        disableMouseZoom={false}
        zoomSpeed={0.2}
        drag={false}
        onClose={() => setShowImageViewer(false)}
      />
      <Modal
        title="Place Detail"
        visible={showPlaceDetail}
        onCancel={() => setShowPlaceDetail(false)}
        footer={null}
        width={"70%"}
      >
        <PlaceDetails place={placeToShow} />
      </Modal>
      <Modal
        title="Update Place Location"
        visible={showPlaceLocation}
        onCancel={() => setShowPlaceLocation(false)}
        okText="Save"
        onOk={updatePlaceLocation}
        width={"70%"}
        confirmLoading={savePlaceLoading}
      >
        <PlaceLocation place={placeToShow} google={google}
          updatePlace={setPlaceToShow} />
      </Modal>
    </MapProvider>

  );
}

export default PlaceList;