import BaseClient from './BaseClient';

class Google extends BaseClient {
    direction = (origin, destination, mode) => {
        let url = '/direction?origin=' + origin + '&destination=' + destination;
        if(mode != null)
            url += '&mode=' + mode;
        return this.get(url);
    }

    searchPlace = (url, search) => {
        if (url == null)
            url = '/place';

        if (search != null)
            url += '?search=' + search;

        return this.get(url);
    }

    searchGooglePlace = (search, latLong, radius) => {
        let url = '/google/places/search?search=' + encodeURIComponent(search);

        if(latLong != null && radius != null)
            url += '&location=' + latLong + '&radius=' + radius;

        return this.get(url);
    }

    getGooglePlaceDetail = (placeId) => {
        let url = '/google/place/' + placeId;

        return this.get(url);
    }

    hideGooglePlace = (placeId) => {
      return this.post(`/google/${placeId}/hide`, [], this.defaultHeaders());
    }

    unhideGooglePlace = (placeId) => {
      return this.post(`/google/${placeId}/unhide`, [], this.defaultHeaders());
    }

    autoSuggestPlace = (search) => {
      return this.get(`/search/google/places?search=${search}`, [], this.defaultHeaders());
    }

    convertPlaceTypeToGoogle = ($type) => {
      switch($type) {
        case "drinking":
          return "bar";
        case "by_car":
          return "car_dealer";
        case "by_car":
          return "car_dealer";
        case "health_beauty":
          return "beauty_salon";
        case "shop_women":
          return "shopping_mall";
        case "shop_men":
          return "shopping_mall";
        case "home_shopping":
          return "home_goods_store";
        case "hotel":
          return "lodging";
        case "restaurants":
          return "restaurant";
        case "transport":
          return "transit_station";
        case "bars_pubs":
            return "bar";
        case "cafes":
          return "cafe";
        default:
          return $type;
      }
    }

    getPlaceCategory = ($types) => {
      let $categories = [];
      $types.forEach((type) => {
        if(type == 'lodging')
          if($categories.indexOf("accommodation") < 0)
            $categories.push("accommodation");
        if(type == 'restaurant') {
          if($categories.indexOf("restaurant") < 0)
            $categories.push("restaurant");
          if($categories.indexOf("food_drink") < 0)
            $categories.push("food_drink");
        }
        // if(type == 'bar')
        //   if($categories.indexOf("bars_pubs") < 0)
        //     $categories.push("bars_pubs");\
        // if(type == 'cafe')
        //   if($categories.indexOf("cafe") < 0)
        //     $categories.push("cafe");
      });
      return $categories;
    }

    findLocation = (google, address, callback) => {
      let geocoder = new google.maps.Geocoder()
        geocoder.geocode( { 'address': address}, (results, status) => {
          if (status == 'OK') {
            this.getGooglePlaceDetail(results[0].place_id).then(callback);
          }
        });
    }

    getPlaceImage = (place) => {
      if(place.photos !== null && place.photos !== undefined && place.photos.length > 0)
          return 'https://maps.googleapis.com/maps/api/place/photo?maxwidth=750&photoreference=' + place.photos[0].photo_reference + '&key=' + process.env.REACT_APP_GOOGLE_MAP_KEY;
      else
          return "https://iguides.net/cityguideapi/v1/storage//public/questionmark.png";
  }
}

export default new Google();