import React from 'react';
import { Table, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const Towns = ({ towns, editTown, deleteTown }) => {
    const columns = [
        {
            title: "Town Name",
            dataIndex: "name",
            index: "name",
            width: 200,
            render: text => <p>{text}</p>
        },
        {
            title: "Action",
            dataIndex: "id",
            index: "id",
            render: (text, town, idx) => (
                <div>
                    <Button type="primary" size="small" onClick={() => editTown(town)}
                        icon={<EditOutlined />} style={{marginRight: 10}}></Button>
                    <Popconfirm title={`Are you sure to delete ${town.name}?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteTown(town, idx)}
                            >
                        <Button danger size="small"
                            icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                </div>
            )
        }
    ];

    return (
        <div className="country-list">
            <Table columns={columns} dataSource={towns} size="small" />
        </div>
    );
}

export default Towns;