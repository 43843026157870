import React, { useState, useContext, useEffect, useRef } from 'react';
import Map from '~/Components/Maps/Map';
import MapTile from '~/Scripts/Client/MapTile';
import Place from '~/Scripts/Client/Place';
import PlaceType from '~/Scripts/Client/PlaceType';
import GoogleUtils from '~/Scripts/Client/Google';
import PlaceInfoWindow from '~/Components/Maps/PlaceInfoWindow';
import GooglePlaceList from './GooglePlaceList';
import GoogleClient from '~/Scripts/Client/Google';
import { MapContext } from '~/Components/Context/MapContext';
import { Polygon, Marker, InfoWindow, Polyline } from 'google-maps-react';
import { message, Select, Input, Slider, Switch, Button, Drawer, Tooltip } from 'antd';
import PlaceTypes from '~/assets/placetypes';
import { InfoCircleOutlined } from '@ant-design/icons';
import CityBoundary from '~/Scripts/Client/CityBoundary';

const { Option } = Select;
const { Search } = Input;

const GoogleBotMap = (props) => {

  const { map, google, tiles, setTiles, markers,
    setMarkers, showHidden, setShowDetail, setPlaceToShow } = useContext(MapContext);
  const [loadTypes, setLoadTypes] = useState(true);
  const [types, setTypes] = useState(PlaceTypes);
  const [currentType, setCurrentType] = useState("");
  const [currentTile, setCurrentTile] = useState();
  const [rating, setRating] = useState(0);
  const [includeDatabase, setIncludeDatabase] = useState(false);
  const [loadPlaces, setLoadPlaces] = useState(false);
  const [activeMarker, setActiveMarker] = useState();
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [markerPlace, setMarkerPlace] = useState();
  const [loadingPlaces, setLoadingPlaces] = useState(false);
  const [currentCity, setCurrentCity] = useState();
  const [showList, setShowList] = useState(false);
  const [cityBounds, setCityBounds] = useState([]);
  const mapMarkers = [];

  let timeOutInterval = null;
  let mapTiles = [...tiles];

  useEffect(() => {
    if (map !== null && map !== undefined) {
      mapTiles = [...mapTiles, tiles];
      google.maps.event.addListener(map, "idle", () => {
        let mapBounds = map.getBounds()
        let NE = mapBounds.getNorthEast()
        let SW = mapBounds.getSouthWest()
        let boundText = NE.lat() + ' ' + SW.lng() + ', ' + NE.lat() + ' ' + NE.lng() + ', ' +
          SW.lat() + ' ' + NE.lng() + ', ' + SW.lat() + ' ' + SW.lng() + ', ' + NE.lat() + ' ' + SW.lng();
        loadTiles(boundText);
        loadBoundaries(boundText);
      });

      // google.maps.event.addListener(map, "click", (e) => {
      //     console.log(e.placeId);
      // });
    }

    // if(loadTypes) {
    //     PlaceType.getPlaceTypes().then(
    //         (response) => {
    //             setLoadTypes(false);
    //             setTypes([...response.data]);
    //         }
    //     ).catch(errorHandler);
    // }

    if (loadPlaces) {
      loadMarkers(currentTile);
    }

  }, [map, loadTypes, loadPlaces, showHidden]);

  const onMarkerMount = (marker) => {
    mapMarkers.push(marker);
  }

  const showMarkerInMap = (place) => {
    var m = mapMarkers.find((mark) => {
      if (place.place_id === mark.props.id)
        return mark;
    });

    let image = place.photos != null ? ('https://maps.googleapis.com/maps/api/place/photo?maxwidth=750&photoreference=' + place.photos[0].photo_reference + '&key=' + process.env.REACT_APP_GOOGLE_MAP_KEY) : '';

    let pl = {
      name: place.name,
      image: image,
      fields: {
        rating: place.rating,
        google_place_id: place.place_id,
        google_map_url: place.url
      }
    }

    if (m.marker !== undefined && m.marker !== null)
      setActiveMarker(m.marker);
    setMarkerPlace(pl);
    setShowInfoWindow(true);
    map.setCenter(m.props.position);
  }

  const onTypeSelect = (type) => {
    setCurrentType(type);
    if (currentTile !== null && currentTile !== undefined)
      setLoadPlaces(true);
  }

  const searchCity = (search) => {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': search }, function (results, status) {
      if (status == 'OK') {
        setCurrentCity(search);
        // console.log(results);
        map.setCenter(results[0].geometry.location);
        if (results[0].place_id && results[0].types.includes("establishment")) {
          map.setZoom(18);
          setCurrentType("");
          let place = {
            name: "Random Place",
            place_id: results[0].place_id,
            types: []
          }

          setPlaceToShow(place);
          setShowDetail(true);
          setShowList(true);
        }
        // if(map.setCenter(results[0].place_id !== undefined && map.setCenter(results[0].place_id !== null) {

        // }
      }
    });
  }

  const loadTiles = (bounds) => {
    if (timeOutInterval !== null && timeOutInterval !== undefined)
      clearTimeout(timeOutInterval);

    timeOutInterval = setTimeout(() => {
      MapTile.getTiles(bounds).then(
        (response) => {
          let temp = response.data.data.filter((t) => {
            return !tileAlreadyInTheMap(t, mapTiles);
          });
          mapTiles = mapTiles.concat(temp);
          setTiles([...mapTiles]);
        }
      ).catch(errorHandler)
    }, 300);
  }

  const loadBoundaries = (bounds) => {
    CityBoundary.getBoundaries(bounds).then(
      (res) => {
        setCityBounds(res.data.data);
      }
    )
  }

  const loadMarkers = (center, length) => {
    // let type = GoogleUtils.convertPlaceTypeToGoogle(currentType);\
    let type = currentType;
    setLoadingPlaces(true);
    setShowList(true);
    setShowInfoWindow(false);
    let selectCategory = types.find((t) => {
      return t.type === type;
    });

    let name = '';
    if (selectCategory && selectCategory.label);
    name = `${selectCategory.label}, ${currentCity}`;

    Place.searchNearbyGooglePlaces(center, length, type, name, 1, rating, includeDatabase ? 1 : 0).then(
      (response) => {
        mapMarkers.splice(0, mapMarkers.length);
        setMarkers(response.data.data);
        setLoadPlaces(false);
        setLoadingPlaces(false);
      }
    ).catch(errorHandler)
  }

  const searchGooglePlaces = () => {
    if (currentType === '') {
      message.error("Please select a Category");
      return false;
    }

    if (map.zoom < 14) {
      message.error("Please zoom in the map to search places");
      return false;
    }

    let mapBounds = map.getBounds();
    let NE = mapBounds.getNorthEast();
    let SW = mapBounds.getSouthWest();

    let boundsArray = [
      { lat: NE.lat(), lng: SW.lng() },
      { lat: NE.lat(), lng: NE.lng() },
      { lat: SW.lat(), lng: NE.lng() },
      { lat: NE.lat(), lng: SW.lng() },
      { lat: NE.lat(), lng: SW.lng() },
    ];

    let tileLength = Math.floor(google.maps.geometry.spherical.computeLength(boundsArray) / 4);
    let center = map.getCenter();
    let tileCenter = `${center.lat()},${center.lng()}`;

    loadMarkers(tileCenter, tileLength);
  }

  const markerClick = (marker, place) => {
    let image = place.photos != null ? ('https://maps.googleapis.com/maps/api/place/photo?maxwidth=750&photoreference=' + place.photos[0].photo_reference + '&key=' + process.env.REACT_APP_GOOGLE_MAP_KEY) : '';
    GoogleUtils.getGooglePlaceDetail(place.place_id).then(
      response => {
        let pl = {
          name: place.name,
          image: image,
          fields: {
            rating: place.rating,
            google_place_id: place.place_id,
            google_map_url: response.data.result.url
          }
        }
        setActiveMarker(marker);
        setMarkerPlace(pl);
      }
    )


    setShowInfoWindow(true);
  }

  const mapClick = (props, map, event) => {
    if (event.placeId !== undefined && event.placeId !== null) {
      event.stop();
      setCurrentType("");
      let place = {
        name: "Random Place",
        place_id: event.placeId,
        types: []
      }

      setPlaceToShow(place);
      setShowDetail(true);
      setShowList(true);
    }
  }

  const infowWindowClose = () => {
    setActiveMarker(null);
    setMarkerPlace(null);
    setShowInfoWindow(false);
  }

  const resetFilter = () => {
    setCurrentType("");
    setRating(0);
    setIncludeDatabase(false);
    setShowList(false);
    setMarkers([]);
  }

  const errorHandler = (error) => {
    console.log(error);
    setLoadingPlaces(false);
    if (error.response !== undefined)
      message.error(error.response.data.message);
    else
      message.error("Something went wrong, please try again later.");
  }

  const tileAlreadyInTheMap = (tile, currentTiles) => {

    let exists = currentTiles.filter((t) => {
      return tile.id === t.id;
    });

    return exists.length > 0;
  }

  // check if the hidden places are shown or not
  let temp = markers;
  if (!showHidden)
    temp = markers.filter((p) => {
      return !p.hidden;
    });

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div className="google-filter" style={{ marginTop: 10 }}>
        <div className="containers">
          <Tooltip title="Use this search to move the map to a city. While the search below is used to search for Places in Google Map with the given category.">
            <Button type="link" icon={<InfoCircleOutlined />} size="medium" />
          </Tooltip>
          <div style={{ width: "40%", marginRight: 20 }}>
            <Search type="primary" placeholder="Search City in the Map..."
              enterButton onSearch={searchCity}
            />
          </div>
          <div style={{ width: "40%" }}>
            <Slider value={rating} max={5} step={0.1}
              onChange={setRating} />
          </div>
        </div>
        <div className="containers">
          <div style={{ paddingTop: 5, marginRight: 10 }}>
            <Switch checked={includeDatabase}
              onChange={() => setIncludeDatabase(!includeDatabase)} />
            <span>Include Saved Places</span>
          </div>
          <Select defaultValue="" className="select"
            value={currentType}
            style={{ width: 175, marginRight: 10 }}
            onSelect={onTypeSelect}>
            <Option value="">- Select Category -</Option>
            {types.map((type) => {
              return <Option value={type.type} key={type.type}>{type.label}</Option>
            })}
          </Select>
          <Button type="primary" style={{ marginRight: 10 }}
            onClick={searchGooglePlaces}>Search</Button>
          <Button onClick={resetFilter} style={{ marginRight: 10 }}>Reset</Button>
        </div>
      </div>
      <div style={{ display: 'flex', position: 'relative', height: '100%' }}>
        <Map style={{ flex: 1 }} google={props.google} onClick={mapClick}>
          {tiles.map((t) => {
            return <Polyline
              key={t.id}
              path={t.path}
              strokeColor="#FF0000"
              fillColor="#FF0000"
              strokeOpacity={0.7}
              strokeWeight={1}
            />
          })}
          {cityBounds.map((t) => {
            return <Polyline
              key={t.id}
              path={t.path}
              strokeColor="#0D55FA"
              strokeOpacity={0.5}
              strokeWeight={2}
            />
          })}
          {temp.map((m, i) => {
            let type = types.find((type) => {
              return type.type === currentType
            });
            let color = type != null ? type.color : '7F8C8D';
            let lat = m.geometry.location.lat
            let lng = m.geometry.location.lng
            return (
              <Marker
                ref={onMarkerMount}
                key={m.place_id}
                id={m.place_id}
                position={{ lat: lat, lng: lng }}
                name={m.name}
                title={m.name}
                icon={{
                  url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' +
                    (i + 1) + '|b575a2|FFFFFF'
                }}
                onClick={(props, marker) => markerClick(marker, m)}
              />
            );
          })}
          <InfoWindow
            marker={activeMarker}
            visible={showInfoWindow}
            onClose={infowWindowClose}
          >
            <PlaceInfoWindow place={markerPlace} setShowDetail={setShowDetail}
              setPlaceToShow={setPlaceToShow} setShowList={setShowList} />
          </InfoWindow>
        </Map>
        <Drawer
          visible={showList}
          closable={false}
          mask={false}
          getContainer={false}
          placement="left"
          style={{ position: "absolute", width: 400, zIndex: 99, display: showList ? "block" : "none" }}
        >
          <GooglePlaceList loading={loadingPlaces}
            showList={setShowList}
            showMarkerInMap={showMarkerInMap}
            currentType={currentType} />
        </Drawer>
      </div>
    </div>
  );
}

export default GoogleBotMap;