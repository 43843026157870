import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import GoogleBotMap from '~/Components/GoogleBot/GoogleBotMap';
import WindowDimension from '~/Scripts/Utils/Window';
import { MapProvider } from '~/Components/Context/MapContext';


class GoogleBot extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);

    }

    state = {
        windowDimension: WindowDimension.getWindowDimensions()
    }

    componentDidMount() {
        this.context.setCurrentTab("google_bot");
        WindowDimension.onWindowSizeChange(this.windowSizeChange);
    }

    windowSizeChange = () => {
        this.setState({windowDimension: WindowDimension.getWindowDimensions()});
    }

    render() {

        return (
            <MapProvider>
                <GoogleBotMap google={this.props.google} />
            </MapProvider>
        );
    }
}

export default GoogleBot;