import React, { useState, useEffect, createRef, useContext } from 'react';
import {
  message, Skeleton, Input, Select, Modal,
  InputNumber, Button, Upload, Spin, Radio, Switch, Form, Tooltip
} from 'antd';
import Place from '~/Scripts/Client/Place';
import Image from '~/Scripts/Client/Image';
import PlaceType from '~/Scripts/Client/PlaceType';
import { Link } from 'react-router-dom';
import ImageCropper from '~/Components/Places/ImageCropper';
import ImageList from '~/Components/ImageGrabber/ImageList';
import { useHistory } from 'react-router-dom';
import PlaceTypes from '~/assets/placetypes';
import { PlacesContext } from '~/Components/Context/PlacesContext';
import { ByCarContext } from '~/Components/Context/ByCarContext';
import { InfoCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const EditPlace = ({ match }) => {

  const { places, setPlaces, csvPlaces, setCsvPlaces } = useContext(PlacesContext);
  const { places: byCarPlaces, setPlaces: setByCarPlaces } = useContext(ByCarContext);
  const [loadTypes, setLoadTypes] = useState(true);
  const [loadSubTypes, setLoadSubTypes] = useState(false);
  const [placeId] = useState(match.params.placeId);
  const [place, setPlace] = useState();
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [placeLoaded, setPlaceLoaded] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedGoogleTypes, setSelectedGoogleTypes] = useState([]);
  const [selectedSubTypes, setSelectedSubTypes] = useState([]);
  const [googleTypes] = useState(PlaceTypes);
  const [showCropper, setShowCropper] = useState(false);
  const [aspectRatio, setAspecRation] = useState(1.618 / 1);
  const [image, setImage] = useState();
  const [base64Image, setBase64Image] = useState();
  const [showSelectImage, setShowSelectImage] = useState(false);
  const imageRef = createRef("image");
  const [form] = Form.useForm();
  const history = useHistory();

  const uploaderProps = {
    action: `${Place.baseUrl}/upload/image`,
    headers: Place.defaultHeaders().headers,
    multiple: false,
    name: "image",
    accept: ".jpg,.jpeg,.png",
    showUploadList: false,
    beforeUpload: () => setUploadLoading(true),
    onChange: (info) => uploadDone(info)
  }

  useEffect(() => {

    if (!placeLoaded) {
      setLoading(true);
      // prevent the loading of multiple times
      setPlaceLoaded(true);
      Place.getPlace(placeId).then(
        (res) => {
          // if the city is empty but there is value for town, set it as the city
          if ((res.data.fields.city === null || res.data.fields.city === undefined) &&
            (res.data.fields.town !== null && res.data.fields.town !== undefined))
            res.data.fields.city = res.data.fields.town;
          setPlace(res.data);
          setImage(res.data.image);
          setLoading(false);
          let t = res.data.types.map((m) => {
            return m.type
          });

          setSelectedTypes(t);
          form.setFieldsValue({
            "type": t
          });

          let gt = res.data.google_types.map((m) => {
            return m.type;
          });

          setSelectedGoogleTypes(gt);

          // load sub type on the first load of types
          if (t.length > 0) {
            PlaceType.getSubTypes(t.join(",")).then(
              (response) => {
                setLoadSubTypes(false);
                setSubTypes(response.data);
                if (res.data.sub_types !== undefined) {
                  let sub = res.data.sub_types.filter((m) => {
                    let exists = response.data.find((sub) => {
                      return sub.id === m.id;
                    });
                    console.log(exists);
                    if (exists)
                      return m.id;
                  });
                  let subIds = sub.map((m) => {
                    return m.id;
                  });
                  setSelectedSubTypes(subIds);
                }
              }
            ).catch(errorHandler);
          }

        }
      ).catch(errorHandler);
    }

    if (loadTypes) {
      PlaceType.getPlaceTypes().then(
        (response) => {
          setLoadTypes(false);
          setTypes(response.data);
        }
      ).catch(errorHandler);
    }

    if (loadSubTypes && selectedTypes.length > 0) {
      PlaceType.getSubTypes(selectedTypes.join(",")).then(
        (res) => {
          setLoadSubTypes(false);
          setSubTypes(res.data);
          // let t = place.sub_types.map((m) => {
          //     return m.id;
          // });
          // setSelectedSubTypes(t);
          let subs = selectedSubTypes.filter((subId) => {
            let id = subTypes.find(type => {
              return type.id === subId;
            });
            console.log(id);
            return (id !== undefined && id !== null)
          });
          setSelectedSubTypes(subs);
        }
      ).catch(errorHandler);
    } else if (selectedTypes.length === 0) {
      setSelectedSubTypes([]);
      setSubTypes([]);
    }
  }, [placeId, loadTypes, place, selectedTypes]);

  const errorHandler = (error) => {
    console.log(error);
    if (error.response !== undefined)
      message.error(error.response.data.error_description);
    else
      message.error("Something went wrong, please try again later.");
    setLoading(false);
    setPlaceLoaded(true);
    setUploadLoading(false);
    setSaveLoading(false);
  }

  const updatePlaceValue = (key, value) => {
    // console.log(value);
    place[key] = value;
    setPlace({ ...place });
    if (key === "type") {
      console.log(value);
      setSelectedTypes(value);
      setLoadSubTypes(true);
    }

    if (key === "sub_types")
      setSelectedSubTypes(value);

    if (key === "google_type")
      setSelectedGoogleTypes(value);
  }

  const savePlace = () => {
    setSaveLoading(true);
    Place.editPlace(place.id, place).then(
      (res) => {
        setPlace({ ...res.data });
        setSaveLoading(false);
        message.success("Place successfully saved");

        // update the state for the places
        places.forEach((p, i) => {
          if (p.id === res.data.id) {
            places[i] = res.data;
          }
        });
        setPlaces([...places]);

        csvPlaces.forEach((p, i) => {
          if (p.id === res.data.id) {
            csvPlaces[i] = res.data;
          }
        });
        setCsvPlaces([...csvPlaces]);

        if (byCarPlaces.data) {
          byCarPlaces.data.forEach((p, i) => {
            if (p.id === res.data.id) {
              // make sure that the travel time is preserve
              let pl = res.data;
              pl.travel_time = byCarPlaces.data[i].travel_time ? byCarPlaces.data[i].travel_time : null;
              pl.distance = byCarPlaces.data[i].distance ? byCarPlaces.data[i].distance : 0;
              byCarPlaces.data[i] = pl;
            }
          });
          setByCarPlaces({ ...byCarPlaces });
        }
      }
    ).catch(errorHandler);
  }

  const onCropImage = (image) => {
    // console.log("cropping...");
    setUploadLoading(false);
    setBase64Image(image);
  }

  const uploadDone = (info) => {

    if (info.file.status === 'done') {
      setImage(info.file.response.url);
      setShowCropper(true);
    } else if (info.file.status === 'error') {
      setUploadLoading(false);
      message.error("error uploading image, please try again");
    }
  }

  const cancelCrop = () => {
    setShowCropper(false);
    setImage(place.image);
  }

  const saveImage = () => {
    setUploadLoading(true);
    Place.uploadBase64Image(place.id, base64Image).then(
      (res) => {
        setShowCropper(false);
        setUploadLoading(false);
        setImage(res.data.image);

        places.forEach((p, i) => {
          if (p.id === res.data.id) {
            places[i] = res.data;
          }
        });
        setPlaces([...places]);

        csvPlaces.forEach((p, i) => {
          if (p.id === res.data.id) {
            csvPlaces[i] = res.data;
          }
        });
        setCsvPlaces([...csvPlaces]);

        if (byCarPlaces.data) {
          byCarPlaces.data.forEach((p, i) => {
            if (p.id === res.data.id) {
              // make sure that the travel time is preserve
              let pl = res.data;
              pl.travel_time = byCarPlaces.data[i].travel_time ? byCarPlaces.data[i].travel_time : null;
              pl.distance = byCarPlaces.data[i].distance ? byCarPlaces.data[i].distance : 0;
              byCarPlaces.data[i] = pl;
            }
          });
          setByCarPlaces({ ...byCarPlaces });
        }
      }
    ).catch(errorHandler);
  }

  const onSelectImage = (url) => {
    setUploadLoading(true);
    setShowSelectImage(false);
    Image.uploadUrl(url).then(
      (res) => {
        setImage(res.data.url);
        // setUploadLoading(false);
        setShowCropper(true);
      }
    ).catch(errorHandler);
  }

  const renderImageButtons = () => {
    if (!showCropper) {
      return (
        <React.Fragment>
          <Upload {...uploaderProps}>
            <Button type="primary">Upload</Button>
          </Upload>
          <Button type="primary" onClick={() => setShowSelectImage(true)}>Select</Button>
          <Tooltip title="'Upload' button is used for uploading images in your local machine. 'Select' button to use images saved through Image Grabber.">
            <Button type="link" icon={<InfoCircleOutlined />} size="medium" />
          </Tooltip>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Radio.Group defaultValue="Landscape" buttonStyle="solid">
            <Radio.Button value="Landscape" onClick={() => setAspecRation(1.618 / 1)}>Landscape</Radio.Button>
            <Radio.Button value="Portrait" onClick={() => setAspecRation(1 / 1.618)}>Portrait</Radio.Button>
            <Radio.Button value="Square" onClick={() => setAspecRation(1 / 1)}>Square</Radio.Button>
            <Radio.Button value="Free Hand" onClick={() => setAspecRation(0)}>Free Hand</Radio.Button>
          </Radio.Group>
          <Button onClick={cancelCrop}>Cancel</Button>
          <Button type="primary" onClick={saveImage}>Crop</Button>
        </React.Fragment>
      )
    }
  }

  const renderCropper = () => {
    if (!showCropper) {
      return <img src={image} alt={place.name}
        id="place-image" ref={imageRef} crossOrigin="Anonymous" />

    } else {
      return (
        <div className={!showCropper ? "hidden-cropper" : "cropper"}>
          <ImageCropper showCropper={showCropper} onCrop={onCropImage}
            aspectRatio={aspectRatio}
            imageRef={imageRef}>
            <img src={image} alt={place.name}
              id="place-image" ref={imageRef} crossOrigin="Anonymous" />
          </ImageCropper>
        </div>
      );
    }
  }

  const renderHotelAttributes = () => {
    if (selectedTypes.includes("accommodation")) {
      return (
        <React.Fragment>
          <h2>
            Hotel Amenities
            <Tooltip title="Add Hotel Amenities that is available in your hotel.">
              <Button type="link" icon={<InfoCircleOutlined />} size="medium" />
            </Tooltip>
          </h2>
          <div className="hotel-info">
            <div>
              <div className="form-item">
                <label>
                  <p>Free Parking</p>
                  <Switch checked={place.fields.free_parking}
                    onChange={(check) => {
                      place.fields.free_parking = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Free Wifi</p>
                  <Switch checked={place.fields.free_wifi}
                    onChange={(check) => {
                      place.fields.free_wifi = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Free Breakfast</p>
                  <Switch checked={place.fields.free_breakfast}
                    onChange={(check) => {
                      place.fields.free_breakfast = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Free Cancellation</p>
                  <Switch checked={place.fields.free_cancellation}
                    onChange={(check) => {
                      place.fields.free_cancellation = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
            </div>
            <div>
              <div className="form-item">
                <label>
                  <p>Pet Friendly</p>
                  <Switch checked={place.fields.pet_friendly}
                    onChange={(check) => {
                      place.fields.pet_friendly = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Kids Friendly</p>
                  <Switch checked={place.fields.kids_friendly}
                    onChange={(check) => {
                      place.fields.kids_friendly = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  if (loading)
    return <Skeleton />

  return (
    <div className="edit-place">
      <h2 style={{ width: "100%" }}>Edit {place.name}</h2>
      <Form onFinish={savePlace} form={form}>
        <div className="edit-place-container">
          <div className="edit-place-image">
            <div className="image">
              <Spin spinning={uploadLoading}>
                {renderCropper()}
              </Spin>
              {renderImageButtons()}
            </div>
            <Modal
              visible={showSelectImage}
              title="Select Image"
              footer={[]}
              onCancel={() => setShowSelectImage(false)}
              width={"70%"}
            >
              <div className="modal-image-list">
                <ImageList onSelectImage={onSelectImage} />
              </div>
            </Modal>
          </div>
          <div className="edit-place-details">
            <div className="edit-place-form">
              <div className="form-item">
                <label>
                  <p>Place Name</p>
                  <Form.Item name={['name']}
                    rules={[{ required: true, message: "Please enter place name" }]}
                    initialValue={place.name}>
                    <Input placeholder="Enter Place Name"
                      value={place.name}
                      onChange={(e) => updatePlaceValue("name", e.target.value)}
                    />
                  </Form.Item>
                  {place.fields.google_map_url && <a href={place.fields.google_map_url} target="_blank" title="View in Google Map">
                    <Button icon={<ArrowRightOutlined />}
                      style={{ marginLeft: 3 }} />
                  </a>}
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Address</p>
                  <Form.Item name={['address']}
                    rules={[{ required: true, message: "Please enter place address" }]}
                    initialValue={place.address}>
                    <TextArea placeholder="Enter Address"
                      value={place.address}
                      rows={2}
                      onChange={(e) => updatePlaceValue("address", e.target.value)}
                    />
                  </Form.Item>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Google Category</p>
                  <Select
                    mode="multiple"
                    optionLabelProp="label"
                    placeholder="Select Google Category"
                    style={{ width: '60%' }}
                    onChange={(value) => updatePlaceValue("google_type", value)}
                    value={selectedGoogleTypes}
                  >
                    {googleTypes.map((m) => {
                      return <Option value={m.type}
                        key={m.type}
                        label={m.label}>{m.label}</Option>
                    })}
                  </Select>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Category</p>
                  <Form.Item name={['type']}
                    rules={[{ required: true, message: "Please select category" }]}
                    initialValue={selectedTypes}>
                    <Select
                      mode="multiple"
                      optionLabelProp="label"
                      placeholder="Select Category"
                      style={{ width: '60%' }}
                      onChange={(value) => updatePlaceValue("type", value)}
                      value={selectedTypes}
                    >
                      {types.map((m) => {
                        return <Option value={m.type}
                          key={m.id}
                          label={m.name}>{m.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Sub Category</p>
                  <Select
                    mode="multiple"
                    optionLabelProp="label"
                    placeholder="Select Sub-Category"
                    style={{ width: '60%' }}
                    onChange={(value) => updatePlaceValue("sub_types", value)}
                    value={selectedSubTypes}
                  >
                    {subTypes.map((m) => {
                      return <Option value={m.id}
                        key={m.id}
                        label={m.name}>{m.name}</Option>
                    })}
                  </Select>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Description</p>
                  <TextArea placeholder="Enter Description"
                    value={place.description}
                    rows={3}
                    onChange={(e) => updatePlaceValue("description", e.target.value)}
                  />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Price</p>
                  <Select value={place.fields.price} style={{ width: "60%" }}
                    onChange={(value) => {
                      place.fields.price = value;
                      updatePlaceValue("fields", place.fields);
                    }}>
                    <Option value={0}>Free</Option>
                    <Option value={1}>$</Option>
                    <Option value={2}>$$</Option>
                    <Option value={3}>$$$</Option>
                  </Select>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Website</p>
                  <Input placeholder="Enter Website"
                    value={place.fields.website}
                    onChange={(e) => {
                      place.fields.website = e.target.value;
                      updatePlaceValue("fields", place.fields);
                    }}
                  />
                  <a href={place.fields.website} target="_blank" title="Visit Website">
                    <Button icon={<ArrowRightOutlined />}
                      style={{ marginTop: 3, marginLeft: 3 }} />
                  </a>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Phone</p>
                  <Input placeholder="Enter Phone"
                    value={place.fields.phone}
                    onChange={(e) => {
                      place.fields.phone = e.target.value;
                      updatePlaceValue("fields", place.fields);
                    }}
                  />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Area</p>
                  <Input placeholder="Enter Area"
                    value={place.fields.area}
                    onChange={(e) => {
                      place.fields.area = e.target.value;
                      updatePlaceValue("fields", place.fields);
                    }}
                  />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Town/Disctrict</p>
                  <Input placeholder="Enter Town/District"
                    value={place.fields.route}
                    onChange={(e) => {
                      place.fields.route = e.target.value;
                      updatePlaceValue("fields", place.fields);
                    }}
                  />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>City</p>
                  <Form.Item name={['city']}
                    rules={[{ required: true, message: "Please enter city" }]}
                    initialValue={place.fields.city}>
                    <Input placeholder="Enter City"
                      value={place.fields.city}
                      onChange={(e) => {
                        place.fields.city = e.target.value;
                        updatePlaceValue("fields", place.fields);
                      }}
                    />
                  </Form.Item>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Country</p>
                  <Input placeholder="Enter Country"
                    value={place.fields.country}
                    onChange={(e) => {
                      place.fields.country = e.target.value;
                      updatePlaceValue("fields", place.fields);
                    }}
                  />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Hidden</p>
                  <Switch checked={place.is_hidden}
                    onChange={(check) => {
                      place.is_hidden = check;
                      updatePlaceValue("is_hidden", check);
                    }} />
                </label>
              </div>
              {renderHotelAttributes()}
              <div className="form-item" style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "30%", marginRight: 15 }}></div>
                <div style={{
                  display: "flex", flexDirection: "row",
                  justifyContent: "flex-start"
                }}>
                  <Button onClick={() => history.goBack()}
                    style={{ marginRight: 15 }}
                  >Back</Button>
                  <Button type="primary" htmlType="submit" loading={saveLoading}
                    disabled={showCropper}
                  >Save</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default EditPlace;