import React, { useState, useContext } from 'react';
import { Upload, message, Button, Skeleton, Modal } from 'antd';
import Place from '~/Scripts/Client/Place';
import PlaceCard from '~/Components/Places/PlaceCard';
import { PlacesContext } from '~/Components/Context/PlacesContext';
import ImageViewer from 'react-viewer';
import PlaceDetails from './PlaceDetails';
import PlaceLocation from './PlaceLocation';
import { MapProvider } from '~/Components/Context/MapContext';

const UploadCSV = ({ google }) => {
    const [loading, setLoading] = useState(false);
    const [placeToShow, setPlaceToShow] = useState();
    const [showPlaceDetail, setShowPlaceDetail] = useState(false);
    const [showPlaceLocation, setShowPlaceLocation] = useState(false);
    const [savePlaceLoading, setSavePlaceLoading] = useState(false);
    const { csvPlaces, setCsvPlaces, viewImage, showImageViewer,
        setShowImageViewer } = useContext(PlacesContext);

    const uploaderProps = {
        action: `${Place.baseUrl}/place/upload/csv`,
        headers: Place.defaultHeaders().headers,
        multiple: false,
        name: "places",
        accept: ".csv",
        showUploadList: false,
        beforeUpload: () => setLoading(true),
        onChange: (info) => uploadDone(info)
    }

    const onShowDetail = (place) => {
        setPlaceToShow(place);
        setShowPlaceDetail(true);
    }

    const onShowLocation = (place) => {
        setPlaceToShow(place);
        setShowPlaceLocation(true);
    }

    const uploadDone = (info) => {

        if (info.file.status === 'done') {
            // setImage(info.file.response.url);
            setCsvPlaces(info.file.response);
            setLoading(false);
        } else if (info.file.status === 'error') {
            setLoading(false);
            message.error("error uploading image, please try again");
        }
    }

    const updatePlaceLocation = () => {
        setSavePlaceLoading(true);
        Place.editPlace(placeToShow.id, placeToShow).then(
            () => {
                setSavePlaceLoading(false);
                setShowPlaceLocation(false);
                message.success("Place Location successfull updated");
                let updatedList = csvPlaces.map((p) => {
                    if(p === placeToShow.id) {
                        placeToShow.newName = "update";
                        return placeToShow;
                    }
                    return p;
                });
                setCsvPlaces([...updatedList]);
            }
        ).catch(
            (error) => {
                console.log(error);
                setSavePlaceLoading(false);
            }
        )
    }

    return (
        <MapProvider>
            <div className="upload-csv">
                <Upload {...uploaderProps}>
                    <Button type="primary" disabled={loading}>Upload</Button>
                </Upload>
                <Skeleton loading={loading} active>
                    <div className="place-list">
                        {csvPlaces.map((place) => {
                            return <PlaceCard place={place} key={place.id} 
                                onShowDetail={onShowDetail}
                                onShowLocation={onShowLocation}
                            />
                        })}
                    </div>
                </Skeleton>
                <ImageViewer 
                    visible={showImageViewer}
                    images={[{src: viewImage}]}
                    disableMouseZoom={false}
                    zoomSpeed={0.2}
                    drag={false}
                    onClose={() => setShowImageViewer(false)}
                />
                <Modal 
                    title="Place Detail"
                    visible={showPlaceDetail}
                    onCancel={() => setShowPlaceDetail(false)}
                    footer={null}
                    width={"70%"}
                >
                    <PlaceDetails place={placeToShow} />
                    </Modal>
                <Modal 
                    title="Update Place Location"
                    visible={showPlaceLocation}
                    onCancel={() => setShowPlaceLocation(false)}
                    okText="Save"
                    onOk={updatePlaceLocation}
                    width={"70%"}
                    confirmLoading={savePlaceLoading}
                >
                    <PlaceLocation place={placeToShow} google={google} 
                        updatePlace={setPlaceToShow} />
                </Modal>
            </div>
        </MapProvider>
    );
}

export default UploadCSV;