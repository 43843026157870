import React, { useState, useEffect } from 'react';
import { Input, Button, message, Upload, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import PlaceType from '~/Scripts/Client/PlaceType';
import { CloseOutlined } from '@ant-design/icons';

const EditCategory = ({ match }) => {
    const [name, setName] = useState('');
    const [color, setColor] = useState('');
    const [image, setImage] = useState("https://iguides.net/cityguideapi/v1/storage//public/questionmark.png");
    const [loading, setLoading] = useState(false);
    const [catId] = useState(match.params.catId);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [subTypes, setSubTypes] = useState([]);

    const uploaderProps = {
        action: `${PlaceType.baseUrl}/placetype/${catId}/image`,
        headers: PlaceType.defaultHeaders().headers,
        multiple: false,
        name: "image",
        accept: ".jpg,.jpeg,.png",
        showUploadList: false,
        beforeUpload: () => setUploadLoading(true),
        onChange: (info) => uploadDone(info)
    }

    useEffect(() => {
        PlaceType.getPlaceTypes().then(
            (res) => {
                let type = res.data.find((t) => {
                    return t.id == catId;
                })

                if(type === null || type === undefined) {
                    message.error("Invalid Category");
                    return false;
                }

                setName(type.name);
                setColor(type.color);
                setImage(type.image);
                setSubTypes(type.sub_types);
            }
        ).catch(
            (error) => {
                console.log(error);
            }
        );
    }, [catId]);

    const reset = () => {
        setName('');
        setColor('');
    }

    const saveCategory = () => {
        if(name.length <= 0) {
            message.error("Please input Category Name");
            return false;
        }

        if(color.length <= 0) {
            message.error("Please Select Color");
            return false;
        }

        let type = name.replace(/[^a-zA-Z0-9 ]/gi, "")
                    .replace(/([\s]{1,3})/gi, "_").toLowerCase()
        let data = {
            type: type,
            name: name,
            color: color.replace('#', ''),
            sub_types: subTypes
        }

        setLoading(true);
        PlaceType.updateType(catId, data).then(
            (res) => {
                message.success("Category successfully added");
                // reset();
                setLoading(false);
            }
        ).catch(
            (error) => {
                console.log(error);
                message.error("Something went wrong, please try again.");
                setLoading(false);
            }
        )
    }

    const addSubTypeField = () => {
        let data = {
            name: "",
            type: ""
        }
        subTypes.push(data);
        setSubTypes([...subTypes]);
    }

    const removeSubTypeField = (index) => {
        subTypes[index].is_deleted = 1;
        setSubTypes([...subTypes]);
    }

    const updateSubTypeFields = (value, index) => {
        subTypes[index].name = value;
        subTypes[index].type = value.trim().toLowerCase().replace(" ", "_");
        setSubTypes([...subTypes]);
    }

    const uploadDone = (info) => {
        if (info.file.status === 'done') {
            setImage(info.file.response.image);
            setUploadLoading(false);
        } else if (info.file.status === 'error') {
            setUploadLoading(false);
            message.error("error uploading image, please try again");
        }
    }

    return (
        <div className="edit-category">
            <div className="edit-category-form">
                <h2>Edit Category</h2>
                <div className="form-item">
                    <label>
                        <p>Category Name</p>
                        <Input placeholder="Enter Category Name" 
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            />
                    </label>
                </div>
                <div className="form-item">
                    <label>
                        <p>Category Color</p>
                        <SketchPicker
                            color={color}
                            onChange={(c) => setColor(c.hex) }
                         />
                    </label>
                </div>
                <div className="form-item">
                    <Button type="primary"
                        loading={loading}
                        onClick={saveCategory}
                        style={{marginRight: 15}}
                    >
                        Save
                    </Button>
                    <Link to="/categories">
                        <Button>Back</Button>
                    </Link>
                </div>
            </div>
            <div className="edit-sub-types" style={{marginLeft: 15}}>
                {subTypes.map((subType, index) => {
                    if(subType.is_deleted !== undefined && subType.is_deleted)
                        return null;
                    return (
                        <div className="sub-type-item">
                            <Input placeholder="Enter Sub Type name"
                                value={subType.name}
                                onChange={(e) => updateSubTypeFields(e.target.value, index)} />
                            <Button danger shape="circle" className="delete-item"
                                icon={<CloseOutlined />} 
                                onClick={() => removeSubTypeField(index)}
                                size="small" />
                        </div>
                    )
                })}
                <Button type="primary" className="add-sub-type"
                    onClick={addSubTypeField}>Add Sub Type</Button>
            </div>
            <div className="edit-category-image">
                <Spin spinning={uploadLoading}>
                    <div className="category-image">
                        <img src={image} alt={name} />
                    </div>
                </Spin>
                <div>
                    <Upload {...uploaderProps}>
                        <Button type="primary">Upload</Button>
                    </Upload>
                </div>
            </div>
        </div>
    )
}

export default EditCategory;