import React, { useState, useContext, useEffect } from 'react';
import { GoogleApiWrapper, Map } from 'google-maps-react';
import { MapContext } from '~/Components/Context/MapContext';
import User from '~/Scripts/Client/User';

const GoogleMap = (props) => {

    const { center, zoom, onClick } = props;
    const [mapCenter] = useState(center !== undefined ?  center : { lat: 51.571960, lng: -0.777920 });
    const [mapZoom] = useState(zoom !== undefined ? zoom : 14);

    const { setMap, setGoogle, tiles, markers } = useContext(MapContext);

    const setGoogleMap = (p, m) => {
        setGoogle(p.google);
        setMap(m);

        if(props.mapReady !== null && props.mapReady !== undefined)
            props.mapReady(m);
    }

    return (
        <Map
            google={props.google}
            style={{width: '100%', height: '100%'}}
            initialCenter={mapCenter}
            zoom={mapZoom}
            onReady={setGoogleMap}
            onClick={onClick}
            clickableIcons={true}
            >
            {props.children}
        </Map>
    )
}

let libraries = [];
if(User.userCan('create_tile') || User.userCan('edit_tile'))
    libraries.push('drawing');

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    version: 3.41,
    libraries: libraries,
})(GoogleMap);