import BaseClient from './BaseClient';

class PlaceType extends BaseClient {
    getPlaceTypes() {
        return this.get('/placetype')
    }
    
    addType (data) {
        return this.post('/placetype', data, this.defaultHeaders())
    }

    updateType (id, data) {
        return this.put(`/placetype/${id}`, data, this.defaultHeaders())
    }

    deleteType(id) {
        return this.delete(`/placetype/${id}`, this.defaultHeaders())
    }

    getSubTypes(types) {
        return this.get('/type/get/subtypes?type_id=' + types, this.defaultHeaders());
    }
}

export default new PlaceType();