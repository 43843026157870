import React, { useState, useEffect } from 'react';
import { Skeleton, Input, message, Empty, Modal, Button, Table, Popconfirm, Select } from 'antd';
import User from '~/Scripts/Client/User';
import Role from '~/Scripts/Client/Role';
import LoadMoreButton from '~/Components/LoadMoreButton';
import CreateUser from './CreateUser';
import UpdateUser from './UpdateUser';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Option } = Select;

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [loadUsers, setLoadUsers] = useState(true);
    const [nextPageLink, setNextPageLink] = useState();
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
    const [userToEdit, setUserToEdit] = useState();

    const columns = [
        {
            title: "Avatar",
            dataIndex: "avatar",
            index: "avatar",
            width: 200,
            render: (text, user) => (
                <div className="user-item">
                    <div className="user-avatar">
                        <img src={user.avatar} alt={user.name} />
                    </div>
                </div>
                )
        },
        {
            title: "User Info",
            dataIndex: "avatar",
            index: "avatar",
            width: 200,
            render: (text, user) => (
                <div className="user-name">
                    <p><strong>{user.name}</strong></p>
                    <p>{user.email}</p>
                    <p>{user.role ? user.role.name : 'Member'}</p>
                </div>
            )
        },
        {
            title: "Action",
            dataIndex: "id",
            index: "id",
            render: (text, user, idx) => (
                <div>
                    { User.userCan("update_user") &&
                        <Button type="primary" 
                            size="small" 
                            style={{marginRight: 15}}
                            onClick={() => editUser(user)}
                            icon={<EditOutlined />}
                            disabled={loading}>Edit</Button> }
                        <Popconfirm title="Are you sure to delete this User?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => deleteUser(user, idx)}
                                >
                            { User.userCan("delete_user") &&
                            <Button danger size="small" disabled={loading}
                                icon={<DeleteOutlined />}>Delete</Button> }
                        </Popconfirm>
                </div>
            )
        }
    ];

    useEffect(() => {
        if(loadUsers) {
            getUsers(search, selectedRole);
        }
        Role.getRoles().then(
            (res) => {
                setRoles(res.data);
            }
        )
    }, [loadUsers]);

    const getUsers = (searchUser, role) => {
        setLoading(true);
        setUsers([]);
        User.getAllUsers(searchUser, role).then(
            (res) => {
                setLoading(false);
                setUsers(res.data.data);
                setLoadUsers(false);
                setNextPageLink(res.data.next_page_url);
            }
        ).catch(errorHandler)
    }

    const errorHandler = (error) => {
        console.log(error);
        if(error.response !== undefined)
            message.error(error.response.data.error_description);
        else
            message.error("Something went wrong, please try again later.");
            setLoading(false);
    }

    const setLoadedUsers = (res) => {
        setLoadUsers(false);
        setLoading(false);
        setLoadMoreLoading(false);
        setUsers(users.concat(res.data.data));
        setNextPageLink(res.data.next_page_url);
    }

    const deleteUser = (user, index) => {
        // setLoading(true);
        User.deleteUser(user.id).then(
            (res) => {
                if(res.data.success) {
                    onDeleteUser(user.id, index);
                    message.info("User successfully deleted.");
                } else {
                    message.error("An error occured. Please try again.");
                }

                // setLoading(false);
            },
            (error) => {
                message.error(error.data.error_description);
                // setLoading(false);
            }
        ).catch(
            (error) => {
                message.error("Something went wrong, please try again later.");
                console.log(error);
            }
        )
    }

    const roleFilterChanged = (val) => {
        setSelectedRole(val);
        getUsers(search, val);
    }

    const resetFilter = () => {
        setSelectedRole("");
        setSearch("");
        getUsers("", "");
    }

    const onDeleteUser = (userId, index) => {
        users.splice(index, 1);
        setUsers([...users]);
    }

    const userAdded = () => {
        setLoadUsers(true);
        setShowAddUserModal(false);
        setShowUpdateUserModal(false);
    }

    const editUser = (user) => {
        setUserToEdit(user);
        setShowUpdateUserModal(true);
    }

    const renderUserList = () => {
        if((!loading && users.length === 0))
            return <Empty 
                style={{marginTop: 100}}
                description="No Places Found" />;

        return (
            <Skeleton loading={loading}>
                <Table columns={columns} dataSource={users}
                    size="small" />
                <LoadMoreButton
                    url={nextPageLink}
                    onError={errorHandler}
                    loading={loadMoreLoading}
                    setLoading={setLoadMoreLoading}
                    onFinish={setLoadedUsers}
                />
            </Skeleton>
        );
    }

    return (
        <div className="user-list-container">
            { User.userCan("create_user") && <div style={{marginBottom: 15}}>
                <Button type="primary" onClick={() => setShowAddUserModal(true)}
                    style={{marginRight: 15}}>Add New User</Button>
                <Link to="/user/roles">
                    <Button type="primary">Manage Roles</Button>
                </Link>
            </div> }
            <div className="search-input">
                <Select style={{width: 150}} value={selectedRole} 
                    onChange={roleFilterChanged}>
                    <Option value="">- Filter By Role -</Option>
                    {roles.map((role) => {
                        return <Option value={role.id}>{role.name}</Option>
                    })}
                </Select>
                <Search value={search}
                    placeholder="Search User..."
                    onChange={(e) => setSearch(e.target.value)}
                    onSearch={() => getUsers(search, selectedRole)}
                    enterButton
                    style={{width: "75%"}}
                    />
                <Button type="primary" onClick={resetFilter}>Reset</Button>
                    
            </div>
            <div className="user-list">
                {renderUserList()}
            </div>
            <Modal

                title="Add New User"
                visible={showAddUserModal}
                okText="Save"
                cancelText="Cancel"
                // onOk={addCountry}
                onCancel={() => { setShowAddUserModal(false); }}
                maskClosable={false}
                footer={[]}
                // confirmLoading={saveLoading}
            >
                <div>
                    <CreateUser cancelSave={() => setShowAddUserModal(false)} userAdded={userAdded} />
                </div>
            </Modal>
            <Modal

                title="Update User"
                visible={showUpdateUserModal}
                okText="Save"
                cancelText="Cancel"
                // onOk={addCountry}
                onCancel={() => { setShowUpdateUserModal(false); }}
                maskClosable={false}
                footer={[]}
                // confirmLoading={saveLoading}
            >
                <div>
                    <UpdateUser cancelSave={() => setShowUpdateUserModal(false)} 
                        user={userToEdit}
                        userAdded={userAdded} />
                </div>
            </Modal>
        </div>
    )
}

export default UserList;