import React, { useState, useEffect, createRef, useContext } from 'react';
import {
  message, Skeleton, Input, Select, Modal,
  InputNumber, Button, Upload, Spin, Radio, Switch,
  Form, Tooltip
} from 'antd';
import Place from '~/Scripts/Client/Place';
import Image from '~/Scripts/Client/Image';
import PlaceType from '~/Scripts/Client/PlaceType';
import { Link } from 'react-router-dom';
import ImageCropper from '~/Components/Places/ImageCropper';
import ImageList from '~/Components/ImageGrabber/ImageList';
import Google from '~/Scripts/Client/Google';
import { GoogleApiWrapper } from 'google-maps-react';
import { PlacesContext } from '~/Components/Context/PlacesContext';
import PlaceTypes from '~/assets/placetypes';
import { InfoCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const AddNewPlace = ({ googlePlace, setModalVisible, google }) => {

  const { selectedTypes, setSelectedTypes,
    selectedGoogleTypes, setSelectedGoogleTypes } = useContext(PlacesContext);
  const [form] = Form.useForm();

  let data = {
    name: "",
    address: "",
    description: "",
    is_published: 1,
    latitude: "",
    longitude: "",
    rating: 0,
    type: [],
    sub_types: [],
    image: "https://iguides.net/cityguideapi/v1/storage//public/questionmark.png",
    fields: {
      phone: "",
      website: "",
      rating: "",
      google_place_id: "",
      google_reviews: "",
      google_map_url: "",
      opening_hours: ""
    }
  }

  const [loadTypes, setLoadTypes] = useState(true);
  const [loadSubTypes, setLoadSubTypes] = useState(selectedTypes != undefined && selectedTypes.length > 0);
  const [place, setPlace] = useState(googlePlace !== undefined ? googlePlace : data);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [googleTypes] = useState(PlaceTypes);
  const [selectedSubTypes, setSelectedSubTypes] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const [aspectRatio, setAspecRation] = useState(1.618 / 1);
  const [image, setImage] = useState(googlePlace !== undefined ? googlePlace.image : "https://iguides.net/cityguideapi/v1/storage//public/questionmark.png");
  const [base64Image, setBase64Image] = useState();
  const [showSelectImage, setShowSelectImage] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const imageRef = createRef("image");

  const [nameError, setNameError] = useState(false);

  const uploaderProps = {
    action: `${Place.baseUrl}/upload/image`,
    headers: Place.defaultHeaders().headers,
    multiple: false,
    name: "image",
    accept: ".jpg,.jpeg,.png",
    showUploadList: false,
    beforeUpload: () => setUploadLoading(true),
    onChange: (info) => uploadDone(info)
  }

  useEffect(() => {
    setSelectedGoogleTypes([]);
    setSelectedTypes([]);
    form.setFieldsValue({
      "type": []
    });
  }, []);

  useEffect(() => {
    if (loadTypes) {
      PlaceType.getPlaceTypes().then(
        (response) => {
          setLoadTypes(false);
          setTypes(response.data);
          let t = place.type.map((m) => {
            return m;
          });
          setSelectedTypes(t);

          if (place.image !== undefined && setModalVisible !== undefined)
            onSelectImage(place.image);
        }
      ).catch(errorHandler);
    }

    if (loadSubTypes && selectedTypes.length > 0) {
      PlaceType.getSubTypes(selectedTypes.join(",")).then(
        (res) => {
          setLoadSubTypes(false);
          setSubTypes(res.data);
          // let t = place.sub_types.map((m) => {
          //     return m.id;
          // });
          // setSelectedSubTypes(t);
        }
      ).catch(errorHandler);
    } else if (selectedTypes.length === 0) {
      setSelectedSubTypes([]);
      setSubTypes([]);
    }
  }, [loadTypes, place, loadSubTypes]);

  const errorHandler = (error) => {
    if (error.response !== undefined)
      message.error(error.response.data.error_description);
    else
      message.error("Something went wrong, please try again later.");
    setLoading(false);
    setUploadLoading(false);
    setSaveLoading(false)
  }

  const updatePlaceValue = (key, value) => {
    // console.log(value);
    place[key] = value;

    setPlace({ ...place });
    if (key === "type") {
      setSelectedTypes(value);
      setLoadSubTypes(true);
    }

    if (key === "sub_types")
      setSelectedSubTypes(value);

    if (key === "google_type")
      setSelectedGoogleTypes(value);

    if (key === "name" || key === "address") {
      setHasChanged(true);
    }
  }

  const savePlace = () => {
    setSaveLoading(true);
    setNameError(false);
    Place.addPlace(place).then(
      (res) => {
        setPlace({ ...res.data });
        // Place.updateImageByUrl(res.data.id, place.image).then(
        //     (res) => {
        //         message.success("Place successfully saved.");
        //         setSaveLoading(false);
        //         setModalVisible(false);
        //     }
        // );
        if (base64Image !== undefined) {
          Place.uploadBase64Image(res.data.id, base64Image).then(
            (res) => {
              message.success("Place successfully saved.");
              setSaveLoading(false);
              if (setModalVisible !== undefined)
                setModalVisible(false);
            }
          ).catch(errorHandler);
        } else {
          message.success("Place successfully saved.");
          setSaveLoading(false);
        }
      }
    ).catch(errorHandler);
  }

  const findLocation = () => {
    if (place.name.length === 0 || place.address.length === 0 || !hasChanged)
      return false;

    let address = `${place.name},${place.address}`;
    Google.findLocation(google, address, (res) => {
      let result = res.data.result;
      place.latitude = result.geometry.location.lat;
      place.longitude = result.geometry.location.lng;
      place.address = result.formatted_address;
      place.fields = {
        phone: result.international_phone_number,
        website: result.website,
        rating: result.rating,
        google_place_id: result.place_id,
        google_reviews: result.reviews,
        google_map_url: result.url,
        opening_hours: (result.opening_hours != null) ? result.opening_hours : null
      }

      let types = Google.getPlaceCategory(result.types);
      place.type = types;
      setSelectedTypes(types);
      form.setFieldsValue({
        "address": result.formatted_address,
        "type": types
      });

      if (result.address_components != undefined) {
        result.address_components.forEach((address) => {
          if (address.types.includes("route")) {
            place.fields.route = address.long_name;
          }

          if (address.types.includes("locality")) {
            place.fields.city = address.long_name;
            form.setFieldsValue({
              "city": address.long_name
            });
          }

          if (address.types.includes("country"))
            place.fields.country = address.long_name;
        });
      }

      let gTypes = result.types.filter((t) => {
        let exists = PlaceTypes.find((pt) => {
          return pt.type === t;
        });

        return exists !== null && exists !== undefined;
      });
      setSelectedGoogleTypes(gTypes);
      place.google_type = gTypes;

      place.image = Google.getPlaceImage(result);
      if (showCropper)
        setShowCropper(false);

      onSelectImage(place.image);

      setPlace({ ...place });
      setHasChanged(false);
      setLoadSubTypes(true);
    });
  }

  const onCropImage = (image) => {
    // console.log("cropping...");
    setUploadLoading(false);
    setBase64Image(image);
  }

  const uploadDone = (info) => {

    if (info.file.status === 'done') {
      setImage(info.file.response.url);
      setShowCropper(true);
    } else if (info.file.status === 'error') {
      setUploadLoading(false);
      message.error("error uploading image, please try again");
    }
  }

  const cancelCrop = () => {
    setShowCropper(false);
    setImage(place.image);
  }

  const saveImage = () => {
    setImage(base64Image);
    setShowCropper(false);
    // setUploadLoading(true);
    // Place.uploadBase64Image(place.id, base64Image).then(
    //     (res) => {
    //         setShowCropper(false);
    //         setUploadLoading(false);
    //         setImage(res.data.image);
    //     }
    // ).catch(errorHandler);
  }

  const onSelectImage = (url) => {
    setUploadLoading(true);
    setShowSelectImage(false);
    Image.uploadUrl(url).then(
      (res) => {
        setImage(res.data.url);
        // setUploadLoading(false);
        setShowCropper(true);
      }
    ).catch(errorHandler);
  }

  const renderImageButtons = () => {
    if (!showCropper) {
      return (
        <React.Fragment>
          <Upload {...uploaderProps}>
            <Button type="primary" disabled={loading}>Upload</Button>
          </Upload>
          <Button type="primary" onClick={() => setShowSelectImage(true)} disabled={loading}>Select</Button>
          <Tooltip title="'Upload' button is used for uploading images in your local machine. 'Select' button to use images saved through Image Grabber.">
            <Button type="link" icon={<InfoCircleOutlined />} size="medium" />
          </Tooltip>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Radio.Group defaultValue="Landscape" buttonStyle="solid">
            <Radio.Button value="Landscape" onClick={() => setAspecRation(1.618 / 1)}>Landscape</Radio.Button>
            <Radio.Button value="Portrait" onClick={() => setAspecRation(1 / 1.618)}>Portrait</Radio.Button>
            <Radio.Button value="Square" onClick={() => setAspecRation(1 / 1)}>Square</Radio.Button>
            <Radio.Button value="Free Hand" onClick={() => setAspecRation(0)}>Free Hand</Radio.Button>
          </Radio.Group>
          <Button onClick={cancelCrop}>Cancel</Button>
          <Button type="primary" onClick={saveImage}>Crop</Button>
        </React.Fragment>
      )
    }
  }

  const renderBack = () => {
    if (setModalVisible !== undefined) {
      return (
        <Button onClick={() => setModalVisible(false)}>Cancel</Button>
      )
    } else {
      return (
        <Link to="/places" style={{ marginRight: 10 }}>
          <Button>Back</Button>
        </Link>
      )
    }
  }

  const renderCropper = () => {
    if (!showCropper) {
      return <img src={image} alt={place.name}
        id="place-image" ref={imageRef} />

    } else {
      return (
        <div className={!showCropper ? "hidden-cropper" : "cropper"}>
          <ImageCropper showCropper={showCropper} onCrop={onCropImage}
            aspectRatio={aspectRatio}
            imageRef={imageRef}>
            <img src={image} alt={place.name}
              id="place-image" ref={imageRef} crossOrigin="Anonymous" />
          </ImageCropper>
        </div>
      );
    }
  }

  const renderHotelAttributes = () => {
    if (selectedTypes.includes("accommodation")) {
      return (
        <React.Fragment>
          <h2>
            Hotel Amenities
            <Tooltip title="Add Hotel Amenities that is available in your hotel.">
              <Button type="link" icon={<InfoCircleOutlined />} size="medium" />
            </Tooltip>
          </h2>
          <div className="hotel-info">
            <div>
              <div className="form-item">
                <label>
                  <p>Free Parking</p>
                  <Switch checked={place.fields.free_parking}
                    onChange={(check) => {
                      place.fields.free_parking = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Free Wifi</p>
                  <Switch checked={place.fields.free_wifi}
                    onChange={(check) => {
                      place.fields.free_wifi = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Free Breakfast</p>
                  <Switch checked={place.fields.free_breakfast}
                    onChange={(check) => {
                      place.fields.free_breakfast = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Free Cancellation</p>
                  <Switch checked={place.fields.free_cancellation}
                    onChange={(check) => {
                      place.fields.free_cancellation = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
            </div>
            <div>
              <div className="form-item">
                <label>
                  <p>Pet Friendly</p>
                  <Switch checked={place.fields.pet_friendly}
                    onChange={(check) => {
                      place.fields.pet_friendly = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Kids Friendly</p>
                  <Switch checked={place.fields.kids_friendly}
                    onChange={(check) => {
                      place.fields.kids_friendly = check ? 1 : 0;
                      updatePlaceValue("fields", place.fields);
                    }} />
                </label>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  if (loading)
    return <Skeleton />

  return (
    <div className="edit-place">
      <h2 style={{ width: "100%" }}>
        Add New Place
        <Tooltip title="Input Place name and address first then the system will try to auto-fill some of the fields. If the fields are not auto-fill, try to check the spelling and the adress if it is right.">
          <Button type="link" icon={<InfoCircleOutlined />} size="medium" />
        </Tooltip>
      </h2>
      <Form onFinish={savePlace} form={form}>
        <div className="edit-place-container">
          {/* <div className="edit-place-image">
            <div className="image">
              <Spin spinning={uploadLoading}>
                {renderCropper()}
              </Spin>
              {renderImageButtons()}
            </div>
            <Modal
              visible={showSelectImage}
              title="Select Image"
              footer={[]}
              onCancel={() => setShowSelectImage(false)}
              width={"70%"}
            >
              <div className="modal-image-list">
                <ImageList onSelectImage={onSelectImage} />
              </div>
            </Modal>
          </div> */}
          <div className="edit-place-details">
            <div className="edit-place-form">
              <div className="form-item">
                <label>
                  <p>Place Name</p>
                  <Form.Item name={['name']}
                    rules={[{ required: true, message: "Please enter place name" }]}
                    initialValue={place.name}>
                    <Input placeholder="Enter Place Name"
                      value={place.name}
                      onBlur={findLocation}
                      onChange={(e) => updatePlaceValue("name", e.target.value)}
                    />
                  </Form.Item>
                  {place.fields.google_map_url && <a href={place.fields.google_map_url} target="_blank" title="View in Google Map">
                    <Button icon={<ArrowRightOutlined />}
                      style={{ marginLeft: 3 }} />
                  </a>}
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Address</p>
                  <Form.Item name={['address']}
                    rules={[{ required: true, message: "Please enter place address" }]}
                    initialValue={place.address}>
                    <TextArea placeholder="Enter Address"
                      value={place.address}
                      rows={2}
                      onBlur={findLocation}
                      onChange={(e) => updatePlaceValue("address", e.target.value)}
                    />
                  </Form.Item>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Google Category</p>
                  <Select
                    mode="multiple"
                    optionLabelProp="label"
                    placeholder="Select Google Category"
                    style={{ width: '60%' }}
                    onChange={(value) => updatePlaceValue("google_type", value)}
                    value={selectedGoogleTypes}
                  >
                    {googleTypes.map((m) => {
                      return <Option value={m.type}
                        key={m.type}
                        label={m.label}>{m.label}</Option>
                    })}
                  </Select>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Category</p>
                  <Form.Item name={['type']}
                    rules={[{ required: true, message: "Please select category" }]}
                    initialValue={selectedTypes}>
                    <Select
                      mode="multiple"
                      optionLabelProp="label"
                      placeholder="Select Category"
                      style={{ width: '60%' }}
                      onChange={(value) => updatePlaceValue("type", value)}
                      value={selectedTypes}
                    >
                      {types.map((m) => {
                        return <Option value={m.type}
                          key={m.id}
                          label={m.name}>{m.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Sub Category</p>
                  <Select
                    mode="multiple"
                    optionLabelProp="label"
                    placeholder="Select Sub-Category"
                    style={{ width: '60%' }}
                    onChange={(value) => updatePlaceValue("sub_types", value)}
                    value={selectedSubTypes}
                  >
                    {subTypes.map((m) => {
                      return <Option value={m.id}
                        key={m.id}
                        label={m.name}>{m.name}</Option>
                    })}
                  </Select>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Description</p>
                  <TextArea placeholder="Enter Description"
                    value={place.description}
                    rows={3}
                    onChange={(e) => updatePlaceValue("description", e.target.value)}
                  />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Price</p>
                  <Select value={place.fields.price} style={{width: "60%"}}
                    onChange={(value) => {
                      place.fields.price = value;
                      updatePlaceValue("fields", place.fields);
                    }}>
                    <Option value={0}>Free</Option>
                    <Option value={1}>$</Option>
                    <Option value={2}>$$</Option>
                    <Option value={3}>$$$</Option>
                  </Select>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Website</p>
                  <Input placeholder="Enter Website"
                    value={place.fields.website}
                    onChange={(e) => {
                      place.fields.website = e.target.value;
                      updatePlaceValue("fields", place.fields);
                    }}
                  />
                  <a href={place.fields.website} target="_blank" title="Visit Website">
                    <Button icon={<ArrowRightOutlined />}
                      style={{ marginTop: 3, marginLeft: 3 }} />
                  </a>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Phone</p>
                  <Input placeholder="Enter Phone"
                    value={place.fields.phone}
                    onChange={(e) => {
                      place.fields.phone = e.target.value;
                      updatePlaceValue("fields", place.fields);
                    }}
                  />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Area</p>
                  <Input placeholder="Enter Area"
                    value={place.fields.area}
                    onChange={(e) => {
                      place.fields.area = e.target.value;
                      updatePlaceValue("fields", place.fields);
                    }}
                  />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Town/Disctrict</p>
                  <Input placeholder="Enter Town/District"
                    value={place.fields.route}
                    onChange={(e) => {
                      place.fields.route = e.target.value;
                      updatePlaceValue("fields", place.fields);
                    }}
                  />
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>City</p>
                  <Form.Item name={['city']}
                    rules={[{ required: true, message: "Please enter city" }]}
                    initialValue={place.fields.city}>
                    <Input placeholder="Enter City"
                      value={place.fields.city}
                      onChange={(e) => {
                        place.fields.city = e.target.value;
                        updatePlaceValue("fields", place.fields);
                      }}
                    />
                  </Form.Item>
                </label>
              </div>
              <div className="form-item">
                <label>
                  <p>Country</p>
                  <Input placeholder="Enter Country"
                    value={place.fields.country}
                    onChange={(e) => {
                      place.fields.country = e.target.value;
                      updatePlaceValue("fields", place.fields);
                    }}
                  />
                </label>
              </div>
              {renderHotelAttributes()}
              <div className="form-item" style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "30%", marginRight: 15 }}></div>
                <div style={{
                  display: "flex", flexDirection: "row",
                  justifyContent: "flex-start"
                }}>
                  {renderBack()}
                  <Button type="primary" htmlType="submit" loading={saveLoading}
                    
                  >Save</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
})(AddNewPlace);