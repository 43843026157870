import BaseClient from './BaseClient';
import axios from 'axios';

class Auth extends BaseClient {

    login = (username, password, grantType) => {
        let data = {
            username: username,
            password: password,
            grant_type: grantType,
            client_id: process.env.REACT_APP_PASSPORT_CLIENT_ID,
            client_secret: process.env.REACT_APP_PASSPORT_CLIENT_SECRET
        }

        return axios.post(process.env.REACT_APP_API_AUTH_URL, data);
    }

}

export default new Auth();