import React, { createContext, useState } from 'react';

export const PlacesContext = createContext();

export const PlacesProvider = (props) => {
    const [places, setPlaces] = useState([]);
    const [placesData, setPlacesData] = useState();
    const [viewImage, setViewerImage] = useState();
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [loadPlaces, setLoadPlaces] = useState(true);
    const [loadingPlaces, setLoadingPlaces] = useState(true);
    const [position, setPosition] = useState(0);
    const [nextPageLink, setNextPageLink] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedGoogleTypes, setSelectedGoogleTypes] = useState([]);
    const [csvPlaces, setCsvPlaces] = useState([]);
    const [currentType, setCurrentType] = useState("");
    const [currentSubType, setCurrentSubType] = useState("");
    const [currentGoogleType, setCurrentGoogleType] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [search, setSearch] = useState("");
    const [city, setCity] = useState("");
    const [area, setArea] = useState("");
    const [town, setTown] = useState("");
    const [filter, setFilter] = useState("");
    const [subTypes, setSubTypes] = useState([]);
    const [selectCountryId, setSelectedCountryId] = useState();
    const [hiddenOnly, setHiddenOnly] = useState(false);
    const [shownOnly, setShownOnly] = useState(false);
    const [price, setPrice] = useState("");

    const state = {
        places: places,
        placesData: placesData,
        csvPlaces: csvPlaces,
        viewImage: viewImage,
        showImageViewer: showImageViewer,
        loadPlaces: loadPlaces,
        loadingPlaces: loadingPlaces,
        position: position,
        nextPageLink: nextPageLink,
        selectedTypes: selectedTypes,
        selectedGoogleTypes: selectedGoogleTypes,
        selectedCountry: selectedCountry,
        totalCount: totalCount,
        currentType: currentType,
        currentSubType: currentSubType,
        currentGoogleType: currentGoogleType,
        search: search,
        city: city,
        area: area,
        town: town,
        filter: filter,
        subTypes: subTypes,
        selectCountryId: selectCountryId,
        hiddenOnly: hiddenOnly,
        shownOnly: shownOnly,
        price: price,
        setPlaces: setPlaces,
        setPlacesData: setPlacesData,
        setCsvPlaces: setCsvPlaces,
        setViewerImage: setViewerImage,
        setShowImageViewer: setShowImageViewer,
        setLoadPlaces: setLoadPlaces,
        setLoadingPlaces: setLoadingPlaces,
        setPosition: setPosition,
        setNextPageLink: setNextPageLink,
        setSelectedTypes: setSelectedTypes,
        setSelectedGoogleTypes: setSelectedGoogleTypes,
        setSelectedCountry: setSelectedCountry,
        setTotalCount: setTotalCount,
        setCurrentType: setCurrentType,
        setCurrentSubType: setCurrentSubType,
        setCurrentGoogleType: setCurrentGoogleType,
        setSearch: setSearch,
        setCity: setCity,
        setArea: setArea,
        setTown, setTown,
        setFilter: setFilter,
        setSubTypes: setSubTypes,
        setSelectedCountryId: setSelectedCountryId,
        setHiddenOnly: setHiddenOnly,
        setShownOnly: setShownOnly,
        setPrice: setPrice
    };
    return (
        <PlacesContext.Provider value={state}>{props.children}</PlacesContext.Provider>
    );
}