import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import CSV from '~/Components/Places/UploadCSV';

class UploadCSV extends Component {
    static contextType = AppContext;

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        this.context.setCurrentTab("places");
    }

    render() {

        return (
            <React.Fragment>
                <h1>Upload Places via CSV</h1>
                <CSV />
            </React.Fragment>
        );
    }
}

// Places.propTypes = {

// };

export default UploadCSV;