import BaseClient from "./BaseClient";

class Role extends BaseClient {
    getRoles = () => {
        return this.get(`/role`, this.defaultHeaders());
    }

    createRole = ({name, permissions}) => {
        return this.post(`/role`, {name, permissions}, this.defaultHeaders());
    }

    updateRole = (roleId, {name, permissions}) => {
        return this.put(`/role/${roleId}`, {name, permissions}, this.defaultHeaders());
    }

    deleteRole = (roleId) => {
        return this.delete(`/role/${roleId}`, this.defaultHeaders());
    }

    getPermissions = () => {
        return this.get(`/permissions`, this.defaultHeaders());
    }
}

export default new Role();