import React, { useContext, useEffect } from 'react';
import { Marker } from 'google-maps-react';
import GoogleMap from '~/Components/Maps/Map';
import { MapContext } from '~/Components/Context/MapContext';

const PlaceLocation = ({ place, updatePlace }) => {

    const { map, google } = useContext(MapContext);

    useEffect(() => {
        if(place !== null && place !== undefined && map !== null && map !== undefined)
            map.setCenter({lat: place.latitude, lng: place.longitude});
    }, [place, map]);

    const setMarkerDragEvent = (props, marker) => {
        google.maps.event.addListener(marker, "dragend", function (event) {
            updatePlace({...place, latitude: event.latLng.lat(), longitude: event.latLng.lng()});
        });
    }

    return (
        <div className="place-map-location">
            <GoogleMap google={google} 
                center={{lat: place.latitude, lng: place.longitude}}
                zoom={17}
                >
                <Marker
                    position={{lat: place.latitude, lng: place.longitude}}
                    draggable={true}
                    name={place.name}
                    icon={{url: require("~/assets/images/marker.png")}}
                    onMouseover={setMarkerDragEvent}
                    
                />
            </GoogleMap>
        </div>
    );
}

export default PlaceLocation;
