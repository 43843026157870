import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import { PlacesProvider } from '~/Components/Context/PlacesContext';
import PlaceList from '~/Components/Places/PlaceList';

class Places extends Component {
    static contextType = AppContext;

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        this.context.setCurrentTab("places");
    }

    render() {

        return (
            <React.Fragment>
                <PlaceList />
            </React.Fragment>
        );
    }
}

// Places.propTypes = {

// };

export default Places;