import BaseClient from './BaseClient';

class City extends BaseClient {

    getCities =  (countryId) => {
        let url = `/city`;
        if(countryId)
            url += `?country_id=${countryId}`;
        return this.get(url, this.defaultHeaders());
    }

    addCity = (data) => {
        return this.post("/city", data, this.defaultHeaders());
    }

    updateCity = (id, data) => {
        return this.put(`/city/${id}`, data, this.defaultHeaders());
    }

    deleteCity = (id) => {
        return this.delete(`/city/${id}`, this.defaultHeaders());
    }

    getCountries = (search) => {
        let url = `/country`;
        if(search)
            url += `?search=${search}`;
        return this.get(url, this.defaultHeaders());
    }

    addCountry = (name) => {
        return this.post(`/country`, {name}, this.defaultHeaders());
    }

    upateCountry = (id, name) => {
        return this.put(`/country/${id}`, {name}, this.defaultHeaders());
    }

    deleteCountry = (id) => {
        return this.delete(`/country/${id}`, this.defaultHeaders());
    }

    getCityAreas = (city) => {
        return this.get(`/get/city/areas?city=${city}`);
    }

    getCityTowns = (city) => {
        return this.get(`/get/city/towns?city=${city}`);
    }

    saveTown = (cityId, { name }) => {
        return this.post(`/city/${cityId}/town`, { name: name, city_id: cityId }, this.defaultHeaders());
    }

    updateTown = (cityId, townId, { name }) => {
        return this.put(`/city/${cityId}/town/${townId}`, { name: name, city_id: cityId }, this.defaultHeaders());
    }

    deleteTown = (cityId, townId) => {
        return this.delete(`/city/${cityId}/town/${townId}`, this.defaultHeaders());
    }

}

export default new City();