import React, { useState, useContext } from 'react';
import { Input, Button, Checkbox, message } from 'antd';
import Auth from '~/Scripts/Client/Auth';
import { Redirect } from 'react-router-dom';
import User from '~/Scripts/Client/User';
import { AppContext } from '~/Components/Context/AppContext';

const LoginForm = () => {
    const [username, setUsername] = useState(Auth.getRememberMe());
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [remember, setRemember] = useState(true);
    // const [isLogin, setIsLogin] = useState(Auth.isLogin());
    const { isLoggedIn, setIsLoggedIn, setLoggedUser } = useContext(AppContext);

    const login = () => {
        
        if(username === null || username.length <= 0) {
            message.error("Please enter username");
            return false;
        }
        if(password === null || password.length <= 0) {
            message.error("Please enter password");
            return false;
        }
        setLoading(true);
        Auth.login(username, password, "password").then(
            (res) => {
                Auth.setToken(res.data.access_token);
                if(remember)
                    Auth.setRememberMe(username);
                User.getCurrentUser().then(
                    (user) => {
                        setLoading(false);
                        Auth.setCurrentUser(user.data);
                        setLoggedUser(user.data);
                        // message.success("Login");
                        // setIsLogin(true);
                        setIsLoggedIn(true);
                    }
                ).catch(errorHandler)
            }
        ).catch(errorHandler)
    }

    const errorHandler = (error) => {
        console.log(error);
        if(error.response !== undefined)
            message.error(error.response.data.message);
        else
            message.error("Something went wrong, please try again later.");
        setLoading(false);
    }

    const usernameChange = (e) => {
        setUsername(e.target.value);
    }

    const passwordChange = (e) => {
        setPassword(e.target.value);
    }

    const checkKeyborPress = (e) => {
        if(e.keyCode === 13)
            login();
    }

    // if user is login, redirect to dashboard
    if(isLoggedIn)
        return <Redirect push to="/" />

    return (
        <div className="loginForm">
            <div>
                <div className="loginHeader">
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <span className="logo"></span>
                        <p className="name">
                            <span>City iGuide</span> Login
                        </p>
                    </div>
                    
                    <p style={{flex: 1, fontSize: 16}}>Enter your <strong>Username</strong> and <strong>Password</strong> to sign in!</p>
                </div>
                <div className="loginInput">
                    <Input value={username} size="large" placeholder="Enter Username" 
                        style={{marginBottom: 10}} onChange={usernameChange} onKeyDown={checkKeyborPress}/>
                    <Input.Password value={password} size="large" placeholder="Enter Password" 
                        onChange={passwordChange} onKeyDown={checkKeyborPress} />
                </div>
                <div className="loginButton">
                    <Checkbox checked={remember} value={remember} onChange={() => setRemember(!remember)}>Remember me</Checkbox>
                    <Button type="primary" style={{width: '100%', marginTop: '20px'}} 
                        onClick={login}
                        size="large" loading={loading}>
                        Sign me In</Button>
                </div>
            </div>
        </div>
    )
}

export default LoginForm;