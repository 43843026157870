import React from 'react';
import { Table, Button, Popconfirm } from 'antd';
import Moment from 'react-moment';
import { DeleteOutlined, RollbackOutlined } from '@ant-design/icons';
import User from '~/Scripts/Client/User';

const DeletedPlaceTable = ({ places, data, restorePlace, deletePlace, onPageChange, onPageSizeChange }) => {
    const columns = [
        {
            title: "Place Image",
            dataIndex: "image",
            index: "image",
            render: (text, place) => (
                <div className="place-image" style={{width: 100, height: 70, overflow: "hidden"}}>
                    <img style={{width: "100%"}} src={text} alt={place.name} />
                </div>
            )
        },
        {
            title: "Place Name",
            dataIndex: "name",
            index: "name",
            render: (text, place) => (
                <div style={{maxWidth: 200}}>
                    <strong><p>{text}</p></strong>
                    <p>{place.address}</p>
                </div>
            )
        },
        {
            title: "Date Deleted",
            dataIndex: "deleted_at",
            index: "deleted_at",
            render: (text) => (
                <div>
                    <p><Moment format="MMMM Do YYYY">{text}</Moment></p>
                    <p><Moment format="h:mm:ss A">{text}</Moment></p>
                </div>
            )
        },
        {
            title: "Deleted By",
            dataIndex: "user_deleted",
            index: "user_deleted",
            render: (user) => (
                <div style={{textAlign: "left"}}>
                    {user && <div>
                        <img src={user.avatar} style={{width: 75}} />
                        <p>{user.name}</p>
                    </div>}
                </div>
                
            )
        },
        {
            title: "Action",
            dataIndex: "deleted_at",
            index: "deleted_at",
            render: (text, place, index) => (
                <div>
                    <Popconfirm title={`Are you sure to restore ${place.name}?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => restorePlace(place, index)}
                            >
                        <Button type="primary" size="small"
                        icon={<RollbackOutlined />} style={{marginRight: 10}}>Restore</Button>
                    </Popconfirm>
                    
                    {User.userCan('view_deleted_place') && <Popconfirm title={`Are you sure to permanently delete ${place.name}?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deletePlace(place, index)}
                            >
                        <Button danger size="small"
                            icon={<DeleteOutlined />}>Delete</Button>
                    </Popconfirm>}
                </div>
            )
        }
    ];

    const paginator = {
        current: data.current_page,
        pageSize: data.per_page,
        total: data.total,
        onChange: onPageChange,
        onShowSizeChange: onPageSizeChange,
        showTotal: ( total => `${total} items`)
    }

    return (
        <div>
            <Table columns={columns} dataSource={places} 
                pagination={paginator}
                size="small" />
        </div>
    );
}

export default DeletedPlaceTable;