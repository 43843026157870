import { Empty } from 'antd';
import BaseClient from './BaseClient';

class User extends BaseClient {

    getCurrentUser = () => {
        return this.get("/me", this.defaultHeaders());
    }

    getAllUsers = (search, roleId) => {
        let url = `/user?search=${search}&role_id=${roleId}`;
            
        return this.get(url, this.defaultHeaders());
    }

    createUser = (data) => {
        return this.post(`/user`, data, this.defaultHeaders());
    }

    updateUser = (userId, data) => {
        return this.put(`/user/${userId}`, data, this.defaultHeaders());
    }

    changeAvatar = (userId, {image}) => {
        return this.post(`/user/${userId}/changeavatar/base64`, {image}, this.defaultHeaders());
    }

    deleteUser = (userId) => {
        return this.delete(`/user/${userId}`, this.defaultHeaders());
    }

    userCan = (slug) => {
        let user = JSON.parse(localStorage.getItem("CityGuide.CurrentUser"));
        if(!user)
            return false;

        if(!user.role_id)
            return false;

        let permission = user.role.permissions.find((perm) => {
            return perm.slug === slug;
        });

        return permission !== undefined && permission !== null;
    }

}

export default new User();