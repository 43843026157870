import React, { useState } from 'react';
import { Marker, Polygon, Polyline } from 'google-maps-react';
import Map from '~/Components/Maps/Map';
import Google from '~/Scripts/Client/Google';
import Direction from '~/Scripts/Utils/Direction';
import MapTile from '~/Scripts/Client/MapTile';
import { message, Spin, Button } from 'antd';
import CityBoundary from '~/Scripts/Client/CityBoundary';
import { Link } from 'react-router-dom';

const PlaceMap = ({ google, origin, places, setPlaces, place }) => {

    const [loading, setLoading] = useState(false);
    const [route, setRoute] = useState();
    const [tiles, setTiles] = useState([]);
    const [cityBounds, setCityBounds] = useState([]);

    const loadTiles = (bounds, currentTiles) => {
        MapTile.getTiles(bounds).then(
            (response) => {
                setTiles(response.data.data);
            }
        );
    }

    const loadBoundaries = (bounds) => {
        CityBoundary.getBoundaries(bounds).then(
            (res) => {
                setCityBounds(res.data.data);
            }
        )
    }

    const onMapLoad = (m) => {
        // set the destination on the first load of the map
        if(m) {
            // setMap(m);
            let dest = new google.maps.LatLng(place.latitude, place.longitude);
            let orig = new google.maps.LatLng(origin.lat(), origin.lng());
            let bounds = new google.maps.LatLngBounds();
            bounds.extend(orig);
            bounds.extend(dest);
            m.fitBounds(bounds);

            google.maps.event.addListener(m, "idle", () => {
                let mapBounds = m.getBounds()
                let NE = mapBounds.getNorthEast()
                let SW = mapBounds.getSouthWest()
                let boundText = NE.lat() + ' ' + SW.lng() + ', ' + NE.lat() + ' ' + NE.lng() + ', ' +
                SW.lat() + ' ' + NE.lng() + ', ' + SW.lat() + ' ' + SW.lng() + ', ' + NE.lat() + ' ' + SW.lng();
                loadTiles(boundText, tiles);
                loadBoundaries(boundText);
            });

            setLoading(true);
            Google.direction(`${origin.lat()},${origin.lng()}`,
            `${place.latitude},${place.longitude}`,"DRIVING").then(
                (res) => {
                    if(res.data.status === "OK") {
                        let legs = res.data.routes[0].legs;
                        setRoute(legs[0]);
                        places.data.forEach((p) => {
                            if(p.id === place.id) {
                                p.travel_time = legs[0].duration.text;
                            }
                        })
                
                        setPlaces({...places});

                        res.data.routes.map((route) => {
                            let destination = {lat: place.latitude, lng: place.longitude};
                            Direction.addPolyLine(google, m, route.overview_polyline.points, '#99211d', origin, destination);
                        });
                        setLoading(false);
                    }
                },
                (error) => {
                    setLoading(false);
                    message.error("Failed to calculate travel time.");
                }
            )
        }
    }

    return (
        <div style={{height: 300, display: "flex", flexDirection: "row"}}>
            <Spin spinning={loading}>
                <div style={{width: 500, height: 300, display: "flex", 
                    overflow: "hidden", position: "relative"}}>
                    { origin && 
                        <Map google={google} 
                            center={{lat: origin.lat(), lng: origin.lng()}}
                            mapReady={onMapLoad}
                            zoom={16}>
                                {tiles.map((t) => {
                                    return <Polygon
                                        key={t.id}
                                        paths={t.path}
                                        strokeColor="#FF0000"
                                        fillColor="#FF0000"
                                        strokeOpacity={0.5}
                                        strokeWeight={1}
                                        fillOpacity={0.2}
                                    />
                                })}
                                {cityBounds.map((t) => {
                                    return <Polyline
                                        key={t.id}
                                        path={t.path}
                                        strokeColor="#0D55FA"
                                        strokeOpacity={0.5}
                                        strokeWeight={2}
                                    />
                                })}
                                <Marker
                                position={{lat: origin.lat(), lng: origin.lng()}}
                                name="Starting point"
                                title="Starting point"
                                icon={{url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=A|7F8C8D|FFFFFF'}}
                            />
                            <Marker
                                position={{lat: place.latitude, lng: place.longitude}}
                                name={place.name}
                                title={place.name}
                                icon={{url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=B|7F8C8D|FFFFFF'}}
                            />
                        </Map>}
                </div>
            </Spin>
            {route && <div style={{paddingLeft: 15}}>
                <h3>Details</h3>
                <p style={{marginBottom: 2}}>Distance: <strong>{route.distance.text}</strong></p>
                <p style={{marginBottom: 2}}>Travel time: <strong>{route.duration.text}</strong></p>
                <p style={{marginBottom: 2}}>From: : <strong>{route.start_address}</strong></p>
                <p style={{marginBottom: 2}}>To: : <strong>{route.end_address}</strong></p>
                <Link to={`/place/edit/${place.id}`}>
                    <Button type="primary">Edit</Button>
                </Link>
            </div> }
        </div>
    )
}

export default PlaceMap;