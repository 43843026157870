import React from 'react';
import StarRating from 'react-star-ratings';
import Constants from '~/Scripts/Utils/Constants';
import { Table, Button } from 'antd';
import { Link } from 'react-router-dom';

const ByCarTable = ({ data, onPageChange, onPageSizeChange, getPlaceTravelTime, showMapClick }) => {
    const paginator = {
        current: data.current_page,
        pageSize: data.per_page,
        total: data.total,
        onChange: onPageChange,
        onShowSizeChange: onPageSizeChange,
        showTotal: ( total => `${total} items`)
    }

    const columns = [
        {
            title: "Place Image",
            dataIndex: "image",
            index: "image",
            render: (text, place) => (
                <div className="place-image" style={{width: 100, height: 70, overflow: "hidden"}}>
                    <Link to={`/place/edit/${place.id}`}>
                        <img style={{width: "100%"}} src={text} alt={place.name} />
                    </Link>
                </div>
            )
        },
        {
            title: "Place Info",
            dataIndex: "name",
            index: "name",
            render: (text, place) => (
                <div>
                    <StarRating 
                        rating={(place.fields.rating !== null && place.fields.rating !== undefined) ? parseFloat(place.fields.rating) : 0}
                        numberOfStars={5}
                        name={place.name}
                        starDimension="10px"
                        starSpacing="1px"
                        starRatedColor={Constants.primaryColor}
                    />
                    <Link to={`/place/edit/${place.id}`}>
                        <strong><p style={{marginBottom: 0}}>{place.name}</p></strong>
                    </Link>
                    <p style={{marginBottom: 0}}>{place.address}</p>
                </div>
            )
        },
        {
            title: "Categories",
            dataIndex: "Categories",
            index: "types",
            render: (index, place) => (
                <div>
                    {place.types.map((type) => {
                        return <p style={{marginBottom: 2}}>{type.name}</p>
                    })}
                </div>
            )
        },
        {
            title: "Distance",
            dataIndex: "distance",
            index: "distance",
            render: (text, place) => (
                <div className="place-distance">
                    <p style={{marginBottom: 0}}>{parseFloat(place.distance).toFixed(2)} km</p>
                    {place.travel_time && 
                        <p style={{marginBottom: 0}}>{place.travel_time}</p>}
                    {!place.travel_time && 
                        <Button type="primary" size="small" 
                            onClick={() => getPlaceTravelTime(place)} 
                            loading={place.loading}>Travel Time</Button>}
                    <Button type="primary" onClick={() => showMapClick(place)} 
                        size="small" style={{display: "block", marginTop: 5}}>Show Map</Button>
                </div>
            )
        },
    ];

    return (
        <div>
            <Table columns={columns} dataSource={data.data} 
                pagination={paginator}
                size="small" />
        </div>
    );
}

export default ByCarTable;