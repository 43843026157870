import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import AddRoomType from '~/Components/HotelRoomTypes/AddRoomType';

class HotelRoomTypeAddNew extends Component {
    static contextType = AppContext;

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        this.context.setCurrentTab("places");
    }

    render() {

        return (
            <React.Fragment>
                <h1>Add Room Type</h1>
                <AddRoomType match={this.props.match} />
            </React.Fragment>
        );
    }
}

// Places.propTypes = {

// };

export default HotelRoomTypeAddNew;