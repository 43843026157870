class Window {
    container = null;

    getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

    onWindowSizeChange = (handler) => {
        window.addEventListener('resize', handler);
    }

    onWindowScroll = (handler) => {
        this.container = window.document.getElementById("main-container");
        if(this.container !== null && this.container !== undefined)
            this.container.addEventListener('scroll', handler);
    }

    removeWindowScroll = (handler) => {
        // container = window.document.getElementById("main-container");
        if(this.container !== null && this.container !== undefined)
            this.container.removeEventListener('scroll', handler);
    }

    scrollElement = (position) => {
        // container = window.document.getElementById("main-container");
        if(this.container !== null && this.container !== undefined)
            this.container.scrollTop = position;
            
    }
}

export default new Window();