import React, { useState, useEffect, useContext } from 'react';
import Place from '~/Scripts/Client/Place';
import User from '~/Scripts/Client/User';
import { Spin, message } from 'antd';
import DeletedPlaceTable from './DeletedPlaceTable';
import { AppContext } from '~/Components/Context/AppContext';

const DeletedPlacesList = () => {
    const [places, setPlaces] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { loggedUser } = useContext(AppContext);
    const [userId, setUserId] = useState(User.userCan('view_deleted_place') ? '' : loggedUser.id);

    useEffect(() => {
        getDeletePlaces(1, 10, userId);
    }, []);

    const getDeletePlaces = (page, limit, userId) => {
        setLoading(true);
        Place.getDeletedPlaces({page, limit, userId}).then(
            (res) => {
                setPlaces(res.data.data);
                setTableData(res.data);
                setLoading(false);
            },
            (error) => {
                if(error.response !== undefined)
                    message.error(error.response.data.error_description);
                else
                    message.error("Something went wrong, please try again later.");
                setLoading(false);
            }
        );
    }

    const restorePlace = (place, index) => {
        Place.restorePlace(place.id).then(
            (res) => {
                places.splice(index, 1);
                setPlaces([...places]);
            },
            (error) => {
                if(error.response !== undefined)
                    message.error(error.response.data.error_description);
                else
                    message.error("Something went wrong, please try again later.");
                setLoading(false);
            }
        )
    }

    const permanentlyDeletePlace = (place, index) => {
        Place.deletePlace(place.id, 1).then(
            (res) => {
                places.splice(index, 1);
                setPlaces([...places]);
            },
            (error) => {
                if(error.response !== undefined)
                    message.error(error.response.data.error_description);
                else
                    message.error("Something went wrong, please try again later.");
                setLoading(false);
            }
        )
    }

    const pageChange = (page, size) => {
        getDeletePlaces(page, size, userId);
    }

    const onPageSizeChange = (page, size) => {
        getDeletePlaces(1, size, userId);
    }

    return (
        <div className="deleted-places">
            <Spin spinning={loading}>
                <DeletedPlaceTable places={places} data={tableData} onPageChange={pageChange}
                    restorePlace={restorePlace} deletePlace={permanentlyDeletePlace}
                    onPageSizeChange={onPageSizeChange} />
            </Spin>
        </div>
    )
}

export default DeletedPlacesList;