import React, { useState, useContext, useEffect } from 'react';
import Map from '~/Components/Maps/Map';
import MapTile from '~/Scripts/Client/MapTile';
import Place from '~/Scripts/Client/Place';
import User from '~/Scripts/Client/User';
import Googe from '~/Scripts/Client/Google';
import MapTileDrawer from '~/Scripts/Utils/MapTileDrawer';
import PlaceType from '~/Scripts/Client/PlaceType';
import PlaceInfoWindow from './PlaceInfoWindow';
import AddNewTile from './AddNewTile';
import EditTile from './EditTile';
import { MapContext } from '~/Components/Context/MapContext';
import { Polygon, Marker, InfoWindow } from 'google-maps-react';
import { message, Select, Input, Button } from 'antd';

const { Option } = Select;
const { Search } = Input;

const MapTileView = (props) => {

  const { map, google, tiles, setTiles, markers, setMarkers, tileDeleted, setTileDeleted } = useContext(MapContext);
  const [loadTypes, setLoadTypes] = useState(true);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [price, setPrice] = useState("");
  const [currentSubType, setCurrentSubType] = useState("");
  const [overlay, setOverlay] = useState();
  const [currentType, setCurrentType] = useState("");
  const [currentTile, setCurrentTile] = useState();
  const [loadPlaces, setLoadPlaces] = useState(false);
  const [activeMarker, setActiveMarker] = useState();
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [markerPlace, setMarkerPlace] = useState();
  const [hasDrawer, setHasDrawer] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditTileModal, setShowEditTileModal] = useState(false)
  const [newTilePath, setNewTilePath] = useState([]);
  const [tileToEdit, setTileToEdit] = useState();
  const [selectedTile, setSelectedTile] = useState({ tile: null });
  const [currentBounds, setCurrentBounds] = useState();
  const [location, setLocation] = useState();

  let timeOutInterval = null;
  let mapTiles = [...tiles];

  useEffect(() => {
    if (map !== null && map !== undefined) {
      mapTiles = [...mapTiles, tiles];
      google.maps.event.addListener(map, "idle", () => {
        let mapBounds = map.getBounds()
        let NE = mapBounds.getNorthEast()
        let SW = mapBounds.getSouthWest()
        let boundText = NE.lat() + ' ' + SW.lng() + ', ' + NE.lat() + ' ' + NE.lng() + ', ' +
          SW.lat() + ' ' + NE.lng() + ', ' + SW.lat() + ' ' + SW.lng() + ', ' + NE.lat() + ' ' + SW.lng();
        loadTiles(boundText, tiles);
        setCurrentBounds(boundText);
        setLocation(map.getCenter());

        // if(map.zoom >= 14) {
        //     console.log(currentType);
        //     Place.getNearestPlaceByBounds(boundText, currentType).then(
        //         (res) => {
        //             setMarkers(res.data.data);
        //         }
        //     ).catch(
        //         (error) => {
        //             console.log(error);
        //         }
        //     );
        // }
      });

      // check to set drawer only once
      if (User.userCan('create_tile') || User.userCan('edit_tile')) {
        if (!hasDrawer) {
          MapTileDrawer(map, google, onCreateTile);
          setHasDrawer(true);
        }
      }
    }

    if (loadTypes) {
      PlaceType.getPlaceTypes().then(
        (response) => {
          setLoadTypes(false);
          setTypes(response.data);
        }
      ).catch(errorHandler);
    }

    if (loadPlaces) {
      loadMarkers(currentTile);
    }

    if (tileDeleted) {
      if (selectedTile.tile !== null && selectedTile.tile !== undefined) {
        console.log(selectedTile.tile);
        selectedTile.tile.setMap(null);
        markers.splice();
        setMarkers(markers);
        setTileDeleted(false);
      }
    }
  }, [map, loadTypes, loadPlaces, tileDeleted]);

  const onTypeSelect = (type) => {
    setSubTypes([]);
    setCurrentSubType("");
    setCurrentType(type);
    PlaceType.getSubTypes(type).then(
      (res) => {
        setSubTypes(res.data);
      }
    ).catch(errorHandler);

    if (currentTile !== null && currentTile !== undefined)
      setLoadPlaces(true);
  }

  const onSubTypeSelect = (type) => {
    setCurrentSubType(type);
    if (currentTile !== null && currentTile !== undefined)
      setLoadPlaces(true);
  }

  const searchCity = (search) => {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': search }, function (results, status) {
      if (status == 'OK') {
        map.setCenter(results[0].geometry.location);
      }
    });
  }

  const loadTiles = (bounds, currentTiles) => {
    if (timeOutInterval !== null && timeOutInterval !== undefined)
      clearTimeout(timeOutInterval);

    // timeOutInterval = setTimeout(() => {
    //     MapTile.getTiles(bounds).then(
    //         (response) => {
    //             let temp = response.data.data.filter((t) => {
    //                 return !tileAlreadyInTheMap(t);
    //             });
    //             console.log(currentTiles);
    //             let newTiles = currentTiles.concat(temp);
    //             console.log(temp);
    //             setTiles([...newTiles]);
    //         }
    //     ).catch(errorHandler)
    // }, 300);
    MapTile.getTiles(bounds).then(
      (response) => {
        let temp = response.data.data.filter((t) => {
          return !tileAlreadyInTheMap(t, mapTiles);
        });
        mapTiles = mapTiles.concat(temp);
        setTiles([...mapTiles]);
      }
    ).catch(errorHandler)
  }

  const loadMarkers = (tile, e) => {
    let boundsText = '';
    tile.paths.forEach((path, i) => {
      boundsText += `${path.lat} ${path.lng}`;
      if (i < (tile.paths.length - 1))
        boundsText += ',';
    });
    setCurrentTile(tile);
    Place.getNearestPlaceByBounds(boundsText, currentType).then(
      (response) => {
        setMarkers(response.data.data);
        setLoadPlaces(false);
      }
    ).catch(errorHandler)
  }

  const loadNearbyPlaces = () => {
    if (map.zoom < 14) {
      message.error("Please zoom in the map to load places");
      return false;
    }

    if (!currentType) {
      message.error("Please select a category");
      return false;
    }

    Place.getNearestPlaceByBounds(currentBounds, currentType, currentSubType, price).then(
      (res) => {
        setMarkers(res.data.data);
      }
    ).catch(
      (error) => {
        console.log(error);
      }
    );

  }

  const onCreateTile = (path, tile) => {
    setNewTilePath(path);
    setShowCreateModal(true);
    setOverlay(tile);
  }

  const editTile = (tile, e, maptile) => {
    google.maps.event.addListener(e, "dblclick", function (event) {
      if (!User.userCan('edit_tile'))
        return false;
      event.stop();
      event.cancelBubble = true;
      if (event.stopPropagation) {
        event.stopPropagation();
      }
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }

      setTileToEdit({ ...tile });
      setSelectedTile({ ...selectedTile, tile: e });
      setShowEditTileModal(true);
    });
  }

  const markerClick = (marker, place) => {
    setActiveMarker(marker);
    setMarkerPlace(place);
    setShowInfoWindow(true);
    // Google.direction(`${location.lat()},${location.lng()}`,`${marker.getPosition().lat()},${marker.getPosition().lng()}`,"DRIVING")
  }

  const infowWindowClose = () => {
    setActiveMarker(null);
    setMarkerPlace(null);
    setShowInfoWindow(false);
  }

  const modalClose = (tile) => {
    setShowCreateModal(false);
    setShowEditTileModal(false);
    if (overlay !== null && overlay !== undefined) {
      overlay.setMap(null);
      if (tileDeleted) {
        let temp = tiles.filter((t) => {
          return t.id !== tileToEdit.id;
        });
        setTiles([...temp]);
      } else if (tile) {
        tiles.push(tile)
        setTiles([...tiles]);
      }
    }
  }

  const errorHandler = (error) => {
    console.log(error);
    if (error.response !== undefined)
      message.error(error.response.data.message);
    else
      message.error("Something went wrong, please try again later.");
  }

  const tileAlreadyInTheMap = (tile, currentTiles) => {

    let exists = currentTiles.filter((t) => {
      return tile.id === t.id;
    });

    return exists.length > 0;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div className="place-filter" style={{ marginTop: 10, flexDirection: "row" }}>
        <Select defaultValue="" className="select" onSelect={onTypeSelect}>
          <Option value="">- Select Category -</Option>
          {types.map((type) => {
            return <Option value={type.type} key={type.type}>{type.name}</Option>
          })}
        </Select>
        <Select defaultValue="" className="select"
          onSelect={onSubTypeSelect} value={currentSubType}>
          <Option value="">- Select Sub-Category -</Option>
          {subTypes.map((type) => {
            return <Option value={type.id}>{type.name}</Option>
          })}
        </Select>
        <Select value={price} 
          onChange={(value) => {
            setPrice(value);
          }}>
          <Option value="">- Select Price -</Option>
          <Option value={0}>Free</Option>
          <Option value={1}>$</Option>
          <Option value={2}>$$</Option>
          <Option value={3}>$$$</Option>
        </Select>
        <Search type="primary" placeholder="Search City ..." enterButton onSearch={searchCity} />
        <Button
          type="primary"
          style={{ marginLeft: 15 }}
          onClick={loadNearbyPlaces}>Load Places</Button>
      </div>
      <div style={{ display: 'flex', position: 'relative', height: '100%' }}>
        <Map style={{ flex: 1 }} google={props.google}>
          {tiles.map((t) => {
            return <Polygon
              key={t.id}
              paths={t.path}
              strokeColor="#FF0000"
              fillColor="#FF0000"
              strokeOpacity={0.5}
              strokeWeight={1}
              fillOpacity={0.2}
              onClick={(tile, e) => loadMarkers(tile, e)}
              onMouseover={(tile, e) => editTile(t, e, tile)}
            />
          })}
          {markers.map((m, i) => {
            return (
              <Marker
                key={m.id}
                id={m.id}
                position={{ lat: m.latitude, lng: m.longitude }}
                name={m.name}
                title={m.name}
                icon={{
                  url: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' +
                    (i + 1) + '|' + (m.types.length > 0 ? m.types[0].color : '7F8C8D') + '|FFFFFF'
                }}
                onClick={(props, marker) => markerClick(marker, m)}
              />
            );
          })}
          <InfoWindow
            marker={activeMarker}
            visible={showInfoWindow}
            onClose={infowWindowClose}
          >
            <PlaceInfoWindow place={markerPlace} />
          </InfoWindow>
        </Map>
      </div>
      <AddNewTile path={newTilePath}
        google={google}
        modalVisble={showCreateModal}
        modalClose={modalClose}
      />
      <EditTile
        tile={tileToEdit}
        maptile={selectedTile.map}
        google={google}
        modalVisble={showEditTileModal}
        modalClose={modalClose}
      />
    </div>
  );
}

export default MapTileView;