import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import BookmarkPlaces from '~/Components/Dashboard/BookmarkPlaces';
import VisitedPlaces from '~/Components/Dashboard/VisitedPlaces';
import UserPerCity from '~/Components/Dashboard/UserPerCity';
// import PropTypes from 'prop-types';

class Dashboard extends Component {
    static contextType = AppContext;
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {  
        this.context.setCurrentTab("dashboard");
    }

    render() {

        return (
            <div className="dashboard">
                <h1>Dashboard</h1>
                <div className="dashboard-chart-container">
                    <div className="dashboard-item">
                        <BookmarkPlaces />
                    </div>
                    <div className="dashboard-item">
                        <VisitedPlaces />
                    </div>
                </div>
                <div className="dashboard-chart-container">
                    <div className="dashboard-item">
                        <UserPerCity />
                    </div>
                </div>
            </div>
        );
    }
}

// Dashboard.propTypes = {

// };

export default Dashboard;