import React from 'react';
import { Button } from 'antd';
import axios from 'axios';
import Place from '~/Scripts/Client/Place';

const LoadMoreButton = ({ style, url, onFinish, onError, setLoading, loading }) => {

    const loadMore = () => {
        setLoading(true);
        axios.get(url, Place.defaultHeaders()).then(onFinish).catch(onError);
    }

    if(url === null || url === undefined)
        return null;

    return (
        <div className="load-more" style={style}>
            <Button type="primary" block onClick={loadMore} loading={loading}>Load More</Button>
        </div>
    )
}

export default LoadMoreButton;