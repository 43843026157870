import React, { useState, useEffect, useContext } from 'react';
import { Input, Modal, message, Popconfirm, Button } from 'antd';
import { GoogleApiWrapper, Map, Polygon, Polyline } from 'google-maps-react';
import CityBoundary from '~/Scripts/Client/CityBoundary';
import { MapContext } from '~/Components/Context/MapContext';

const EditBoundary = ({ tile, maptile, google, modalVisble, modalClose }) => {

    const { setTileDeleted } = useContext(MapContext);
    const [map, setMap] = useState();
    const [tilename, setTilename] = useState();
    const [city, setCity] = useState();
    const [tileState, setTileState] = useState();
    const [country, setCountry] = useState();
    const [loading, setLoading] = useState(false);
    const [mapCenter] = useState({ lat: 51.571960, lng: -0.777920 });
    const [mapZoom] = useState(14);
    // const [path, setPath] = useState();

    useEffect(() => {

        if(map !== null && map !== undefined && tile !== null && tile !== undefined) {
            setCity(tile.city);
            setTileState(tile.state);
            setCountry(tile.country);
            // setPath(tile.path);

            let bounds = new google.maps.LatLngBounds();
            tile.path.forEach((p) => {
                let myLatLng = new google.maps.LatLng(p.lat, p.lng);
                bounds.extend(myLatLng);
            });
            
            setTimeout(() => {
                map.fitBounds(bounds);
            }, 500);
            
        }
        
    }, [tile, map]);

    const setGoogleMap = (props, m) => {
        setMap(m);
    }

    const saveTile = () => {
        setLoading(true);
        let data = {
            city: city,
            state: tileState,
            country: country,
            path: tile.path
        }

        CityBoundary.editBoundary(tile.id, data).then(
            (res) => {
                message.success("Tile saved");
                setLoading(false);
                modalClose(res.data);
            }
        ).catch(
            (error) => {
                message.error("Something went wrong, please try again later");
                setLoading(false);
            }
        );
    }

    const deleteTile = () => { 
        setLoading(true);
        CityBoundary.deleteBoundary(tile.id).then(
            (res) => {
                setLoading(false);
                modalClose();
                setTileDeleted(tile);
            }
        ).catch(
            (error) => {
                console.log(error);
                message.error("Something went wrong, please try again later");
                setLoading(false);
            }
        );
    }

    if(tile === null || tile === undefined)
        return null;

    return (
        <Modal
                className="create-tile-modal"
                visible={modalVisble}
                title="Create New Boundary"
                width="70%"
                okText="Save"
                maskClosable={false}
                confirmLoading={loading}
                onCancel={() => modalClose(false)}
                onOk={saveTile}
                footer={[
                    <Button key="back" onClick={modalClose}>
                      Cancel
                    </Button>,
                    (<Popconfirm
                        title="Are you sure to delete this Boundary?"
                        onConfirm={deleteTile}
                        okText="Yes"
                        cancelText="No"
                        >
                        <Button danger key="back" loading={loading}>
                            Delete
                        </Button>
                        </Popconfirm>),
                    <Button key="submit" type="primary" loading={loading} 
                        onClick={saveTile}>
                      Save
                    </Button>,
                  ]}
            >
            <div className="tile-form-container">
                <div className="form">
                    <Input value={city} placeholder="Enter City"
                        onChange={(e) => setCity(e.target.value)} />
                    <Input value={tileState} placeholder="Enter State"
                        onChange={(e) => setTileState(e.target.value)} />
                    <Input value={country} placeholder="Enter Country"
                        onChange={(e) => setCountry(e.target.value)} />
                </div>
                <div className="tile-map">
                    <Map google={google}
                        style={{width: '100%', height: '100%'}}
                        initialCenter={mapCenter}
                        zoom={mapZoom}
                        onReady={setGoogleMap}
                        >
                        <Polyline
                            path={tile.path}
                            strokeColor="#0D55FA"
                            strokeOpacity={0.5}
                            strokeWeight={2}
                        />
                    </Map>
                </div>
            </div>      
        </Modal>
        
    )
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
})(EditBoundary);