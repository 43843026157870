
const MapTileDrawer = (map, google, drawFinishHandler, color, stroke) => {
    let cancelDrawing = false;

    let drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['polygon']
        },
        markerOptions: {icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'},
        polygonOptions: {
          strokeColor: color ? color : '#FF0000',
          fillColor: color ? color : '#FF0000',
          fillOpacity: 0.35,
          strokeWeight: stroke ? stroke : 1,
          strokeOpacity: 0.5,
          clickable: false,
          editable: true,
          zIndex: 1
        }
    });

    drawingManager.setMap(map);

    google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
        drawingManager.setDrawingMode(null);
        if(cancelDrawing) {
          cancelDrawing = false;
          event.overlay.setMap(null);
          return false;
        }

        // vm.drawTile = event.overlay
        var coordinatesArray = event.overlay.getPath().getArray()
        let path = []
        for (let x = 0; x < coordinatesArray.length; x++) {
          path.push({lat: coordinatesArray[x].lat(), lng: coordinatesArray[x].lng()})
        }
        // insert the original start point of the polygon
        if (coordinatesArray.length > 0) {
          path.push({lat: coordinatesArray[0].lat(), lng: coordinatesArray[0].lng()})
        }

        drawFinishHandler(path, event.overlay);
    });

    document.addEventListener('keydown', function(event) {
        if(event.keyCode === 27) {
          cancelDrawing = true;
          drawingManager.setDrawingMode(null);
        }
    });
}

export default MapTileDrawer;