import React from 'react';
import { MenuFoldOutlined } from '@ant-design/icons';
import UserInfo from './UserInfo';

const Header = ({ collpaseMenu }) => {

    return (
        <div className="headerContainer" style={{position: "fixed", zIndex: 999}}>
            <div className="headerLeft component">
                <a href="/" onClick={collpaseMenu}>
                    <MenuFoldOutlined fill="#FFFFFF" style={{fontSize: 32, color: "#FFFFFF"}} />
                </a>
            </div>
            <div className="headerMid component" style={{textAlign: 'center'}}>
                <h1 style={{color: "#FFFFFF"}}>City Guide CMS</h1>
            </div>
            <div className="headerRight component">
                <UserInfo />
            </div>
        </div>
    )
}

export default Header;