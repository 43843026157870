import BaseClient from './BaseClient';

class MapTile extends BaseClient {

    addTile = (data) => {
		return this.post('/maptile', data, this.defaultHeaders())
	}

	editTile = (id, data) => {
		return this.put('/maptile/' + id, data, this.defaultHeaders())
	}

	getTile = (id) => {
		return this.get('/maptile/' + id)
	}

	deleteTile = (id) => {
		return this.delete('/maptile/' + id, this.defaultHeaders())
	}

	getTiles = (bounds, search, city, state, country, parent_id) => {
		let url = '/maptile?page=1'

		if(bounds != null)
			url+= "&bounds=" + encodeURIComponent(bounds)

		if(search != null)
			url += '&search=' + search
		
		if(city != null)
			url += '&city=' + city

		if(state != null)
			url += '&state=' + state

		if(country != null)
			url += '&country=' + country

		if(parent_id != null)
			url += '&parent_id=' + parent_id

 
		return this.get(url)
	}

	getChildTiles = (parent_id, search) => {
		let url = '/maptile?page=1'
		if(search != null)
			url += '&search=' + search

		if(parent_id != null)
			url += '&parent_id=' + parent_id
 
		return this.get(url)
	}

	getNextPage = (link) => {
        return this(link)
    }

    getTileByUserLocation = (latitude, longitude) => {
    	return this.get('/maptile/userlocation?latitude=' + latitude + '&longitude=' + longitude)
    }
}

export default new MapTile();