import React, { useState, useEffect, useContext } from 'react';
import Place from '~/Scripts/Client/Place';
import PlaceType from '~/Scripts/Client/PlaceType';
import { Select, Button, Input, message, Spin, Modal } from 'antd';
import { MapContext } from '~/Components/Context/MapContext';
import Map from '~/Components/Maps/Map';
import ByCareTable from './ByCarTable';
import Google from '~/Scripts/Client/Google';
import PlaceMap from './PlaceMap';
import { ByCarContext } from '~/Components/Context/ByCarContext';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Search} = Input;
// create a setTimeout placeholder to cancel when type fast
var placeNameTimeOut = null;

const ByCarPlaces = () => {

    const { google } = useContext(MapContext);
    const { places, currentType, nearest, coordinates, search,
        setPlaces, setCurrentType, setNearest, setCoordinates, setSearch } = useContext(ByCarContext);
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [destination, setDestination] = useState();
    const [currentPlace, setCurrentPlace] = useState();
    const [placeNames, setPlaceNames] = useState([]);

    useEffect(() => {
        PlaceType.getPlaceTypes().then(
            (response) => {
                setTypes(response.data);
            }
        );
        setShowMap(false);
    }, []);

    // useEffect(() => {
    //     if(coordinates)
    //         loadPlaces(coordinates.lat(), coordinates.lng(), 1);
    // }, [currentType])

    const searchCity = (search) => {
        let geocoder = new google.maps.Geocoder();
        setSearch(search);
        geocoder.geocode( { 'address': search}, function(results, status) {
            if (status == 'OK') {
                // console.log(results[0].geometry.location)
                // map.setCenter(results[0].geometry.location);
                let position = results[0].geometry.location;
                setCoordinates(position);
                loadPlaces(position.lat(), position.lng(), 1);
            }
        });
    }

    const resetSearch = () => {
        setNearest(null);
        setCurrentType("");
        setSearch("");
    }

    const loadPlaces = (lat, lng, page, perPage) => {
        if(!perPage)
            perPage = 20;
        setLoading(true);
        Place.getNearestPlaces(lat, lng, 50, currentType, null, nearest, page, perPage).then(
            (res) => {
                setLoading(false);
                setPlaces(res.data);
            },
            (error) => {
                setLoading(false);
                message.error("Cannot search nearby places.");
            }
        )
    }

    const onTypeSelect = (type) => {
        setCurrentType(type);
    }

    const onPageChange = (page, size) => {
        loadPlaces(coordinates.lat(), coordinates.lng(), page, size);
    }

    const onPageSizeChange = (current, size) => {
        loadPlaces(coordinates.lat(), coordinates.lng(), 1, size);
    }

    const onSearchChange = (e) => {
        setNearest(e.target.value);
    }

    const getPlaceTravelTime = (place) => {
        setPlaceLoading(place, true);
        Google.direction(`${coordinates.lat()},${coordinates.lng()}`,
            `${place.latitude},${place.longitude}`,"DRIVING").then(
                (res) => {
                    setPlaceLoading(place, false);
                    if(res.data.status === "OK") {
                        let legs = res.data.routes[0].legs;
                        places.data.forEach((p) => {
                            if(p.id === place.id) {
                                p.travel_time = legs[0].duration.text;
                            }
                        })
                
                        setPlaces({...places});
                    }
                },
                (error) => {
                    setPlaceLoading(place, false);
                    message.error("Failed to calculate travel time.");
                }
            )
    }

    const setPlaceLoading  = (place, loading) => {
        places.data.forEach((p) => {
            if(p.id === place.id) {
                p.loading = loading;
            }
        })

        setPlaces({...places});
    }

    const showMapClick = (place) => {
        setDestination({lat: place.latitude, lng: place.longitude});
        setCurrentPlace(place);
        setShowMap(true);
    }

    const getPlaceNames = (search) => {
        // clear the previous timeout if it is set
        if(placeNameTimeOut !== null) {
            clearTimeout(placeNameTimeOut);
            console.log("Clear timeout");
        }

        placeNameTimeOut = setTimeout(() => { 
            Google.autoSuggestPlace(search).then(
                response => {
                    if(response.data.status === "OK")
                        setPlaceNames(response.data.predictions);
                }
            ).catch(
                error => {
                    console.log(error);
                }
            );
        }, 500);
    }

    const searchKeyDown = (e) => {
        if(e.keyCode === 13) {
            e.preventDefault();
            searchCity(search);
        }
    }

    const searchKeyUp = (e) => {
        if(e.keyCode === 13) {
            e.preventDefault();
        }
        setSearch(e.target.value);
    }

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
            <div style={{height: 0, display: "flex", overflow: "hidden", position: "relative"}}>
                <Map google={google} />
            </div>
            {/** Force rerender of the Map */}
            {showMap && <Modal
                title={`Direction to ${currentPlace.name}`}
                visible={showMap}
                okText="Save"
                cancelText="Cancel"
                maskClosable={false}
                width={800}
                onCancel={() => setShowMap(false)}
                footer={[<Button onClick={() => setShowMap(false)}>Close</Button>]}
            >
                <PlaceMap google={google} origin={coordinates} 
                    destination={destination} places={places} setPlaces={setPlaces}
                    place={currentPlace} />
            </Modal> }
            <div className="place-filter" style={{marginTop: 10, flexDirection: "row"}}>
                <Select defaultValue="" className="select" 
                    value={currentType}
                    onSelect={onTypeSelect}>
                    <Option value="">- Select Category -</Option>
                    {types.map((type) => {
                        return <Option value={type.type} key={type.type}>{type.name}</Option>
                    })}
                </Select>
                <Input value={nearest} onChange={onSearchChange} 
                    placeholder="Nearest distance" style={{width: 150}} />
                {/* <Search type="primary" placeholder="Search City ..." value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    enterButton onSearch={searchCity} /> */}
                <Select
                    showSearch
                    value={search}
                    allowClear={true}
                    placeholder="Search Place"
                    showArrow={false}
                    filterOption={false}
                    showSearch={true}
                    onSearch={getPlaceNames}
                    onSelect={(val) =>  searchCity(val)}
                    onChange={setSearch}
                    notFoundContent={null}
                    onBlur={(e) => e.preventDefault()}
                    onInputKeyDown={searchKeyDown}
                    onKeyUp={searchKeyUp}
                    disabled={loading}
                    style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, width: 300}}
                    >
                        <Option value="">Search Place...</Option>
                        {placeNames.map((name) => {
                            return <Option value={name.description}>{name.description}</Option>
                        })}
                </Select>
                <Button icon={<SearchOutlined />} type="primary"
                    onClick={() => searchCity(search)} />

                <Button type="primary" style={{marginLeft: 10}}
                    onClick={resetSearch}>Reset</Button>
            </div>
            <div>
                <Spin spinning={loading}>
                    <ByCareTable data={places} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange}
                         getPlaceTravelTime={getPlaceTravelTime} showMapClick={showMapClick} />
                </Spin>
            </div>
        </div>
    );
}

export default ByCarPlaces;