import React, { useRef } from 'react';
import { PieChart } from '@opd/g2plot-react';

const UserPerCity = () => {
    const data = [
        { city: "New York", value: 104 },
        { city: "Barcelona", value: 32 },
        { city: "Dublin", value: 10 },
        { city: "Istanbul", value: 22 },
        { city: "London", value: 321 },
        { city: "Marlow", value: 225 },
        { city: "Paris", value: 276 },
        { city: "Prague", value: 67 },
        { city: "Rome", value: 59 },
        { city: "Stockholm", value: 12 },
        { city: "Venice", value: 109 }
    ];

    const config = {
        title: {
            visible: true,
            text: 'User By City',
        },
        description: {
            visible: true,
            text: 'Number of Users per City',
        },
        forceFit: true,
        radius: 0.8,
        data,
        angleField: 'value',
        colorField: 'city',
        label: {
            visible: true,
            type: 'inner',
        },
    };

    const chartRef = useRef();

    return (
        <div className="dash-chart">
            <PieChart {...config}  chartRef={chartRef} />
        </div>
    )
}

export default UserPerCity;