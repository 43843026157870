const PlaceTypes = [
    { type: "accounting", label: "Account" },
    { type: "airport", label: "Airport" },
    { type: "amusement_park", label: "Amusement Park" },
    { type: "aquarium", label: "Aquarium" },
    { type: "art_gallery", label: "Art Gallery" },
    { type: "atm", label: "ATM" },
    { type: "bakery", label: "Bakery" },
    { type: "bank", label: "Bank" },
    { type: "bar", label: "Bar" },
    { type: "beauty_salon", label: "Beauty Salon" },
    { type: "bicycle_store", label: "Bicycle Store" },
    { type: "book_store", label: "Book Store" },
    { type: "bowling_alley", label: "Bowling Alley" },
    { type: "bus_station", label: "Bus Station" },
    { type: "cafe", label: "Cafe" },
    { type: "campground", label: "Campground" },
    { type: "car_dealer", label: "Car Dealer" },
    { type: "car_rental", label: "Car Rental" },
    { type: "car_repair", label: "Car Repair" },
    { type: "car_wash", label: "Car Wash" },
    { type: "casino", label: "Casino" },
    { type: "cemetery", label: "Cemetery" },
    { type: "church", label: "Church" },
    { type: "city_hall", label: "City Hall" },
    { type: "clothing_store", label: "Clothing Store" },
    { type: "convenience_store", label: "Convenience Store" },
    { type: "courthouse", label: "Courthouse" },
    { type: "dentist", label: "Dentist" },
    { type: "department_store", label: "Department Store" },
    { type: "doctor", label: "Doctor" },
    { type: "drugstore", label: "Drugstore" },
    { type: "electrician", label: "Electrician" },
    { type: "electronics_store", label: "Electronics Store" },
    { type: "embassy", label: "Embassy" },
    { type: "fire_station", label: "Fire Station" },
    { type: "florist", label: "Florist" },
    { type: "funeral_home", label: "Funeral Home" },
    { type: "furniture_store", label: "Furniture Store" },
    { type: "gas_station", label: "Gas Station" },
    { type: "gym", label: "Gym" },
    { type: "hair_care", label: "Hair Care" },
    { type: "hardware_store", label: "Hardware Store" },
    { type: "hindu_temple", label: "Hindu Temple" },
    { type: "home_goods_store", label: "Home Goods Store" },
    { type: "hospital", label: "Hospital" },
    { type: "insurance_agency", label: "Insurance Agency" },
    { type: "jewelry_store", label: "Jewelry Store" },
    { type: "laundry", label: "Laundry" },
    { type: "lawyer", label: "Lawyer" },
    { type: "library", label: "Library" },
    { type: "light_rail_station", label: "Light Rail Station" },
    { type: "liquor_store", label: "Liquor Store" },
    { type: "local_government_office", label: "Local Government Office" },
    { type: "locksmith", label: "Locksmith" },
    { type: "lodging", label: "Lodging" },
    { type: "meal_delivery", label: "Meal Delivery" },
    { type: "meal_takeaway", label: "Meal Takeaway" },
    { type: "mosque", label: "Mosque" },
    { type: "movie_rental", label: "Movie Rental" },
    { type: "movie_theater", label: "Movie Theater" },
    { type: "moving_company", label: "Moving Company" },
    { type: "museum", label: "Museum" },
    { type: "night_club", label: "Night Club" },
    { type: "painter", label: "Painter" },
    { type: "park", label: "Park" },
    { type: "parking", label: "Parking" },
    { type: "pet_store", label: "Pet Store" },
    { type: "pharmacy", label: "Pharmacy" },
    { type: "physiotherapist", label: "Physiotherapist" },
    { type: "plumber", label: "Plumber" },
    { type: "police", label: "Police" },
    { type: "post_office", label: "Post Office" },
    { type: "primary_school", label: "Primary School" },
    { type: "real_estate_agency", label: "Real Estate Agency" },
    { type: "restaurant", label: "Restaurant" },
    { type: "school", label: "School" },
    { type: "secondary_school", label: "Secondary School" },
    { type: "shoe_store", label: "Shoe Store" },
    { type: "shopping_mall", label: "Shopping Mall" },
    { type: "spa", label: "Spa" },
    { type: "stadium", label: "Stadium" },
    { type: "storage", label: "Storage" },
    { type: "store", label: "Store" },
    { type: "subway_station", label: "Subway Station" },
    { type: "supermarket", label: "Supermarket" },
    { type: "synagogue", label: "Synagogue" },
    { type: "taxi_stand", label: "Taxi Stand" },
    { type: "tourist_attraction", label: "Tourist Attraction" },
    { type: "train_station", label: "Train Station" },
    { type: "transit_station", label: "Transit Station" },
    { type: "travel_agency", label: "Travel Agency" },
    { type: "university", label: "University" },
    { type: "veterinary_care", label: "Veterinary Care" },
    { type: "zoo", label: "Zoo" }
]

export default PlaceTypes;