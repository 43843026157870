import React from 'react';
import StarRating from 'react-star-ratings';
import Constants from '~/Scripts/Utils/Constants';

import { Button, Spin } from 'antd';

const PlaceInfoWindow = ({ place, setShowDetail, setPlaceToShow, setShowList  }) => {

    const showPlaceDetail = () => {
        setPlaceToShow(place);
        setShowDetail(true);
        setShowList(true);
    }

    if(place === null || place === undefined)
        return <Spin loading={true} />;

    const renderGoogleButton = () => {
        if(place.fields.google_map_url === null || place.fields.google_map_url === undefined) 
            return null;

        return (
            <div style={{marginTop: 15}}>
                <a href={place.fields.google_map_url} target="_blank">
                    <Button type="primary" size="small">View in Google Map</Button>
                </a>
            </div>
        );
    }

    return (
        <div className="place-info-window">
            <div className="place-image">
                <img src={place.image} />
            </div>
            <div className="place-details">
                <p>{place.name}</p>
                <StarRating 
                        rating={place.fields.rating ? parseFloat(place.fields.rating) : 0}
                        numberOfStars={5}
                        name={place.name}
                        starDimension="15px"
                        starSpacing="1px"
                        starRatedColor={Constants.primaryColor}
                    /> {place.fields.rating}
                {renderGoogleButton()}
            </div>
        </div>
    )
}

export default PlaceInfoWindow;