import React, { useState, createContext, useEffect } from 'react';

export const ByCarContext = createContext();

export const ByCarProvider = (props) => {
    const [places, setPlaces] = useState([]);
    const [currentType, setCurrentType] = useState("");
    const [nearest, setNearest] = useState();
    const [coordinates, setCoordinates] = useState();
    const [search, setSearch] = useState();

    const state = {
        places: places,
        currentType: currentType,
        nearest: nearest,
        coordinates: coordinates,
        search: search,
        setPlaces: setPlaces,
        setCurrentType: setCurrentType,
        setNearest: setNearest,
        setCoordinates: setCoordinates,
        setSearch: setSearch
    }

    return (
        <ByCarContext.Provider value={state}>{props.children}</ByCarContext.Provider>
    );
}