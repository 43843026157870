import React, { useState, useEffect } from 'react';
import PlaceType from '~/Scripts/Client/PlaceType';
import { Skeleton, Button, Popconfirm, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import User from '~/Scripts/Client/User';

const CategoryList = () => {
    const [loadTypes, setLoadTypes] = useState(true);
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(loadTypes) {
            setLoadTypes(false);
            setLoading(true);
            PlaceType.getPlaceTypes().then(
                (res) => {
                    setTypes(res.data);
                    setLoading(false);
                }
            ).catch(
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        }
    }, [loadTypes]);

    const deleteCategory = (category, index) => {
        PlaceType.deleteType(category.id).then(
            () => {
                message.success("Category deleted");
                types.splice(index, 1);
                setTypes([...types]);
            }
        ).catch(
            (error) => {
                console.log(error);
                message.error("Something went wrong, please try again");
            }
        )
    }

    return (
        <div>
            <div style={{textAlign: "left", marginBottom: 25}}>
                <Link to="/category/add">
                    {User.userCan('create_place') && 
                        <Button type="primary" icon={<PlusOutlined />}>Add New Category</Button> }
                </Link>
            </div>
            <Skeleton active loading={loading}>
                <div className="category-container">
                    {types.map((type, idx) => {
                        return (
                            <div className="category-item">
                                <img src={type.image} alt={type.name} />
                                <p style={{color: `#${type.color}`}}>{type.name}</p>
                                <Link to={`/category/edit/${type.id}`}>
                                {User.userCan('update_place') &&  <Button type="primary">Edit</Button> }
                                </Link>
                                <Popconfirm title="Are you sure to delete this category?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => deleteCategory(type, idx)}
                                    >
                                    {User.userCan('delete_place') && <Button>Delete</Button>}
                                </Popconfirm>
                            </div>
                        )
                    })}
                </div>
            </Skeleton>
        </div>
    )
}

export default CategoryList;