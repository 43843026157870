import React, { useState, useEffect, useContext } from 'react';
import { Input, Modal, message } from 'antd';
import { GoogleApiWrapper, Map, Polygon } from 'google-maps-react';
import MapTile from '~/Scripts/Client/MapTile';

const AddNewTile = ({ path, google, modalVisble, modalClose }) => {

    const [map, setMap] = useState();
    const [tilename, setTilename] = useState();
    const [city, setCity] = useState();
    const [tileState, setTileState] = useState();
    const [country, setCountry] = useState();
    const [loading, setLoading] = useState(false);
    const [mapCenter] = useState({ lat: 51.571960, lng: -0.777920 });
    const [mapZoom] = useState(14);

    useEffect(() => {
        if(map !== null && map !== undefined && path.length > 0) {
            let bounds = new google.maps.LatLngBounds();
            
            path.forEach((p) => {
                let myLatLng = new google.maps.LatLng(p.lat, p.lng);
                bounds.extend(myLatLng);
            });

            setTimeout(() => {
                map.fitBounds(bounds);
            }, 500);
        }
    }, [path, map]);

    const setGoogleMap = (props, m) => {
        setMap(m);
    }

    const saveTile = () => {
        setLoading(true);
        let data = {
            name: tilename,
            city: city,
            state: tileState,
            country: country,
            zoom: map.getZoom(),
            path: path
        }

        MapTile.addTile(data).then(
            (res) => {
                console.log(res.data);
                message.success("Tile saved");
                setLoading(false);
                modalClose(res.data);
            }
        ).catch(
            (error) => {
                message.error("Something went wrong, please try again later");
                setLoading(false);
            }
        )
    }

    return (
        <Modal
                className="create-tile-modal"
                visible={modalVisble}
                title="Create New Tile"
                width="70%"
                okText="Save"
                maskClosable={false}
                confirmLoading={loading}
                onCancel={() => modalClose(false)}
                onOk={saveTile}
            >
            <div className="tile-form-container">
                <div className="form">
                    <Input value={tilename} placeholder="Enter Tile Name"
                        onChange={(e) => setTilename(e.target.value)} />
                    <Input value={city} placeholder="Enter City"
                        onChange={(e) => setCity(e.target.value)} />
                    <Input value={tileState} placeholder="Enter State"
                        onChange={(e) => setTileState(e.target.value)} />
                    <Input value={country} placeholder="Enter Country"
                        onChange={(e) => setCountry(e.target.value)} />
                </div>
                <div className="tile-map">
                    <Map
                        google={google}
                        style={{width: '100%', height: '100%'}}
                        initialCenter={mapCenter}
                        zoom={mapZoom}
                        onReady={setGoogleMap}
                        >
                        <Polygon
                            paths={path}
                            strokeColor="#FF0000"
                            fillColor="#FF0000"
                            strokeOpacity={0.5}
                            strokeWeight={1}
                            fillOpacity={0.2}
                        />
                    </Map>
                </div>
            </div>      
        </Modal>
        
    )
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
})(AddNewTile);