import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import WindowDimension from '~/Scripts/Utils/Window';
import UserList from '~/Components/Users/UserList';


class Users extends Component {
    static contextType = AppContext;
    // constructor(props) {
    //     super(props);

    // }

    state = {
        windowDimension: WindowDimension.getWindowDimensions()
    }

    componentDidMount() {
        this.context.setCurrentTab("users");
        WindowDimension.onWindowSizeChange(this.windowSizeChange);
    }

    windowSizeChange = () => {
        this.setState({windowDimension: WindowDimension.getWindowDimensions()});
    }

    render() {

        return (
            <div className="user-container">
                <h1>Users</h1>
                <UserList />
            </div>
        );
    }
}

export default Users;