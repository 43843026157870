import React, { useContext } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Auth from '~/Scripts/Client/Auth';

const UserInfo = () => {
    const { loggedUser, setIsLoggedIn, setLoggedUser  } = useContext(AppContext);
    // console.log(isLoggedIn);
    const logout = (e) => {
        e.preventDefault();
        Auth.removeLoginUser();
        Auth.removeToken();
        setLoggedUser(null);
        setIsLoggedIn(false);
    }

    return (
        <div className="userInfoContainer" style={{marginTop: -10}}>
            <div style={{marginRight: 10}}>
                <Avatar size={50} icon={<UserOutlined />} src={loggedUser.avatar} />
            </div>
            <div>
                <p style={{color: '#FFFFFF', marginBottom: 0}}>Welcome <strong>{loggedUser.name}</strong></p>
                {loggedUser.role && <p style={{color: '#FFFFFF', marginBottom: 0}}>{loggedUser.role.name}</p>}
                <a href="/" style={{color: '#FFFFFF', textDecoration: 'underline'}}
                    onClick={logout}>Logout</a>
            </div>
        </div>
    );
}

export default UserInfo;