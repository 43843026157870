import React, { Component } from 'react';
import { AppContext } from '~/Components/Context/AppContext';
import DeletedPlaceList from '~/Components/DeletedPlaces/DeletedPlacesList';

class DeletedPlaces extends Component {
    static contextType = AppContext;

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        this.context.setCurrentTab("deleted_places");
    }

    render() {

        return (
            <React.Fragment>
                <DeletedPlaceList />
            </React.Fragment>
        );
    }
}

// Places.propTypes = {

// };

export default DeletedPlaces;