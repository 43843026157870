import React, { useState, useEffect } from 'react';
import { Button, Skeleton, Popconfirm, Modal, Input, message, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import City from '~/Scripts/Client/City';
import User from '~/Scripts/Client/User';
import Countries from './Countries';
import Cities from './Cities';
import Towns from './Towns';

const { Option } = Select;

const CityList = () => {

    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [towns, setTowns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingCountry, setLoadingCountry] = useState(false);
    const [loadingTown, setLoadingTown] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [loadCities, setLoadCities] = useState(true);
    const [loadCountries, setLoadCountries] = useState(true);
    const [loadTowns, setLoadTowns] = useState(true);
    const [showAddCityModal, setShowAddCityModal] = useState(false);
    const [showEditCityModal, setShowEditCityModal] = useState(false);
    const [showAddCountryModal, setShowAddCountryModal] = useState(false);
    const [showEditCountryModal, setShowEditCountryModal] = useState(false);
    const [showAddTownModal, setShowAddTownModal] = useState(false);
    const [showEditTownModal, setShowEditTownModal] = useState(false);
    const [addCityName, setAddCityName] = useState("");
    const [editCityName, setEditCityName] = useState("");
    const [addCountryName, setAddCountryName] = useState("");
    const [editCountryName, setEditCountryName] = useState("");
    const [addTownName, setAddTownName] = useState("");
    const [editTownName, setEditTownName] = useState("");
    const [cityToEdit, setCityToEdit] = useState();
    const [countryToEdit, setCountryToEdit] = useState(); 
    const [townToEdit, setTownToEdit] = useState();
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedCity, setSelectedCity] = useState(""); 
    const [filterCountry, setFilterCountry] = useState();
    const [filterCity, setFilterCity] = useState();

    useEffect(() => {
        if(loadCities) {
            loadAllCities();
        }
        if(loadCountries) {
            loadAllCountries();
        }
        if(loadTowns)
            loadAllTowns();
    }, [loadCities, loadCountries]);

    const loadAllCities = (selectedCountry) => {
        setLoading(true);
        City.getCities(selectedCountry).then(
            (res) => {
                setCities(res.data);
                setLoadCities(false);
                setLoading(false);
            }
        ).catch(errorHandler);
    }

    const loadAllCountries = () => {
        setLoadingCountry(true);
        City.getCountries().then(
            (res) => {
                setCountries(res.data);
                setLoadCountries(false);
                setLoadingCountry(false);
            },
            errorHandler
        )
    }

    const loadAllTowns = (selectedCity) => {
        setLoadingTown(true);
        City.getCityTowns(selectedCity).then(
            (res) => {
                setTowns(res.data);
                setLoadTowns(false);
                setLoadingTown(false);
            },
            errorHandler
        )
    }

    const countryChanged = (countryId) => {
        // setSelectedCountry(countryId);
        loadAllCities(countryId);
        setFilterCountry(countryId);
    }

    const cityChanged = (cityId) => {
        loadAllTowns(cityId);
        setFilterCity(cityId);
    }

    const addCity = () => {
        setSaveLoading(true);
        City.addCity({name: addCityName, country_id: selectedCountry}).then(
            () => {
                loadAllCities();
                setSaveLoading(false);
                setShowAddCityModal(false);
                setAddCityName("");
                setSelectedCountry("");
                message.success("City successfully added");
            }
        ).catch(errorHandler);
    };

    const editCityClick = (city) => {
        setCityToEdit(city);
        setEditCityName(city.name);
        setShowEditCityModal(true);
        setSelectedCountry(city.country ? city.country.id : '');
    }

    const editCity = () => {
        setSaveLoading(true);
        City.updateCity(cityToEdit.id, { name: editCityName, country_id: selectedCountry}).then(
            (res) => {
                setSaveLoading(false);
                setShowEditCityModal(false);
                // cities.forEach((city, i) => {
                //     if(city.id === res.data.id)
                //     cities[i] = res.data;
                // });
                // setCities([...cities]);
                setSelectedCountry("");
                loadAllCities(filterCountry);
            },
            errorHandler
        );
    }

    const deleteCity = (city, idx) => {
        City.deleteCity(city.id).then(
            (res) => {
                cities.splice(idx, 1);
                setCities([...cities]);
            }
        ).catch(errorHandler);
    }

    const addCountry = () => {
        setSaveLoading(true);
        City.addCountry(addCountryName).then(
            () => {
                setLoadCountries(true);
                setSaveLoading(false);
                setShowAddCountryModal(false);
                setAddCountryName("");
                message.success("Country successfully added");
            }
        ).catch(errorHandler);
    };

    const editCountryClick = (country) => {
        setCountryToEdit(country);
        setEditCountryName(country.name);
        setShowEditCountryModal(true);
    }

    const editCountry = () => {
        setSaveLoading(true);
        City.upateCountry(countryToEdit.id, editCountryName).then(
            (res) => {
                setSaveLoading(false);
                setShowEditCityModal(false);
                countries.forEach((country, i) => {
                    if(country.id === res.data.id)
                    countries[i] = res.data;
                });
                setCountries([...countries]);
                setShowEditCountryModal(false);
            },
            errorHandler
        );
    }

    const deleteCountry = (coutnry, idx) => {
        City.deleteCountry(coutnry.id).then(
            (res) => {
                countries.splice(idx, 1);
                setCountries([...countries]);
            }
        ).catch(errorHandler);
    }

    const addTown = () => {
        setSaveLoading(true);
        City.saveTown(selectedCity, {name: addTownName}).then(
            () => {
                loadAllTowns(filterCity);
                setSaveLoading(false);
                setShowAddTownModal(false);
                setAddTownName("");
                setSelectedCity("");
                message.success("Town successfully added");
            }
        ).catch(errorHandler);
    };

    const editTownClick = (town) => {
        setTownToEdit(town);
        setEditTownName(town.name);
        setShowEditTownModal(true);
        setSelectedCity(town.city ? town.city.id : '');
    }

    const editTown = () => {
        setSaveLoading(true);
        City.updateTown(selectedCity, townToEdit.id, { name: editTownName }).then(
            (res) => {
                setSaveLoading(false);
                setShowEditTownModal(false);
                // cities.forEach((city, i) => {
                //     if(city.id === res.data.id)
                //     cities[i] = res.data;
                // });
                // setCities([...cities]);
                setSelectedCity("");
                loadAllTowns(filterCity);
            },
            errorHandler
        );
    }

    const deleteTown = (town, idx) => {
        City.deleteTown(filterCity, town.id).then(
            (res) => {
                towns.splice(idx, 1);
                setTowns([...towns]);
            }
        ).catch(errorHandler);
    }

    const errorHandler = (error) => {
        console.log(error);
        if(error.response !== undefined)
            message.error(error.response.data.error_description);
        else
            message.error("Something went wrong, please try again later.");
        setSaveLoading(false);
        setLoading(false);
        setLoadingCountry(false);
        setLoadingTown(false);
    }

    const resetCityFilter = () => {
        setFilterCountry("");
        countryChanged("");
    }

    return (
        <div className="city-list">
            <div className="country-list item-list">
                <div style={{textAlign: "left", marginBottom: 25}}>
                    {User.userCan('create_place') &&  <Button type="primary" icon={<PlusOutlined />}
                        onClick={() => setShowAddCountryModal(true)}
                        >Add New Country</Button>  }
                </div>
                <Skeleton active loading={loadingCountry}>
                    <div className="country-container">
                        <Countries countries={countries} 
                            editCountry={editCountryClick} deleteCountry={deleteCountry} />
                    </div>
                </Skeleton>
            </div>
            <div className="city-list-item item-list">
                <div style={{textAlign: "left", marginBottom: 25}}>
                    {User.userCan('create_place') && <Button type="primary" icon={<PlusOutlined />}
                        onClick={() => setShowAddCityModal(true)}
                        >Add New City</Button> }
                </div>
                <div style={{marginBottom: 20, textAlign: "left"}}>
                    <Select defaultValue="" className="select" 
                        style={{marginTop: 15, marginRight: 10, width: "50%"}}
                        value={filterCountry}
                        onChange={countryChanged}>
                        <Option value="">- Select Country -</Option>
                        {countries.map((c) => {
                            return <Option value={c.id}>{c.name}</Option>
                        })}
                    </Select>
                    <Button onClick={resetCityFilter}>Reset</Button>
                </div>
                <Skeleton active loading={loading}>
                    <div className="city-container">
                        <Cities cities={cities} editCity={editCityClick} 
                            deleteCity={deleteCity} />
                    </div>
                </Skeleton>
            </div>

            <div className="city-list-item item-list">
                <div style={{textAlign: "left", marginBottom: 25}}>
                    {User.userCan('create_place') && <Button type="primary" icon={<PlusOutlined />}
                        onClick={() => setShowAddTownModal(true)}
                        >Add New Town</Button> }
                </div>
                <div style={{marginBottom: 20, textAlign: "left"}}>
                    <Select defaultValue="" className="select" 
                        style={{marginTop: 15, marginRight: 10, width: "50%"}}
                        value={filterCity}
                        onChange={cityChanged}>
                        <Option value="">- Select City -</Option>
                        {cities.map((c) => {
                            return <Option value={c.id}>{c.name}</Option>
                        })}
                    </Select>
                    <Button onClick={resetCityFilter}>Reset</Button>
                </div>
                <Skeleton active loading={loading || loadingTown}>
                    <div className="city-container">
                        <Towns towns={towns} editTown={editTownClick} 
                            deleteTown={deleteTown} />
                    </div>
                </Skeleton>
            </div>


            {/* Country Modals */}

            <Modal

                title="Add New Country"
                visible={showAddCountryModal}
                okText="Save"
                cancelText="Cancel"
                onOk={addCountry}
                onCancel={() => { setShowAddCountryModal(false); setAddCityName("") }}
                maskClosable={false}
                confirmLoading={saveLoading}
            >
                <div>
                    <Input 
                        value={addCountryName} 
                        onChange={(e) => setAddCountryName(e.target.value)}
                        placeholder="Enter Country Name"
                         />
                </div>
            </Modal>

            <Modal
                title="Edit Country"
                visible={showEditCountryModal}
                okText="Save"
                cancelText="Cancel"
                onOk={editCountry}
                onCancel={() => setShowEditCountryModal(false)}
                maskClosable={false}
                confirmLoading={saveLoading}
            >
                <div>
                    <Input 
                        value={editCountryName} 
                        onChange={(e) => setEditCountryName(e.target.value)}
                        placeholder="Enter Country Name"
                         />
                </div>
            </Modal>

            {/* City Modals */}
            <Modal

                title="Add New City"
                visible={showAddCityModal}
                okText="Save"
                cancelText="Cancel"
                onOk={addCity}
                onCancel={() => { setShowAddCityModal(false); setAddCityName(""); }}
                maskClosable={false}
                confirmLoading={saveLoading}
            >
                <div>
                    <Input 
                        value={addCityName} 
                        onChange={(e) => setAddCityName(e.target.value)}
                        placeholder="Enter City Name"
                         />
                    <Select defaultValue="" className="select" 
                        style={{display: "block", marginTop: 15}}
                        onChange={(val) => setSelectedCountry(val)}
                        value={selectedCountry}>
                        <Option value="">- Select Country -</Option>
                        {countries.map((c) => {
                            return <Option value={c.id}>{c.name}</Option>
                        })}
                    </Select>
                </div>
            </Modal>

            <Modal
                title="Edit City"
                visible={showEditCityModal}
                okText="Save"
                cancelText="Cancel"
                onOk={editCity}
                onCancel={() => setShowEditCityModal(false)}
                maskClosable={false}
                confirmLoading={saveLoading}
            >
                <div>
                    <Input 
                        value={editCityName} 
                        onChange={(e) => setEditCityName(e.target.value)}
                        placeholder="Enter City Name"
                         />
                    <Select defaultValue="" className="select" 
                        style={{display: "block", marginTop: 15}}
                        onChange={(val) => setSelectedCountry(val)}
                        value={selectedCountry}>
                        <Option value="">- Select Country -</Option>
                        {countries.map((c) => {
                            return <Option value={c.id}>{c.name}</Option>
                        })}
                    </Select>
                </div>
            </Modal>

            {/* Town Modals */}
            <Modal

                title="Add New Town"
                visible={showAddTownModal}
                okText="Save"
                cancelText="Cancel"
                onOk={addTown}
                onCancel={() => { setShowAddTownModal(false); setAddTownName(""); }}
                maskClosable={false}
                confirmLoading={saveLoading}
            >
                <div>
                    <Input 
                        value={addTownName} 
                        onChange={(e) => setAddTownName(e.target.value)}
                        placeholder="Enter Town Name"
                         />
                    <Select defaultValue="" className="select" 
                        style={{display: "block", marginTop: 15}}
                        onChange={(val) => setSelectedCity(val)}
                        value={selectedCity}>
                        <Option value="">- Select City -</Option>
                        {cities.map((c) => {
                            return <Option value={c.id}>{c.name}</Option>
                        })}
                    </Select>
                </div>
            </Modal>

            <Modal
                title="Edit Town"
                visible={showEditTownModal}
                okText="Save"
                cancelText="Cancel"
                onOk={editTown}
                onCancel={() => setShowEditTownModal(false)}
                maskClosable={false}
                confirmLoading={saveLoading}
            >
                <div>
                    <Input 
                        value={editTownName} 
                        onChange={(e) => setEditTownName(e.target.value)}
                        placeholder="Enter Town Name"
                         />
                    <Select defaultValue="" className="select" 
                        style={{display: "block", marginTop: 15}}
                        onChange={(val) => setSelectedCity(val)}
                        value={selectedCity}>
                        <Option value="">- Select City -</Option>
                        {cities.map((c) => {
                            return <Option value={c.id}>{c.name}</Option>
                        })}
                    </Select>
                </div>
            </Modal>
        </div>
    )
}

export default CityList;